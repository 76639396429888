import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, find } from 'lodash';

import { H1, MessageSmall, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';

import onCallLogo from 'assets/images/oncall.jpg';

import Logo from 'blocks/Logo';
import Tag from 'blocks/Tag';

import { CASE_STATUS } from 'compositions/CaseStatus';

import countOfTime from 'utils/countOfTime';

const labelForStatus = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`Dispatched - Enroute`,
  [CASE_STATUS.arrived]: t`Dispatched - Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
};

function Header({ caseData, currentUser }) {
  const now = moment().tz(moment.tz.guess());
  const reversedHistory = [...(get(caseData, 'statusHistory') || [])].reverse();

  const statusDate =
    caseData.status === CASE_STATUS.new
      ? caseData.createdAt
      : get(find(reversedHistory, { newStatus: caseData.status }), 'changedAt');

  const exportDate = now.format('D MMM');
  const exportTime = now.format('h:mm A z');
  const exportAuthor = (currentUser.name || '').trim() || currentUser.email;

  return (
    <>
      <Row style={{ marginTop: px2rem(10), marginBottom: px2rem(20) }}>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text modifiers="textLight">
            <Trans>
              Exported on {exportDate} at {exportTime} by {exportAuthor}
            </Trans>
          </Text>
        </Column>
      </Row>
      <Row modifiers={['middle']}>
        <Column modifiers={['col_4']}>
          <Row>
            <Column>
              <Logo src={onCallLogo} alt="Logo" />
            </Column>
            <Column modifiers={['col']}>
              <Row>
                <Column
                  modifiers={['padScaleY_3']}
                  style={{ paddingBottom: 0 }}
                >
                  <H1 modifiers="fontWeightLight">{caseData.caseNumber}</H1>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Tag>
                    <Tag.Text>
                      {caseData.billable ? (
                        <Trans>Billable</Trans>
                      ) : (
                        <Trans>Not Billable</Trans>
                      )}
                    </Tag.Text>
                  </Tag>
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
        <Column modifiers={['col_4']}>
          <Row>
            <Column modifiers={['col']}>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>Created</Trans>
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text>
                    {moment(caseData.createdAt)
                      .tz(moment.tz.guess())
                      .format('D MMM, h:mm A z')}
                  </Text>
                </Column>
              </Row>
            </Column>
            <Column modifiers={['col']}>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>Downtime</Trans>
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text>
                    {countOfTime(
                      moment.duration(caseData.assetDowntimeMs, 'milliseconds'),
                    )}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
        <Column modifiers={['col_4']}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Status</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text>
                <Trans
                  id={labelForStatus[caseData.status || CASE_STATUS.new]}
                />{' '}
                &bull;{' '}
                {moment(statusDate)
                  .tz(moment.tz.guess())
                  .format('D MMM, h:mm A z')}
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
      {caseData.quickNote && (
        <Row>
          <Column modifiers={['padScaleY_2']}>
            <MessageSmall type="info">{caseData.quickNote}</MessageSmall>
          </Column>
        </Row>
      )}
    </>
  );
}

Header.propTypes = {
  caseData: PropTypes.shape({
    assetDowntimeMs: PropTypes.number,
    billable: PropTypes.bool,
    caseNumber: PropTypes.string,
    quickNote: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.string,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({
        changedAt: PropTypes.string,
        newStatus: PropTypes.string,
      }),
    ),
  }).isRequired,
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
export default Header;
