import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { H2, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import RelativeNow from 'components/RelativeNow';
import { CallButton } from 'compositions/ClickToCall';
import {
  evaluateOperatingStatusType,
  OPERATING_STATUS_VALUES,
} from 'utils/operatingStatusFilter';

const thirtySeconds = 1000 * 30;

// eslint-disable-next-line react/prop-types
const renderConfirmationModal = ({ onCancel, onConfirm }) => (
  <Modal onClose={onCancel}>
    {() => (
      <Modal.Body>
        <Modal.Header modifiers="warning">
          <Modal.HeaderIcon name="exclamation-circle" />
        </Modal.Header>
        <Modal.Content>
          <Row modifiers="center">
            <Column modifiers="col">
              <H2 modifiers="fontWeightRegular">
                <Trans>
                  This service provider is currently closed and does not provide
                  after hours ERS.
                  <br />
                  Do you still wish to call this service provider?
                </Trans>
              </H2>
            </Column>
          </Row>
        </Modal.Content>
        <Modal.Footer>
          <Row modifiers="end">
            <Column>
              <QuickActionButton onClick={onCancel} type="button">
                <QuickActionButton.Text>
                  <Trans>Cancel</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
            <Column>
              <QuickActionButton
                type="submit"
                onClick={onConfirm}
                modifiers={['secondary']}
              >
                <QuickActionButton.Text>
                  <Trans>Call Service Provider</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
          </Row>
        </Modal.Footer>
      </Modal.Body>
    )}
  </Modal>
);

const DealerCallButton = ({
  contactName,
  dealer,
  dealer: { timezone },
  onOutboundCall,
  phoneNumber,
}) => (
  <RelativeNow
    interval={thirtySeconds}
    timezone={timezone}
    render={({ relativeNow }) => {
      const status = evaluateOperatingStatusType(dealer, relativeNow);

      return (
        <CallButton
          dealer={dealer}
          name={contactName}
          onOutboundCall={onOutboundCall}
          phone={phoneNumber}
          renderConfirmationModal={
            status.type === OPERATING_STATUS_VALUES.CLOSED
              ? renderConfirmationModal
              : undefined
          }
        />
      );
    }}
  />
);

DealerCallButton.propTypes = {
  contactName: PropTypes.string.isRequired,
  dealer: PropTypes.shape({ timezone: PropTypes.string.isRequired }).isRequired,
  onOutboundCall: PropTypes.func,
  phoneNumber: PropTypes.string.isRequired,
};

DealerCallButton.defaultProps = {
  onOutboundCall: undefined,
};

export default DealerCallButton;
