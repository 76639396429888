import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from './Icon';
import Text from './Text';

const modifierConfig = {
  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background: ${theme.colors.base.background};
  border-radius: ${theme.dimensions.borderRadius};
  border: 1px solid ${theme.colors.base.chrome200};
  cursor: pointer;
  display: flex;
  height: ${px2rem(17)};
  outline: none;
  padding: 0 ${px2rem(5)};

  &:hover {
    span:not(.fa):not(.ix) {
      color: ${theme.colors.status.info};
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const ButtonMicro = buildStyledComponent('ButtonMicro', styled.button, styles, {
  modifierConfig,
  themePropTypes,
});

ButtonMicro.Icon = Icon;
ButtonMicro.Text = Text;

export default ButtonMicro;
