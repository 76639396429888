import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';

import gql from 'graphql-tag';

import CustomerWidgetContext from '../CustomerWidgetContext';

class WithCustomerFromCall extends Component {
  static propTypes = {
    callContactAttributes: PropTypes.shape({
      shipTo: PropTypes.string,
    }).isRequired,
    caseCustomerId: PropTypes.string,
    caseId: PropTypes.string,
    children: PropTypes.node.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    inboundProgramAccountType: PropTypes.string,
    isLoadingCase: PropTypes.bool.isRequired,
    isLoadingCustomer: PropTypes.bool.isRequired,
    isNewCase: PropTypes.bool.isRequired,
    saveCustomer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: undefined,
    caseCustomerId: undefined,
    customer: undefined,
    inboundProgramAccountType: undefined,
  };

  state = { customerSaved: false };

  componentDidMount() {
    this.setCustomerFromCall();
  }

  componentDidUpdate() {
    this.setCustomerFromCall();
  }

  /**
   * If the conditions are juuuust right, automatically saves the customer to the case
   *
   * @memberof WithCustomerFromCall
   */
  setCustomerFromCall = () => {
    if (this.shouldAutoSelectCustomer() && this.canAutoSelectCustomer()) {
      this.setState({ customerSaved: true }, () => {
        this.props.saveCustomer({
          variables: {
            id: this.props.caseId,
            customerId: this.props.customer.id,
          },
        });
      });
    }
  };

  /**
   * Evaluates props to determine if a customer should be saved.
   * Specific conditions include:
   * - The case must have just been created
   * - All of the case info must be loaded
   * - The case must not already have a customer saved
   * - The call must include a shipTo attribute
   * - The "save" action has not previously been called
   * - The agent must have been on the phone with the customer when creating the case.
   *
   * @memberof WithCustomerFromCall
   */
  shouldAutoSelectCustomer = () =>
    this.props.isNewCase &&
    this.props.inboundProgramAccountType === 'FLEET' &&
    this.props.callContactAttributes.shipTo &&
    this.props.caseId &&
    !this.props.caseCustomerId &&
    !this.state.customerSaved;

  /**
   * Evaluates props to determine if all of the data needed to save a customer automagically
   * is present.
   *
   * @memberof WithCustomerFromCall
   */
  canAutoSelectCustomer = () =>
    !this.props.isLoadingCase &&
    !this.props.isLoadingCustomer &&
    !!this.props.customer?.id;

  render() {
    return this.props.children;
  }
}

const saveCustomerFromCall = gql`
  mutation saveCustomerFromCall($customerId: ID!, $id: ID!) {
    setCaseNationalCustomer(
      input: { id: $id, patch: { customerId: $customerId } }
    ) {
      case {
        id
        fixpixPushResult
        customer(useCachedDataForCase: true) {
          ... on Customer {
            id
            type
            name
            city
            state
            billTo
            shipTo
          }
        }
        paymentMethod
      }
    }
  }
`;

const findCustomerForCallAttributes = gql`
  query searchCustomersQuery($searchString: String) {
    searchCustomers(filters: { searchString: $searchString }) {
      id
    }
  }
`;

const withCustomerSearchAndSave = curry((WrappedComponent, componentProps) => {
  const {
    callContactAttributes,
    caseCustomerId,
    caseId,
    inboundProgramAccountType,
    isLoadingCase,
    isNewCase,
  } = componentProps;

  return (
    <Mutation mutation={saveCustomerFromCall}>
      {(saveCustomer, { data: mutationData }) => (
        <Query
          query={findCustomerForCallAttributes}
          variables={{ searchString: callContactAttributes.shipTo }}
          skip={!callContactAttributes.shipTo}
        >
          {({ data, loading: isLoadingCustomer }) => {
            const [customer] = get(data, 'searchCustomers') || [];
            const wrappedComponentKey = get(
              mutationData,
              'setCaseNationalCustomer.case.customer.id',
              'no-auto-saved-case-customer',
            );

            return (
              <WithCustomerFromCall
                callContactAttributes={callContactAttributes}
                caseCustomerId={caseCustomerId}
                caseId={caseId}
                customer={customer}
                inboundProgramAccountType={inboundProgramAccountType}
                isLoadingCase={isLoadingCase}
                isLoadingCustomer={isLoadingCustomer}
                isNewCase={isNewCase}
                saveCustomer={saveCustomer}
              >
                <WrappedComponent
                  key={wrappedComponentKey}
                  {...componentProps}
                />
              </WithCustomerFromCall>
            );
          }}
        </Query>
      )}
    </Mutation>
  );
});

const caseQuery = gql`
  query caseInfoQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        inboundProgramAccountType
      }
    }
  }
`;

const withCaseInfo = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { isNewCase, caseNumber } = consumerProps;
    const { isReadOnlyCase, callContactAttributes } = consumerProps;

    return (
      <Query query={caseQuery} variables={{ caseNumber }}>
        {({ data, loading }) =>
          withCustomerSearchAndSave(WrappedComponent, {
            ...componentProps,
            callContactAttributes,
            caseId: get(data, 'case.id'),
            caseCustomerId: get(data, 'case.customer.id'),
            inboundProgramAccountType: get(
              data,
              'case.inboundProgramNumber.inboundProgramAccountType',
            ),
            isLoadingCase: loading,
            isNewCase,
            isReadOnlyCase,
          })
        }
      </Query>
    );
  },
);

const withCustomerFromCall = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {withCaseInfo(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerFromCall;
