import Bugsnag from '@bugsnag/browser';
import { omit } from 'lodash';
import { checkedResourcesQueryNames } from 'setup/ResourceErrorBoundary';

// Some operations should not trigger bugsnag reporting if they have an error.
const noReportOperations = [...checkedResourcesQueryNames, 'webUiVersion'];

const noReportCodes = [
  'access_denied',
  'after_hours_rotation_conflict',
  // These should all also be logged to the Backend Bugsnag
  'external_api_error',
  'unexpected_exception',
];

const reportedErrors = new Set();

// Paths of query variables that should be
// ignored for the operation id generation.
const ignoredVarsPaths = ['cacheBuster'];

/**
 * Regular expression for matching a UUID for a case or other entity of
 * that looks like e.g. "af098fb8-7235-468e-9724-b22ab616fddc".
 */
const UUID_REGEX = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;

/**
 * Customize the Bugsnag error grouping, otherwise all GraphQL
 * errors are grouped together into one Bugsnag error group.
 */
export function makeBugsnagGroupingHash(operation, error) {
  // Strip UUID from the error message so that the same error, for
  // different entities, get grouped together
  const errorMessage = error?.message?.replace(UUID_REGEX, '');

  // Group all "access_denied" errors, since this can happen on any query
  if (error?.code === 'access_denied') return errorMessage;

  return [operation?.operationName || '', errorMessage].join(' - ');
}

const init = () => ({ response, operation, graphQLErrors }) => {
  const query = operation?.query.loc.source.body;
  const [error] = graphQLErrors; // We only handle the first error ¯\_(ツ)_/¯
  const { operationName } = operation;

  const shouldSkip = [
    noReportCodes.includes(error.code),
    noReportOperations.includes(operationName),
    operation.getContext()?.reportErrorsToBugSnag === false,
  ].some(Boolean);

  if (shouldSkip) return;

  const variables = omit(operation.variables, ignoredVarsPaths);
  const operationId = `${operationName}-${JSON.stringify(variables)}`;

  if (!reportedErrors.has(operationId)) {
    reportedErrors.add(operationId);

    /* eslint-disable no-console */
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        'The following error would be reported to Bugsnag in production. Please ensure it is caught to prevent double reporting: ',
        error,
      );
    }
    /* eslint-enable no-console */

    Bugsnag.notify(error.message, (event) => {
      const metadata = { query, error, response, variables, operationName };

      event.context = `Error in GraphQL response: ${error.message}`;
      event.groupingHash = makeBugsnagGroupingHash(operation, error);
      event.addMetadata('graphql', metadata);
    });
  }
};

export default { init };
