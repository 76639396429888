import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit, without } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { Container, Row, Column } from 'styled-components-grid';
import { H1, InputGroup, InputField, Text } from 'base-components';

import { CASE_STATUS } from 'compositions/CaseStatus';
import RadioButton from 'components/RadioButton';

import { leftNavStateSelector } from 'redux/preferences/selectors';

import withContext from 'utils/withContext';
import patchFromChangedProps from 'utils/patchFromChangedProps';

import StatusCheckbox from './StatusCheckbox';
import CheckboxesContainer from './CheckboxesContainer';
import HighlightedCasesLink from './HighlightedCasesLink';
import DateRangeSelectorDropdown from './DateRangeSelectorDropdown';
import InboundProgramSelector from './InboundProgramSelector';
import CasesSearchContext from '../Context';
import {
  SORTABLE_COLUMNS_PER_GROUP,
  STATUS_GROUPS_NAMES,
  FILTERABLE_STATUSES_PER_GROUP,
} from '../constants';
import { ALL_FILTER_OPTION } from './constants';

// We delay triggering searches when typing to minimize unnecessary queries.
const QUERY_CHANGE_TRIGGER_DELAY = 500;

const inputPlaceholder = t`Search by case number, fleet name, unit number, or service provider name...`;

export class Filters extends Component {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    sortBy: PropTypes.string.isRequired,
    endDate: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    isLoading: PropTypes.bool.isRequired,
    statusGroup: PropTypes.string.isRequired,
    isNavExpanded: PropTypes.bool,
    totalsByStatus: PropTypes.shape({}).isRequired,
    isAutoRefreshing: PropTypes.bool.isRequired,
    updateSearchParams: PropTypes.func.isRequired,
    inboundProgramId: PropTypes.string,
  };

  static defaultProps = {
    endDate: undefined,
    startDate: undefined,
    isNavExpanded: true,
    inboundProgramId: undefined,
  };

  state = {
    query: this.props.query,
    status: this.props.status,
    endDate: this.props.endDate,
    sortBy: this.props.sortBy,
    startDate: this.props.startDate,
    // MICONCALL-1083
    // We are tracking when a search is "in flight" (through isLoading)
    // and ignore prop updates triggered outside if during that time a
    // change to the query happens. This prevents the input value from
    // switching back to the in-flight search term and loose any text
    // change that might have happened since the search was triggered.
    ignoreParentPropsChange: false,
    statusGroup: this.props.statusGroup,
    selectedInboundProgram:
      this.props.inboundProgramId === undefined
        ? ALL_FILTER_OPTION
        : {
            id: this.props.inboundProgramId,
            customerName: '',
          },
    inboundProgramSearchValue: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.ignoreParentPropsChange) return;

    let statePatch = patchFromChangedProps(nextProps, this.state, [
      'query',
      'status',
      'endDate',
      'startDate',
      'statusGroup',
      'inboundProgramId',
    ]);

    if (!nextProps.isLoading && this.props.isLoading) {
      statePatch = { ...statePatch, ignoreParentPropsChange: false };
    }

    if (statePatch) this.setState(statePatch);
  }

  onChangeInboundProgramSearchValue = (inboundProgramSearchValue) =>
    this.setState({ inboundProgramSearchValue });

  onSelectInboundProgram = (selectedInboundProgram) =>
    this.setState(
      {
        selectedInboundProgram,
        inboundProgramSearchValue: selectedInboundProgram.customerName,
      },
      () => this.triggerChange(),
    );

  getTotalForType = (type) => {
    const { totalsByStatus } = this.props;

    const typeGroupsForTotals = {
      [CASE_STATUS.dispatched]: [
        CASE_STATUS.dispatched,
        CASE_STATUS.enRoute,
        CASE_STATUS.arrived,
      ],
    };

    return (typeGroupsForTotals[type] || [type]).reduce(
      (total, t) => total + totalsByStatus[t] || 0,
      0,
    );
  };

  clearSelectedInboundProgram = () =>
    this.setState(
      {
        selectedInboundProgram: { id: ALL_FILTER_OPTION.id },
        inboundProgramSearchValue: '',
      },
      () => this.triggerChange(),
    );

  triggerChange = (delayed) => {
    const { selectedInboundProgram, sortBy } = this.state;

    this.props.updateSearchParams(
      {
        ...omit(this.state, [
          'ignoreParentPropsChange',
          'selectedInboundProgram',
          'inboundProgramSearchValue',
        ]),
        sortBy,
        before: null,
        after: null,
        inboundProgramId:
          selectedInboundProgram.id === ALL_FILTER_OPTION.id
            ? null
            : selectedInboundProgram.id,
      },
      { delayBy: delayed ? QUERY_CHANGE_TRIGGER_DELAY : 0 },
    );
  };

  handleStatusGroupChange = ({ target: { value: statusGroup } }) => {
    const { sortBy } = this.props;

    this.setState(
      {
        statusGroup,
        status: null,
        sortBy: SORTABLE_COLUMNS_PER_GROUP[statusGroup].includes(sortBy)
          ? sortBy
          : null,
      },
      this.triggerChange,
    );
  };

  handleQueryChange = ({ target: { value } }) =>
    this.setState(
      { query: value, ignoreParentPropsChange: this.props.isLoading },
      () => this.triggerChange(true),
    );

  handleQueryClear = () => this.setState({ query: '' }, this.triggerChange);

  handleSubmit = (event) => {
    event.preventDefault();
    this.triggerChange();
  };

  handleStatusCheckboxToggle = (type, isChecked) => {
    const status = this.state.status || [];

    this.setState(
      { status: isChecked ? [...status, type] : without(status, type) },
      this.triggerChange,
    );
  };

  handleDateRangeChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate }, this.triggerChange);

  render() {
    const { query, status, statusGroup, startDate, endDate } = this.state;
    const { mode, isAutoRefreshing, isLoading, isNavExpanded } = this.props;
    const { selectedInboundProgram, inboundProgramSearchValue } = this.state;

    const statusGroupCheckboxes = FILTERABLE_STATUSES_PER_GROUP[statusGroup];

    return (
      <Container modifiers="padScale_0">
        <Row modifiers="middle">
          <Column style={{ marginRight: 10 }} modifiers={['padScale_0']}>
            <H1 modifiers="fontWeightLight">
              {mode === 'default' && <Trans>Cases</Trans>}
              {mode === 'highlightedOnly' && (
                <Trans>Cases with reported FIXPIX mismatches</Trans>
              )}
            </H1>
          </Column>
          {mode === 'default' && (
            <>
              <Column modifiers={['padScale_0']}>
                <Row modifiers="middle">
                  <Column modifiers="padScaleX_2">
                    <RadioButton
                      id="search_all_cases"
                      name="statusGroup"
                      value={STATUS_GROUPS_NAMES.all}
                      label={<Trans>All</Trans>}
                      checked={statusGroup === STATUS_GROUPS_NAMES.all}
                      onChange={this.handleStatusGroupChange}
                    />
                  </Column>
                  <Column modifiers="padScaleX_2">
                    <RadioButton
                      id="search_open_cases"
                      name="statusGroup"
                      value={STATUS_GROUPS_NAMES.open}
                      label={<Trans>Open</Trans>}
                      checked={statusGroup === STATUS_GROUPS_NAMES.open}
                      onChange={this.handleStatusGroupChange}
                    />
                  </Column>
                  <Column modifiers="padScaleX_2">
                    <RadioButton
                      id="search_closed_cases"
                      name="statusGroup"
                      value={STATUS_GROUPS_NAMES.closed}
                      label={<Trans>Closed</Trans>}
                      checked={statusGroup === STATUS_GROUPS_NAMES.closed}
                      onChange={this.handleStatusGroupChange}
                    />
                  </Column>
                </Row>
              </Column>

              <Column modifiers={['col', 'end', 'padScale_0']}>
                <HighlightedCasesLink />
              </Column>
            </>
          )}
        </Row>
        <Row>
          <Column modifiers="padScaleY_0">
            <Text modifiers={['small', 'textLight']}>
              {isAutoRefreshing ? (
                <Trans>Reloading...</Trans>
              ) : (
                <span>&nbsp;</span>
              )}
            </Text>
          </Column>
        </Row>
        {mode === 'default' && (
          <>
            <Row>
              <Column modifiers={['col', 'padScaleX_0', 'padScaleY_2']}>
                <form onSubmit={this.handleSubmit}>
                  <InputGroup.Row modifiers={['top']}>
                    <InputGroup.Column
                      style={{ marginBottom: '-1px' }}
                      modifiers={['col']}
                    >
                      <InputField
                        name="query"
                        value={query}
                        onChange={this.handleQueryChange}
                        placeholder={i18n._(inputPlaceholder)}
                      >
                        <InputField.Icon name="search" />
                        <Column modifiers={['col', 'padScale_0']}>
                          <Row>
                            <InputField.Label>
                              <Trans>Case</Trans>
                            </InputField.Label>
                          </Row>
                          <Row>
                            <InputField.TextField autoFocus />
                            {!!query && (
                              <InputField.ActionButton
                                icon="times"
                                type="button"
                                onClick={this.handleQueryClear}
                                modifiers={['hoverDanger']}
                              />
                            )}
                          </Row>
                        </Column>
                      </InputField>
                    </InputGroup.Column>
                    <DateRangeSelectorDropdown
                      key={`${startDate}-${endDate}`}
                      onChange={this.handleDateRangeChange}
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <InboundProgramSelector
                      clearSelectedInboundProgram={
                        this.clearSelectedInboundProgram
                      }
                      inboundProgramSearchValue={inboundProgramSearchValue}
                      onChangeInboundProgramSearchValue={
                        this.onChangeInboundProgramSearchValue
                      }
                      onSelectInboundProgram={this.onSelectInboundProgram}
                      selectedInboundProgram={selectedInboundProgram}
                    />
                  </InputGroup.Row>
                </form>
              </Column>
            </Row>
            {statusGroupCheckboxes.length > 1 && (
              <CheckboxesContainer isNavExpanded={isNavExpanded}>
                {statusGroupCheckboxes.map((type) => (
                  <StatusCheckbox
                    key={type}
                    type={type}
                    total={this.getTotalForType(type)}
                    checked={!!status && status.includes(type)}
                    onChange={this.handleStatusCheckboxToggle}
                    isLoading={isLoading && !isAutoRefreshing}
                    isNavExpanded={isNavExpanded}
                  />
                ))}
              </CheckboxesContainer>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default compose(
  setDisplayName('CasesSearch__Filters'),
  withContext(CasesSearchContext),
  connect(
    (state) => ({
      isNavExpanded: leftNavStateSelector(state).get('isExpanded'),
    }),
    () => ({}),
  ),
)(Filters);
