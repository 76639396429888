import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, renameProp, setDisplayName } from 'recompose';

import { Tooltip, ButtonIconRectangle, Icon } from 'base-components';

import { WithClickToCall } from 'compositions/ClickToCall';

export function ContactCallButton({
  enableCallButton,
  outboundCallsEnabled,
  placeCall,
}) {
  const enableButton = enableCallButton && outboundCallsEnabled;
  return (
    <Tooltip position="top">
      <Tooltip.Target>
        <ButtonIconRectangle
          disabled={!enableButton}
          modifiers={enableButton || 'disabled'}
          onClick={placeCall}
          type="button"
        >
          <Icon name="phone" />
        </ButtonIconRectangle>
      </Tooltip.Target>
      {enableButton && (
        <Tooltip.Content>
          <Trans>Call Contact</Trans>
        </Tooltip.Content>
      )}
    </Tooltip>
  );
}

ContactCallButton.propTypes = {
  enableCallButton: PropTypes.bool.isRequired,
  outboundCallsEnabled: PropTypes.bool.isRequired,
  placeCall: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CallContactButton'),
  WithClickToCall,
  renameProp('enabled', 'outboundCallsEnabled'),
)(ContactCallButton);
