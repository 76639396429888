/* eslint-disable import/prefer-default-export */

import moment from 'moment-timezone';
import { filter, flatten, flow, get, groupBy, map, orderBy } from 'lodash';

import { processNodes as processFieldUpdates } from '../../FieldUpdates/withCaseFieldUpdates/utils';

const getNodeType = (node) => get(node, '__typename');
const isFieldUpdates = (node) => getNodeType(node) === 'CaseHistoryEntry';

const timestampPropByNodeType = {
  CaseHistoryEntryChange: 'recordedAt',
  CaseCall: 'startTime',
  CaseNote: 'createdAt',
  StatusHistory: 'changedAt',
  CaseEmail: 'insertedAt',
  CaseAutomatedCallEntry: 'recordedAt',
  CaseHighlightEntry: 'recordedAt',
};

const getTimestampProp = (node) => timestampPropByNodeType[getNodeType(node)];

const getTimestamp = ({ timestamp }) =>
  moment(timestamp).startOf('day').format('x');

export const processNodes = (_nodes, order = 'desc') =>
  flow([
    (nodes) => [
      ...filter(nodes, (n) => !isFieldUpdates(n)),
      ...processFieldUpdates(filter(nodes, (n) => isFieldUpdates(n))),
    ],
    (nodes) => flatten(nodes),
    (nodes) =>
      map(nodes, (n) => ({ ...n, timestamp: get(n, getTimestampProp(n)) })),
    (nodes) => orderBy(nodes, 'timestamp', order),
    (nodes) =>
      orderBy(
        map(groupBy(nodes, getTimestamp), (entries, timestamp) => ({
          timestamp,
          entries,
        })),
        'timestamp',
        order,
      ),
  ])(_nodes);
