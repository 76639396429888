import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { find, get, last } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Divider, Text, GhostIndicator, Icon } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import ElapsedTime from 'components/ElapsedTime';
import withContext from 'utils/withContext';
import CaseStatusBadge from 'elements/CaseStatusBadge';
import TextGhostIndicator from 'elements/TextGhostIndicator';
import getActiveCaseDelay from 'utils/getActiveCaseDelay';
import eventsFromStatusHistory from 'utils/caseEventsFromStatusHistory';
import { CaseStatusContext, CASE_STATUS } from 'compositions/CaseStatus';

import { useCaseDelays } from './withCaseDelays';

const labelForStatus = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`Dispatched - Enroute`,
  [CASE_STATUS.arrived]: t`Dispatched - Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
};

export function CaseCurrentStatus(props) {
  const { caseId, caseNumber, status } = props;
  const { createdAt, statusHistory, isLoadingCaseStatus } = props;
  const delaysData = useCaseDelays(caseId, caseNumber, status);
  const { caseDelays, isLoadingCaseDelays } = delaysData;
  const isLoading = isLoadingCaseStatus || isLoadingCaseDelays;

  const events = eventsFromStatusHistory({
    status,
    statusHistory,
    caseCreatedAt: createdAt,
  });

  // Because `eventsFromStatusHistory` transforms "closed_cancelled" status
  // into just "closed", and "status" comes is as "closed_cancelled", we get
  // undefined here. Copying eventsFromStatusHistory behavior to grab the "statusDate"
  const isLastClosedCancelled =
    status === CASE_STATUS.closed_canceled &&
    get(last(statusHistory), 'newStatus') === CASE_STATUS.closed_canceled;

  const newStatus = isLastClosedCancelled ? CASE_STATUS.closed : status;
  const statusDate = get(find(events, { newStatus }), 'createdAt');
  const activeDelay = getActiveCaseDelay(caseDelays);

  return (
    <Container modifiers="padScale_0">
      <Row>
        <Column modifiers={['padScaleX_4', 'padScaleY_0']}>
          <Divider modifiers="vertical" />
        </Column>
        <Column
          style={{ alignSelf: 'center', paddingRight: px2rem(10) }}
          modifiers="padScale_0"
        >
          {isLoading ? (
            <GhostIndicator
              style={{ width: px2rem(30), height: px2rem(30), margin: 0 }}
            />
          ) : (
            <CaseStatusBadge status={status} />
          )}
        </Column>
        <Column modifiers="padScale_0">
          <Row>
            <Column modifiers="padScale_0">
              <Text>
                {isLoading ? (
                  <TextGhostIndicator style={{ margin: `0 0 ${px2rem(2)}` }}>
                    xxxx xxxxxxxx
                    <Trans id={labelForStatus[CASE_STATUS.new]} />
                  </TextGhostIndicator>
                ) : (
                  <>
                    <Trans id={labelForStatus[status]} />
                    {activeDelay && (
                      <>
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        <Icon name="hourglass" modifiers={['danger', 'mini']} />
                        &nbsp;
                        <Text modifiers="danger">
                          <Trans>Delayed</Trans>
                        </Text>
                      </>
                    )}
                  </>
                )}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers="padScale_0">
              <Text modifiers={['small', 'textLight']}>
                {isLoading ? (
                  <TextGhostIndicator style={{ margin: `${px2rem(2)} 0 0` }}>
                    xx xxx, xx:xx xx
                  </TextGhostIndicator>
                ) : (
                  <>
                    {moment(statusDate).format('D MMM, h:mm A z')}
                    {activeDelay && (
                      <>
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        <Trans>
                          <ElapsedTime
                            inline
                            sinceTime={activeDelay.startTime}
                          />{' '}
                          Delay
                        </Trans>
                      </>
                    )}
                  </>
                )}
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}

CaseCurrentStatus.propTypes = {
  caseId: PropTypes.string,
  caseNumber: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  isLoadingCaseStatus: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      changedAt: PropTypes.string,
      oldStatus: PropTypes.string,
      newStatus: PropTypes.string,
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('CaseCurrentStatus'),
  withContext(CaseStatusContext),
)(CaseCurrentStatus);
