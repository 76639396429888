/* eslint-disable import/prefer-default-export */
export const services = [
  {
    type: 'url',
    label: 'Colony Tire',
    link: 'https://www.onlinemerchantcenter.com/admin/login.taf',
  },
  {
    type: 'url',
    label: `Pete's Road Service`,
    link: 'https://secure.axiaepay.com/login',
  },
  {
    type: 'url',
    label: 'Snider Tire',
    link: 'https://vault.trustcommerce.com/bbt/',
  },
  {
    type: 'url',
    label: 'STTC',
    link: 'https://vt.globalpay.com/admin/login.aspx',
  },
  {
    type: 'url',
    label: 'T&W Tire',
    link: 'https://globalgatewaye4.firstdata.com/login',
  },
  {
    type: 'url',
    label: 'Tires Centers West',
    link: 'https://www.onlinemerchantcenter.com/admin/login.taf',
  },
  {
    type: 'url',
    label: 'Valley Tire',
    link: 'https://ww2.heartlandportico.com/vt/auth/login',
  },
  {
    type: 'url',
    label: 'Ziegler Tire',
    link: 'https://globalgatewaye4.firstdata.com/',
  },
];
