import { get } from 'lodash';

import { fieldIds } from './constants';

export const isTerminalLocationRequired = (billingPreferences) =>
  get(billingPreferences, 'terminalLocation', '').toUpperCase() === 'REQUIRED';

export const buildCustomerSearchValue = (customer) => {
  const customerName = get(customer, 'name', '');
  const customerCity = get(customer, 'city', '');
  const customerState = get(customer, 'state', '');
  if (customerName) {
    const middleDot = String.fromCharCode(183);
    return `${customerName} ${middleDot} ${customerCity}, ${customerState}`;
  }
  return '';
};

export function setCaseCustomerPanelStatus(props) {
  const { customer } = props;
  const { customerDomicile, domicileSearchValue } = props;
  const { customerTerminalLocation } = props;
  const {
    setCasePanelComplete,
    setCasePanelInvalid,
    setCasePanelIncomplete,
    setCasePanelPartial,
  } = props;

  const validation = [
    {
      test: () => domicileSearchValue && !customerDomicile,
      status: 'invalid',
      field: fieldIds.national.domicile,
    },
    {
      test: () =>
        isTerminalLocationRequired(get(customer, 'billingPreferences')) &&
        !customerTerminalLocation,
      status: 'missing',
      field: fieldIds.national.terminal,
    },
    {
      test: () => !get(customer, 'name'),
      status: 'incomplete',
      field: fieldIds.national.customer,
    },
    {
      test: () => get(customer, 'name'),
      status: 'complete',
      field: fieldIds.national.customer,
    },
  ];

  const fieldStatus = validation.find((fn) => fn.test());

  const newStatus = get(fieldStatus, 'status', 'complete');

  const setStatus = {
    invalid: () => {
      setCasePanelInvalid((status) => ({
        ...status.fields,
        [fieldStatus.field]: 'invalid',
      }));
    },
    incomplete: () => {
      setCasePanelIncomplete((status) => ({
        ...status.fields,
        [fieldStatus.field]: 'incomplete',
      }));
    },
    missing: () => {
      setCasePanelPartial((status) => ({
        ...status.fields,
        [fieldStatus.field]: 'missing',
      }));
    },
    complete: () => {
      setCasePanelComplete();
    },
  }[newStatus];

  setStatus();
}
