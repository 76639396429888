import React from 'react';
import moment from 'moment-timezone';
import { Query } from '@apollo/client/react/components';
import { curry, get, intersection, uniq } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus';

import searchCasesQuery, {
  NAME as SEARCH_CASES_QUERY_NAME,
} from './searchCasesQuery';
import {
  sanitizeSortBy,
  sanitizeSortDirection,
  sanitizeStatusGroup,
} from '../utils';
import {
  STATUS_GROUPS,
  STATUS_GROUPS_NAMES,
  ITEMS_PER_PAGE,
  DEFAULT_STATUS_GROUP,
  COLUMN_NAME_TO_API_NAME,
  FILTERABLE_STATUSES_PER_GROUP,
} from '../constants';

export const buildVariables = (props) => {
  const { sortDirection, inboundProgramId } = props;
  const { after, query, sortBy, before, status: selectedStatuses } = props;
  const { endDate, startDate, statusGroup = DEFAULT_STATUS_GROUP } = props;

  const status = get(selectedStatuses, 'length')
    ? intersection(FILTERABLE_STATUSES_PER_GROUP.all, selectedStatuses)
    : STATUS_GROUPS[
        sortBy === 'ETA' ? STATUS_GROUPS_NAMES.escalation : statusGroup
      ];

  if (status.includes(CASE_STATUS.dispatched)) {
    status.push(CASE_STATUS.enRoute, CASE_STATUS.arrived);
  }

  const startTime = startDate
    ? moment(startDate).startOf('day').toISOString()
    : undefined;

  const endTime =
    endDate || startDate
      ? moment(endDate || startDate)
          .endOf('day')
          .toISOString()
      : undefined;

  return {
    query: query || undefined,
    status: uniq(status),
    // Without this, Apollo won't rerun the query if the
    // variables haven't changed, regardless of the fetchPolicy.
    cacheBuster: Math.random(),
    sort: {
      fields: [COLUMN_NAME_TO_API_NAME[sortBy]],
      direction: sortDirection.toUpperCase(),
    },
    before,
    after,
    pageSize: ITEMS_PER_PAGE,
    startTime,
    endTime,
    inboundProgramNumberId: inboundProgramId
      ? Number(inboundProgramId)
      : undefined,
  };
};

// Make sure we are dealing with valid sortBy and sortDirection values
export const getSanitizedSortProps = ({
  sortBy,
  sortDirection,
  statusGroup,
}) => ({
  sortBy: sanitizeSortBy(sortBy, statusGroup),
  sortDirection: sanitizeSortDirection(sortDirection),
});

const getTotals = (data) =>
  get(data, 'searchCases.cases.countInfo.countByStatus', []).reduce(
    (acc, { status, count }) => ({ ...acc, [status]: count }),
    {},
  );

export const buildQueryChild = curry(
  (
    WrappedComponent,
    componentProps,
    { data, loading, error, refetch, networkStatus, stopPolling, startPolling },
  ) => (
    <WrappedComponent
      {...componentProps}
      cases={get(data, 'searchCases.cases.edges', [])}
      error={error}
      refetch={refetch}
      isLoading={!!loading}
      pagination={get(data, 'searchCases.cases.pageInfo', {
        hasNextPage: false,
        hasPreviousPage: false,
      })}
      statusGroup={sanitizeStatusGroup(componentProps)}
      totalsByStatus={getTotals(data)}
      stopAutoRefresh={stopPolling}
      startAutoRefresh={startPolling}
      isAutoRefreshing={networkStatus === 6} // 6 means it is polling
    />
  ),
);

const withCasesSearchResults = (WrappedComponent) => (componentProps) => (
  <Query
    query={searchCasesQuery}
    context={{ reportErrorsToBugSnag: false }}
    variables={buildVariables({
      ...componentProps,
      ...getSanitizedSortProps(componentProps),
    })}
    errorPolicy="all"
    fetchPolicy="network-only"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export { SEARCH_CASES_QUERY_NAME };
export default withCasesSearchResults;
