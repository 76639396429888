import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { Accordion, MessageMedium } from 'base-components';

import withContext from 'utils/withContext';

import Account from './Account';
import Contacts from './Contacts';
import EmailNotifications from './EmailNotifications';
import ServicePreferences from './ServicePreferences';
import BillingPreferences from './BillingPreferences';
import TirePreferences from './TirePreferences';
import WheelPreferences from './WheelPreferences';
import CustomerWidgetContext from '../CustomerWidgetContext';

const getIgnoredTypes = (customerTypes) => [
  get(customerTypes, 'OTHER.type'),
  get(customerTypes, 'CUSTOM_STORE.type'),
];

const getTypesWithDetails = (customerTypes) => [
  get(customerTypes, 'NATIONAL.type'),
  get(customerTypes, 'STORE.type'),
];

export function CustomerDetails(props) {
  const { caseId, caseCustomerType, customerType } = props;
  const { customerTypes, caseStatus, isReadOnlyCase, caseNumber } = props;

  const isStore = customerType === get(customerTypes, 'STORE.type');

  // customerType refers to the locally saved customer type, like if you click
  // over to a different option but haven't saved a customer caseCustomerType
  // refers to the saved option.
  if (getIgnoredTypes(customerTypes).includes(customerType)) return null;

  if (getTypesWithDetails(customerTypes).includes(caseCustomerType)) {
    const accordionProps = {
      caseId,
      caseNumber,
      caseStatus,
      customerType,
      customerTypes,
      isReadOnlyCase,
    };

    return (
      <div style={{ marginTop: 15 }}>
        <Accordion.Divider />
        <Account {...accordionProps} />
        <Accordion.Divider />
        <Contacts {...accordionProps} />
        <Accordion.Divider />
        <ServicePreferences {...accordionProps} />
        <Accordion.Divider />
        <BillingPreferences {...accordionProps} />
        <Accordion.Divider />
        <TirePreferences {...accordionProps} />
        <Accordion.Divider />
        <WheelPreferences {...accordionProps} />
        <Accordion.Divider />
        <EmailNotifications {...accordionProps} />
      </div>
    );
  }

  return (
    <Row modifiers={['center']}>
      <Column modifiers={['col']}>
        <MessageMedium>
          <MessageMedium.Header>
            <MessageMedium.Icon name="search" />
          </MessageMedium.Header>
          <MessageMedium.Section>
            <Column modifiers={['col']}>
              <MessageMedium.Text>
                {!isStore && (
                  <Trans>
                    Enter at least three characters to start searching for a
                    fleet.
                  </Trans>
                )}
                {isStore && (
                  <Trans>
                    Enter at least three characters to start searching for a
                    store account.
                  </Trans>
                )}
              </MessageMedium.Text>
            </Column>
          </MessageMedium.Section>
        </MessageMedium>
      </Column>
    </Row>
  );
}

CustomerDetails.propTypes = {
  caseId: PropTypes.string,
  caseStatus: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseCustomerType: PropTypes.string,
  customerType: PropTypes.string.isRequired,
  customerTypes: PropTypes.objectOf(
    PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
  ).isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
};

CustomerDetails.defaultProps = {
  caseId: undefined,
  caseCustomerType: null,
};

export default compose(
  setDisplayName('CustomerDetails'),
  withContext(CustomerWidgetContext),
)(CustomerDetails);
