import React from 'react';
import PropTypes from 'prop-types';

import { A, Link, Icon } from 'base-components';
import { Row, Column } from 'styled-components-grid';

/**
 * A `Link` that includes an icon to the left of the text.
 * The children of this should be the link text.
 */
export default function LinkWithIcon({
  to,
  icon,
  external,
  children,
  iconModifiers,
  ...rest
}) {
  const modifiers = [
    'colorInherit',
    ...(Array.isArray(iconModifiers) ? iconModifiers : [iconModifiers]),
  ];

  const Component = external ? A : Link;

  return (
    <Component {...{ [external ? 'href' : 'to']: to }} {...rest}>
      <Row modifiers="middle">
        <Column modifiers="padScaleX_0">
          <Icon name={icon} modifiers={modifiers} />
        </Column>
        <Column modifiers="padScaleX_2">{children}</Column>
      </Row>
    </Component>
  );
}

LinkWithIcon.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  external: PropTypes.bool,
  children: PropTypes.node.isRequired,
  iconModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

LinkWithIcon.defaultProps = {
  external: false,
  iconModifiers: undefined,
};
