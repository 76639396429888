import { pick } from 'lodash';
import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const CASE_STATUS = buildEnum([
  'new',
  'dispatch',
  'dispatched',
  'enRoute',
  'arrived',
  'rolling',
  'closed',
  'closed_canceled',
  'canceled',
]);

export const PANEL_STATUSES = buildEnum([
  'complete',
  'incomplete',
  'invalid',
  'partial',
]);

export const ALL_PANELS_CONFIG = {
  inboundProgram: {
    label: t`INBOUND PROGRAM`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  customer: {
    label: t`FLEET`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  contact: {
    label: t`CONTACT`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  payment: {
    label: t`PAYMENT`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  assetLocation: {
    label: t`ASSET LOCATION`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  dealerSelection: {
    label: t`SERVICE PROVIDER`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  request: {
    label: t`REQUEST`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
  },
  assetValidation: {
    label: t`ASSET VALIDATION`,
    status: { panel: PANEL_STATUSES.incomplete, fields: {} },
    visibleIf: ({ requiresAssetValidation }) => !!requiresAssetValidation,
  },
};

// ALL_PANELS_CONFIG will be used in CaseStatusContext
// if there is no specific config for a given status.
export const CASE_PANELS_CONFIG = {
  [CASE_STATUS.new]: pick(
    ALL_PANELS_CONFIG,
    'inboundProgram',
    'customer',
    'contact',
    'payment',
    'assetLocation',
    'request',
    'assetValidation',
  ),
};

export const CASE_PANELS = Object.keys(ALL_PANELS_CONFIG).reduce(
  (panelNames, key) => ({ ...panelNames, [key]: key }),
  {},
);

export const FIXPIX_PUSH_RESULT = buildEnum([
  'error',
  'success',
  'outdated',
  'notAttempted',
]);
