import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';
import { CASE_STATUS } from 'compositions/CaseStatus/constants';

export const DELAY_REASONS = buildEnum([
  'waitingOnPo',
  'waitingOnCreditCard',
  'waitingForAuthorization',
  'unableToReachAsset',
  'checkingTireInventory',
  'other',
]);

export const ignoredReasons = [DELAY_REASONS.checkingTireInventory];

export const labelForReason = {
  [DELAY_REASONS.waitingOnPo]: t`Waiting on PO`,
  [DELAY_REASONS.waitingOnCreditCard]: t`Waiting on Credit Card`,
  [DELAY_REASONS.waitingForAuthorization]: t`Waiting for Authorization`,
  [DELAY_REASONS.unableToReachAsset]: t`Unable to Locate/Access Asset`,
  [DELAY_REASONS.checkingTireInventory]: t`Checking Tire Inventory`,
  [DELAY_REASONS.other]: t`Other`,
};

export const labelForStatus = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`Dispatched - Enroute`,
  [CASE_STATUS.arrived]: t`Dispatched - Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
};
