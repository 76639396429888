import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H3, InputGroup } from 'base-components';

import Panel from 'blocks/Panel';

import withContext from 'utils/withContext';
import ValidationRulesMessage from 'compositions/CaseAssetValidationPanel/ValidationRulesMessage';

import CaseInboundProgramPanelContext from './CaseInboundProgramPanelContext';
import InboundProgramLocationSelector from './InboundProgramLocationSelector';
import InboundProgramSelector from './InboundProgramSelector';

const ValidationMessage = withContext(CaseInboundProgramPanelContext)(
  ({ requiresValidationForAssetTypes }) => {
    if (!requiresValidationForAssetTypes.length) return null;

    return (
      <Row>
        <Column modifiers={['col', 'padScaleY_2']} style={{ paddingTop: 0 }}>
          <ValidationRulesMessage
            message={<Trans>Has validation rules for:</Trans>}
            requiresValidationForAssetTypes={requiresValidationForAssetTypes}
          />
        </Column>
      </Row>
    );
  },
);

function CaseInboundProgramPanel({ caseNumber }) {
  return (
    <CaseInboundProgramPanelContext caseNumber={caseNumber}>
      <Panel modifiers="padScaleX_3" data-testid="CaseInboundProgramPanel">
        <Row>
          <Column modifiers="padScaleY_2">
            <H3 modifiers="fontWeightRegular">
              <Trans>Inbound Program</Trans>
            </H3>
          </Column>
        </Row>
        <InputGroup
          style={{ marginBottom: px2rem(9) }}
          modifiers={['padScale_1']}
        >
          <InboundProgramSelector />
          <InboundProgramLocationSelector />
        </InputGroup>
        <ValidationMessage />
      </Panel>
    </CaseInboundProgramPanelContext>
  );
}

CaseInboundProgramPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

export default CaseInboundProgramPanel;
