import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { sizes } from 'reactive-container';
import { get, isEmpty } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import {
  Text,
  Tooltip,
  ButtonGroup,
  MessageSmall,
  GhostIndicator,
  QuickActionButton,
} from 'base-components';

import {
  Row,
  Column,
  Container,
  ReactiveContainer,
} from 'styled-components-grid/reactive';

import TravelEstimate from 'compositions/TravelEstimate';

import { evaluateDealerType } from 'utils/dealerFilter';
import { OPERATING_STATUS_VALUES } from 'utils/operatingStatusFilter';

import DealerMarker from '../../DealerMarker';
import ContactsPopover from './ContactsPopover';
import DealerName from './DealerName';
import HoursPopover from './HoursPopover';
import NotesPopover from './NotesPopover';
import contactPropType from './contactPropType';
import ServicesPopover from './ServicesPopover';
import ActiveCasesModalTrigger from './ActiveCasesModalTrigger';
import DealerCallButton from './DealerCallButton';

import useAfterHoursData from './useAfterHoursData';

export function Dealer(props) {
  const { didFailLoadingAfterHoursData } = props;
  const { onDealerHighlight, reloadAfterHoursData } = props;
  const { isLoadingAfterHoursData, displayDetailViewEnabled } = props;
  const { activeAfterHoursContacts, afterHoursRotationContacts } = props;
  const { index, dealer, allContacts, onDealerSelect, onOutboundCall } = props;

  const { phoneAfterHours, phoneAfterHours2 } = dealer;
  const { displayAddress, travelEstimate, operatingStatus } = dealer;
  const { id, name, phone, notes, shipTo, billTo, features, location } = dealer;

  const { distance, time } = travelEstimate;
  const { city, postalCode, province, streetAddress } = displayAddress;

  const isTechRotationActive = !isEmpty(
    get(activeAfterHoursContacts, 'contacts.primaryContact'),
  );

  const afterHoursContacts = isTechRotationActive
    ? activeAfterHoursContacts
    : afterHoursRotationContacts;

  const { contacts = {} } = afterHoursContacts || {};
  const { start, end } = afterHoursRotationContacts || {};

  const dealerType = evaluateDealerType(dealer);

  const isOpen =
    get(dealer, 'operatingStatus.open') === OPERATING_STATUS_VALUES.OPEN;

  const isERSOpen =
    isTechRotationActive ||
    (get(operatingStatus, 'open') === OPERATING_STATUS_VALUES.CLOSED &&
      get(operatingStatus, 'ersOpen') === OPERATING_STATUS_VALUES.OPEN);

  const isAdditionalContactAvailable =
    !isEmpty(contacts.backupContact) ||
    !isEmpty(contacts.finalContact) ||
    !isEmpty(contacts.primaryContact);

  const isPhoneAfterHoursDuplicate = phoneAfterHours === phone;
  const isPhoneAfterHours2Duplicate = phoneAfterHours === phoneAfterHours2;

  const getPhoneNumber = () => {
    const numbers = [
      isERSOpen && get(contacts, 'primaryContact.primaryPhoneNumber'),
      isOpen && phone,
      phoneAfterHours,
      phone,
    ];

    const contactName =
      isERSOpen && contacts.primaryContact
        ? contacts.primaryContact.name
        : name;

    return { phoneNumber: numbers.find(Boolean), contactName };
  };

  const showAdditionalContacts =
    isAdditionalContactAvailable ||
    (!isPhoneAfterHoursDuplicate && phoneAfterHours) ||
    (!isPhoneAfterHours2Duplicate && phoneAfterHours2);

  const contactList = {
    ...contacts,
    storeContact: {
      jobTitle: '',
      name,
      primaryPhoneNumber: phone,
    },
    allContacts,
  };

  if (phoneAfterHours) {
    contactList.afterHoursContact = {
      jobTitle: '',
      name,
      primaryPhoneNumber: phoneAfterHours,
      secondaryPhoneNumber: !isPhoneAfterHours2Duplicate
        ? phoneAfterHours2
        : undefined,
    };
  }

  const rotationSchedule = { isERSOpen, start, end };

  const { phoneNumber, contactName } = getPhoneNumber();

  const renderContacts = () => {
    if (isLoadingAfterHoursData) {
      return (
        <GhostIndicator
          style={{
            width: px2rem(180),
            height: px2rem(30),
            margin: 0,
          }}
        />
      );
    }

    if (didFailLoadingAfterHoursData) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MessageSmall type="danger">
            <Trans>Failed to load contacts!</Trans>
          </MessageSmall>

          <Tooltip position="left">
            <Tooltip.Target>
              <QuickActionButton
                style={{ marginLeft: 10 }}
                onClick={reloadAfterHoursData}
                modifiers="iconOnly"
              >
                <QuickActionButton.Icon name="refresh" />
              </QuickActionButton>
            </Tooltip.Target>
            <Tooltip.Content>
              <Trans>Reload Provider Contacts</Trans>
            </Tooltip.Content>
          </Tooltip>
        </div>
      );
    }

    return (
      <ButtonGroup>
        {phoneNumber && (
          <DealerCallButton
            contactName={contactName}
            dealer={dealer}
            onOutboundCall={onOutboundCall}
            phoneNumber={phoneNumber}
          />
        )}
        {showAdditionalContacts && (
          <ContactsPopover
            dealer={dealer}
            contacts={contactList}
            isOpen={isOpen}
            rotationSchedule={rotationSchedule}
            isTechRotationActive={isTechRotationActive}
            position={index < 2 ? 'bottomLeft' : 'topLeft'}
            onOutboundCall={onOutboundCall}
          />
        )}
      </ButtonGroup>
    );
  };

  return (
    <ReactiveContainer
      breakpoints={[{ name: sizes.LG, minWidth: 960 }]}
      modifiers="padScaleY_2"
      onMouseEnter={() => onDealerHighlight(dealer)}
      onMouseLeave={() => onDealerHighlight(undefined)}
    >
      <Row
        modifiers="padScaleX_0"
        responsiveModifiers={{ [sizes.LG]: ['middle'] }}
        style={{ flexWrap: 'nowrap' }}
      >
        <Column modifiers="padScaleX_0">
          <DealerMarker type={dealerType}>
            <DealerMarker.Text modifiers="small">
              {`${index + 1}`}
            </DealerMarker.Text>
          </DealerMarker>
        </Column>
        <Column
          modifiers="col"
          responsiveModifiers={{ [sizes.LG]: ['padScaleX_5'] }}
        >
          <Row modifiers="middle">
            <Column style={{ flexShrink: 1 }}>
              <DealerName
                clickable={displayDetailViewEnabled}
                onClick={() => onDealerSelect(id, index, location)}
              >
                {name}
              </DealerName>
            </Column>
            {notes && (
              <Column>
                <NotesPopover
                  notes={notes}
                  position={index < 2 ? 'bottomRight' : 'topRight'}
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text>{streetAddress}</Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text>{`${city}, ${province} ${postalCode}`}</Text>
            </Column>
          </Row>
          {!displayDetailViewEnabled && (
            <Row>
              <Column modifiers={['col', 'padScaleY_0']}>
                <Text modifiers="small">
                  <Trans>Bill to #</Trans>
                  &nbsp;
                  {billTo}
                  &nbsp; &middot; &nbsp;
                  <Trans>Ship to #</Trans>
                  &nbsp;
                  {shipTo}
                </Text>
              </Column>
            </Row>
          )}

          {/* This row is displayed when the component is SMALL */}
          <Row responsiveModifiers={{ [sizes.LG]: ['noDisplay'] }}>
            <Column modifiers="col">
              <Row>
                <Column style={{ paddingLeft: 0 }}>
                  <TravelEstimate distance={distance} duration={time} />
                </Column>
                <Column modifiers="padScaleX_2">
                  <HoursPopover
                    dealer={dealer}
                    position={index < 2 ? 'bottomRight' : 'topRight'}
                  />
                </Column>
                {!!dealer.activeCases && (
                  <Column modifiers="padScaleX_2">
                    <ActiveCasesModalTrigger dealer={dealer} />
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
        </Column>

        {/* This column is displayed when the component is SMALL */}
        <Column responsiveModifiers={{ [sizes.LG]: ['noDisplay'] }}>
          <Container
            modifiers={[
              'display_flex',
              'flex_column',
              'height_100',
              'padScale_0',
            ]}
          >
            <Row modifiers={['flex_1', 'end']}>
              <Column modifiers="padScale_0">{renderContacts()}</Column>
            </Row>

            <Row
              style={{ alignContent: 'flex-end' }}
              modifiers={['flex_1', 'end']}
            >
              <ButtonGroup>
                <ServicesPopover
                  services={features}
                  position={index < 2 ? 'bottomLeft' : 'topLeft'}
                />
              </ButtonGroup>
            </Row>
          </Container>
        </Column>

        {/* These columns are displayed when the component is LARGE */}
        <Column
          responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
          style={{ paddingLeft: px2rem(20) }}
        >
          <TravelEstimate distance={distance} duration={time} />
        </Column>
        <Column
          responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
          style={{ paddingLeft: px2rem(20) }}
        >
          <HoursPopover
            dealer={dealer}
            position={index < 2 ? 'bottomRight' : 'topRight'}
          />
        </Column>
        {!!dealer.activeCases && (
          <Column
            style={{ paddingLeft: px2rem(20) }}
            responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
          >
            <ActiveCasesModalTrigger dealer={dealer} />
          </Column>
        )}
        <Column
          responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
          style={{ paddingLeft: px2rem(20) }}
        >
          <ServicesPopover
            services={features}
            position={index < 2 ? 'bottomLeft' : 'topLeft'}
          />
        </Column>
        <Column
          modifiers="padScale_0"
          responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
          style={{ paddingLeft: px2rem(20) }}
        >
          {renderContacts()}
        </Column>

        <Column
          modifiers="padScale_0"
          responsiveModifiers={{ [sizes.XS]: ['noDisplay'] }}
        />
      </Row>
    </ReactiveContainer>
  );
}

Dealer.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    phone: PropTypes.string,
    phoneAfterHours: PropTypes.string,
    phoneAfterHours2: PropTypes.string,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string.isRequired,
      open: PropTypes.string.isRequired,
    }).isRequired,
    displayAddress: PropTypes.shape({
      city: PropTypes.string,
      postalCode: PropTypes.string,
      province: PropTypes.string,
      streetAddress: PropTypes.string,
    }).isRequired,
    billTo: PropTypes.string.isRequired,
    shipTo: PropTypes.string.isRequired,
    notes: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    location: PropTypes.shape({}),
    travelEstimate: PropTypes.shape({
      time: PropTypes.number,
      distance: PropTypes.number,
    }),
    activeCases: PropTypes.number,
    allContacts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  displayDetailViewEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onDealerHighlight: PropTypes.func.isRequired,
  onDealerSelect: PropTypes.func.isRequired,
  onOutboundCall: PropTypes.func,

  allContacts: PropTypes.arrayOf(PropTypes.shape({})),

  activeAfterHoursContacts: PropTypes.shape({
    contacts: PropTypes.shape({
      backupContact: contactPropType,
      finalContact: contactPropType,
      primaryContact: contactPropType,
    }),
  }),

  afterHoursRotationContacts: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    contacts: PropTypes.shape({
      backupContact: contactPropType,
      finalContact: contactPropType,
      primaryContact: contactPropType,
    }),
  }),

  reloadAfterHoursData: PropTypes.func.isRequired,
  isLoadingAfterHoursData: PropTypes.bool.isRequired,
  didFailLoadingAfterHoursData: PropTypes.bool.isRequired,
};

Dealer.defaultProps = {
  onOutboundCall: null,
  allContacts: undefined,
  activeAfterHoursContacts: undefined,
  afterHoursRotationContacts: undefined,
};

const LazyDealer = (props) => {
  const { autoReloadInterval } = props;
  const [shouldLoad, setShouldLoad] = useState(props.index <= 5);

  const afterHoursData = useAfterHoursData(props?.dealer?.id, {
    enabled: shouldLoad,
    pollInterval: autoReloadInterval,
  });

  useEffect(() => {
    if (props.index <= 5) return;

    const id = setTimeout(() => setShouldLoad(true), 100);
    return () => clearTimeout(id);
  }, [props.index]);

  if (!shouldLoad) return <div />;

  return <Dealer {...props} {...afterHoursData} />;
};

LazyDealer.propTypes = {
  index: PropTypes.number.isRequired,
  dealer: PropTypes.shape({}),
  autoReloadInterval: PropTypes.number,
};

LazyDealer.defaultProps = {
  autoReloadInterval: undefined,
};

export default React.memo(LazyDealer);

export {
  ContactsPopover,
  DealerName,
  HoursPopover,
  NotesPopover,
  contactPropType,
  ServicesPopover,
};
