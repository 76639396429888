import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, filter, isEqual, lowerCase } from 'lodash';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Dropdown, MessageMedium, Text } from 'base-components';
import { Container } from 'styled-components-grid';

import HighlightText from 'components/HighlightText';

import UnitTypeInputField from './UnitTypeInputField';
import withAssetTypes from './withAssetTypes';
import assetTypeNames from './assetTypeNames';
import trailerTypeNames from './trailerTypeNames';
import withTrailerTypes from './withTrailerTypes';
import withInboundProgram from '../withInboundProgram';

export class UnitTypeDropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    // for this component we really don't care the shape of `values`
    // so long it contains something at a key corresponding to our `name`
    assetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    readOnly: PropTypes.bool.isRequired,
    trailerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    onValidate: PropTypes.func.isRequired,
    isUsxpress: PropTypes.bool.isRequired,
    focusReceiverId: PropTypes.string.isRequired,

    /* eslint-disable react/no-unused-prop-types */
    values: PropTypes.shape().isRequired,
    isLoadingAssetTypes: PropTypes.bool.isRequired,
    isLoadingTrailerTypes: PropTypes.bool.isRequired,
    isLoadingInboundProgram: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  constructor(props) {
    super(props);

    this.state = {
      // Set the initial value of input field
      inputValue: this.getInitialValue(),
      isInvalid: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const wasLoading = this.isLoading(this.props);
    const inputValuesHasChanged = !isEqual(nextProps.values, this.props.values);
    if ((wasLoading && !this.isLoading(nextProps)) || inputValuesHasChanged) {
      this.setState({ inputValue: this.getInitialValue(nextProps) });
    }
  }

  onInputChange = (e) => {
    e.preventDefault();

    this.setState({
      inputValue: e.target.value,
    });
  };

  get assetBaseName() {
    return this.props.name.replace('.assetType', '');
  }

  /**
   * Get the current selected option based on the form values.
   * @return {String}
   */
  getInitialValue = (props = this.props) => {
    if (this.isLoading(props)) {
      return '';
    }

    const { values, name, isUsxpress } = props;
    const assetType = assetTypeNames[get(values, name, '')];

    if (
      isUsxpress &&
      lowerCase(assetType) === lowerCase(assetTypeNames.TRAILER)
    ) {
      return trailerTypeNames[get(values, `${this.assetBaseName}.trailerType`)];
    }

    return assetType;
  };

  isLoading = (props = this.props) => {
    const {
      isLoadingAssetTypes,
      isLoadingTrailerTypes,
      isLoadingInboundProgram,
    } = props;

    return (
      isLoadingAssetTypes || isLoadingTrailerTypes || isLoadingInboundProgram
    );
  };

  handleChange = (_, value) => {
    const { name, handleChange, onValidate, setFieldValue } = this.props;

    if (trailerTypeNames[value]) {
      this.setState(
        { inputValue: trailerTypeNames[value], isInvalid: false },
        () => {
          setFieldValue(name, 'TRAILER', false);
          handleChange({
            target: {
              name: `${this.assetBaseName}.trailerType`,
              value,
            },
          });
          onValidate(true);
        },
      );
    } else if (value) {
      this.setState(
        { inputValue: assetTypeNames[value], isInvalid: false },
        () => {
          setFieldValue(`${this.assetBaseName}.trailerType`, null, false);
          handleChange({ target: { name, value } });
          onValidate(true);
        },
      );
    }
  };

  handleReset = () => {
    const { name, handleChange, onValidate, setFieldValue } = this.props;

    this.setState({ inputValue: '', isInvalid: false }, () => {
      setFieldValue(`${this.assetBaseName}.trailerType`, null, false);
      handleChange({ target: { name, value: null } });
      onValidate(true);
    });
  };

  validate = (isInvalid) => {
    this.setState({ isInvalid }, this.props.onValidate(!isInvalid));
  };

  /**
   * Generate an array of `<Dropdown.ListItem/>` elements corresponding
   * to the given collection of asset unit types.
   */
  renderAssetTypes = (types, highlightText) =>
    types.map((type) => (
      <Dropdown.ListItem key={type} id={type}>
        <Text>
          <HighlightText text={highlightText}>
            {assetTypeNames[type]}
          </HighlightText>
        </Text>
      </Dropdown.ListItem>
    ));

  renderTrailerTypes = (types, highlightText) =>
    types.map((type) => (
      <Dropdown.ListItem key={type} id={type}>
        <Text value={type}>
          <HighlightText text={highlightText}>
            {trailerTypeNames[type]}
          </HighlightText>
        </Text>
      </Dropdown.ListItem>
    ));

  renderErrorMessage = () => (
    <Container style={{ whiteSpace: 'normal' }} className="no-results">
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );

  render() {
    const { assetTypes, readOnly } = this.props;
    const { inputValue, isInvalid } = this.state;
    const { trailerTypes, isUsxpress, focusReceiverId } = this.props;

    const assetTypesWithoutTrailer = filter(
      assetTypes,
      (name) => name !== 'TRAILER',
    );

    const assetTypesToUse = isUsxpress ? assetTypesWithoutTrailer : assetTypes;

    const filteredAssetTypes = filter(assetTypesToUse, (name) =>
      lowerCase(name).includes(lowerCase(inputValue)),
    );

    const filteredTrailerTypes = filter(trailerTypes, (name) =>
      lowerCase(name).includes(lowerCase(inputValue)),
    );
    const activeType =
      filter(
        assetTypesToUse,
        (type) => assetTypeNames[type] === inputValue,
      )[0] || '';

    const isInvalidAssetType =
      !!inputValue &&
      activeType === '' &&
      isUsxpress &&
      !filter(
        Object.values(trailerTypeNames),
        (name) => lowerCase(name) === lowerCase(inputValue),
      )[0];
    const isNotFound =
      isInvalidAssetType &&
      filteredTrailerTypes.length + filteredAssetTypes.length === 0;

    return (
      <>
        <Dropdown
          fullWidth
          hideOnChange
          onChange={this.handleChange}
          readOnly={readOnly}
          disabled={this.isLoading()}
          activeItem={activeType}
          zIndex={2}
        >
          {({ show, toggle, isVisible }) => (
            <>
              <Dropdown.Target onClick={show}>
                <UnitTypeInputField
                  isDropdownVisible={isVisible}
                  onChange={this.onInputChange}
                  onToggle={toggle}
                  onReset={this.handleReset}
                  onBlur={() => this.validate(isNotFound || isInvalidAssetType)}
                  value={inputValue}
                  readOnly={readOnly}
                  isValid={!isInvalid}
                  focusReceiverId={focusReceiverId}
                />
              </Dropdown.Target>
              <Dropdown.Content
                style={{
                  maxHeight: px2rem(300),
                  minWidth: isNotFound ? px2rem(300) : 'unset',
                  overflowY: 'auto',
                  left: isNotFound ? 'auto' : 0,
                }}
              >
                {isUsxpress && (
                  <>
                    {!!filteredTrailerTypes.length && (
                      <Dropdown.SectionHeader>
                        <Text
                          modifiers={[
                            'small',
                            'noWrap',
                            'capitalize',
                            'textLight',
                          ]}
                        >
                          Trailer
                        </Text>
                      </Dropdown.SectionHeader>
                    )}
                    <Dropdown.List>
                      {this.renderTrailerTypes(
                        filteredTrailerTypes,
                        inputValue,
                      )}
                    </Dropdown.List>
                    {!!filteredAssetTypes.length && (
                      <Dropdown.SectionHeader>
                        <Text
                          modifiers={[
                            'small',
                            'noWrap',
                            'capitalize',
                            'textLight',
                          ]}
                        >
                          Other
                        </Text>
                      </Dropdown.SectionHeader>
                    )}
                  </>
                )}
                <Dropdown.List>
                  {this.renderAssetTypes(filteredAssetTypes, inputValue)}
                </Dropdown.List>
                {isNotFound && this.renderErrorMessage()}
              </Dropdown.Content>
            </>
          )}
        </Dropdown>
      </>
    );
  }
}

export default compose(
  setDisplayName('UnitTypeDropdown'),
  withAssetTypes,
  withTrailerTypes,
  withInboundProgram,
)(UnitTypeDropdown);
