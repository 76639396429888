import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import MessageSmall from 'base-components/blocks/MessageSmall';
import { Text } from 'base-components';

import { allAssetTypes, assetValidationRulesPropType } from './constants';

function ValidationRulesMessage(props) {
  const { message, requiresValidationForAssetTypes } = props;
  const total = get(requiresValidationForAssetTypes, 'length') || 0;

  if (!total) return null;

  return (
    <MessageSmall style={{ alignItems: 'flex-start' }}>
      <MessageSmall.Icon
        type="info"
        style={{ marginTop: 3 }}
        modifiers={['mini', 'info']}
      />
      <MessageSmall.Text style={{ whiteSpace: 'initial' }}>
        {message}
        &nbsp;
        {requiresValidationForAssetTypes.map(({ type }, index) => (
          <Fragment key={type}>
            <Text modifiers="fontWeightMedium">
              <Trans id={allAssetTypes[type]} />
            </Text>
            {index < total - 1 && <span>, &nbsp;</span>}
          </Fragment>
        ))}
      </MessageSmall.Text>
    </MessageSmall>
  );
}

ValidationRulesMessage.propTypes = {
  message: PropTypes.node,
  // eslint-disable-next-line react/no-typos
  requiresValidationForAssetTypes: assetValidationRulesPropType.isRequired,
};

ValidationRulesMessage.defaultProps = {
  message: <Trans>Has rules for:</Trans>,
};

export default ValidationRulesMessage;
