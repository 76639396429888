import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { Text, QuickActionButton } from 'base-components';

import MessageSmall from './MessageSmall';

export default function TransferToIVRButton({
  onClick,
  isDisabled,
  showWarning,
}) {
  return (
    <>
      <Row>
        <Column modifiers="col">
          <Text>
            <Trans>Transfer caller to IVR to enter credit card details.</Trans>
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers="col">
          <QuickActionButton
            disabled={isDisabled}
            modifiers={[isDisabled && 'disabled']}
            onClick={onClick}
          >
            <QuickActionButton.Text>
              <Trans>TRANSFER TO IVR</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>
      {showWarning && (
        <Row>
          <Column modifiers={['col', 'padScaleY_2']}>
            <MessageSmall>
              <MessageSmall.Icon type="warning" />
              <MessageSmall.Text>
                <Trans>
                  Failed to capture credit card information.
                  <br />
                  Try again or select a different payment method.
                </Trans>
              </MessageSmall.Text>
            </MessageSmall>
          </Column>
        </Row>
      )}
    </>
  );
}

TransferToIVRButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool.isRequired,
};
