import React from 'react';
import { curry } from 'lodash';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

const setCardHolderNameMutation = gql`
  mutation setCardHolderName($contactId: String!, $cardHolderName: String!) {
    setCardHolderName(
      input: { contactId: $contactId, cardHolderName: $cardHolderName }
    ) {
      message
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, setCardHolderName) => (
    <WrappedComponent
      {...componentProps}
      setCardHolderName={setCardHolderName}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={setCardHolderNameMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withSetCardHolderName = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withSetCardHolderName;
