import { t } from '@lingui/macro';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

export const iconForStatus = {
  [CASE_STATUS.new]: 'briefcase',
  [CASE_STATUS.dispatch]: 'door-enter',
  [CASE_STATUS.dispatched]: 'door-exit',
  [CASE_STATUS.enRoute]: 'door-exit',
  [CASE_STATUS.arrived]: 'door-exit',
  [CASE_STATUS.rolling]: 'road',
  [CASE_STATUS.closed]: 'archive',
  [CASE_STATUS.canceled]: 'briefcase-times',
  [CASE_STATUS.closed_canceled]: 'archive',
};

export const modifierForStatus = {
  [CASE_STATUS.new]: 'info',
  [CASE_STATUS.dispatch]: 'danger',
  [CASE_STATUS.dispatched]: 'warning',
  [CASE_STATUS.enRoute]: 'warning',
  [CASE_STATUS.arrived]: 'warning',
  [CASE_STATUS.rolling]: 'success',
  [CASE_STATUS.closed]: 'success',
  [CASE_STATUS.canceled]: 'secondary',
  [CASE_STATUS.closed_canceled]: 'success',
};

export const titleForStatus = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`Dispatched - En Route`,
  [CASE_STATUS.arrived]: t`Dispatched - Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
};
