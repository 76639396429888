import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, isFunction } from 'lodash';

import DropdownBlock from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

import Action from './Action';
import Content from './Content';
import List from './List';
import Target from './Target';

class Dropdown extends Component {
  static Action = Action;

  static Content = Content;

  static Divider = DropdownBlock.Divider;

  static List = List;

  static ListItem = DropdownBlock.ListItem;

  static SectionHeader = DropdownBlock.SectionHeader;

  static SectionBody = DropdownBlock.SectionBody;

  static Target = Target;

  static modifiers = DropdownBlock.modifiers;

  static childContextTypes = {
    [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    activeItem: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),
    arrow: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    fullWidth: PropTypes.bool,
    hideOnChange: PropTypes.bool,
    onChange: PropTypes.func,
    onExpandedChange: PropTypes.func,
    position: PropTypes.oneOf(['bottom', 'bottomLeft', 'bottomRight']),
    readOnly: PropTypes.bool,
    removeOnHide: PropTypes.bool,
    selectable: PropTypes.bool,
    showContent: PropTypes.bool,
    showOnHover: PropTypes.bool,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    activeItem: '',
    arrow: false,
    fullWidth: false,
    hideOnChange: false,
    onChange: noop,
    onExpandedChange: noop,
    position: 'bottom',
    readOnly: false,
    removeOnHide: true,
    selectable: true,
    showContent: false,
    showOnHover: false,
    zIndex: 1,
  };

  state = {
    activeItem: this.props.activeItem,
    showContent: this.props.showContent,
  };

  getChildContext() {
    return {
      [DROPDOWN_CONTEXT]: {
        activeItem: this.state.activeItem,
        arrow: this.props.arrow,
        fullWidth: this.props.fullWidth,
        handleHover: this.handleHover,
        handleItemClick: this.handleItemClick,
        handleToggle: this.toggleContent,
        hideContent: this.hideContent,
        position: this.props.position,
        readOnly: this.props.readOnly,
        removeOnHide: this.props.removeOnHide,
        selectable: this.props.selectable,
        showContent: this.state.showContent,
        zIndex: this.props.zIndex,
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('focusout', this.handleFocusOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeItem !== nextProps.activeItem) {
      this.setState({ activeItem: nextProps.activeItem });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('focusout', this.handleFocusOutside);
  }

  setRef = (node) => {
    this.ref = node;
    return this.ref;
  };

  handleClickOutside = (e) => {
    if (this.state.showContent && !this.ref?.contains(e.target)) {
      this.toggleContent({ show: false });
    }
  };

  // Helps with navigating dropdown options using the keyboard
  handleFocusOutside = (e) => {
    if (this.state.showContent && !this.ref?.contains(e.target)) {
      this.toggleContent({ show: false });
    }
  };

  handleHover = () => {
    if (this.props.showOnHover) {
      this.toggleContent({ show: true });
    }
  };

  toggleContent = ({ show } = {}) => {
    if (this.props.readOnly) {
      return undefined;
    }

    // this allows us to handle all show / hide logic in a single location
    // you can explicitly tell the content to show / hide, or let it toggle.
    if (show !== undefined) {
      this.props.onExpandedChange(show);
      return this.setState({ showContent: show });
    }

    this.props.onExpandedChange(!this.state.showContent);
    return this.setState({ showContent: !this.state.showContent });
  };

  showContent = () => this.toggleContent({ show: true });

  hideContent = () => this.toggleContent({ show: false });

  handleItemClick = (e, itemId) => {
    this.props.onChange(e, itemId);
    if (this.props.hideOnChange) {
      this.toggleContent({ show: false });
      return this.setState({ activeItem: itemId });
    }
    return this.setState({ activeItem: itemId });
  };

  render() {
    const { children, position, onExpandedChange, ...rest } = this.props;

    return (
      <DropdownBlock ref={this.setRef} modifiers={[position]} {...rest}>
        {isFunction(children)
          ? children({
              show: this.showContent,
              hide: this.hideContent,
              toggle: this.toggleContent,
              isVisible: this.state.showContent,
            })
          : children}
      </DropdownBlock>
    );
  }
}

export default Dropdown;
