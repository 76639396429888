import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useMutation, gql } from '@apollo/client';

import { P, H4, Text, Tooltip, Popover, ButtonMicro } from 'base-components';

import Ul from 'elements/Ul';
import Tag from 'blocks/Tag';

import { NAME as callHistoryQuery } from 'compositions/CaseActivityPanel/CallHistory/useCallHistory/caseCallHistoryQuery';
import { NAME as unifiedHistoryQuery } from 'compositions/CaseActivityPanel/UnifiedHistory/withCaseUnifiedHistory/caseUnifiedHistoryQuery';

import useAutomatedCallState from './useAutomatedCallState';
import { QUERY_NAME as callbacksStateQuery } from './useAutomatedCallState';

const refetchQueries = [
  callHistoryQuery,
  callbacksStateQuery,
  unifiedHistoryQuery,
];

const mutation = gql`
  mutation MarkCallAsDone($contactId: ID!, $type: AutomatedCallType!) {
    completeAutomatedCall(input: { caseContactId: $contactId, type: $type }) {
      id
      type
      status
      successTime
      retryTimestamps

      user {
        email
        firstName
        lastName
      }
    }
  }
`;

function dateValues(timestamp) {
  if (!timestamp) return {};

  const m = moment(timestamp);

  return { date: m.format('MMM Do'), time: m.format('h:mm A') };
}

function getUserString({ firstName, lastName, email }) {
  const name = [firstName, lastName].filter(Boolean).join(' ');

  return firstName ? `${name} (${email})` : email;
}

function CallbackState({ type, contactId }) {
  const { state, loading: isLoading } = useAutomatedCallState(contactId, type);
  const { status, user, successTime, retryTimestamps = [] } = state || {};

  const hasCall = !!status;
  const isPending = ['pending', 'ongoing'].includes(status);
  const isSuccess = status === 'success';
  const isFailure = status === 'fail';
  const tagStatus = isPending ? '' : isSuccess ? 'success' : 'error';
  const isManualCall = !!user;

  const [mutate, { loading: isMutating }] = useMutation(mutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const markAsComplete = useCallback(
    () => mutate({ variables: { type, contactId } }),
    [type, contactId, mutate],
  );

  if (isLoading) return null;

  return (
    <>
      &nbsp; &nbsp;
      {hasCall && (
        <Popover position="right" showOnHover>
          <Popover.Target>
            <Tag style={{ marginRight: 10 }} modifiers={tagStatus}>
              <Tag.Text>
                {isPending && <Trans>Pending</Trans>}
                {isSuccess && <Trans>Complete</Trans>}
                {isFailure && <Trans>Failed</Trans>}
              </Tag.Text>
            </Tag>
          </Popover.Target>
          <Popover.Content
            modifiers="noWrap"
            zIndex={1}
            style={{ padding: 10 }}
          >
            {isPending && (
              <P>
                <Trans>Contact will be notified via automated call</Trans>
              </P>
            )}
            {isSuccess && (
              <P>
                {isManualCall && (
                  <Trans
                    id="Contact notified by {user} on {date}, {time}"
                    values={{
                      user: getUserString(user),
                      ...dateValues(successTime),
                    }}
                  />
                )}
                {!isManualCall && (
                  <Trans
                    id="Contact notified via automated call on {date}, {time}"
                    values={dateValues(successTime)}
                  />
                )}
              </P>
            )}
            {isFailure && (
              <>
                <H4 modifiers="fontWeightMedium">
                  <Trans>Failed to notify the contact via automated call</Trans>
                </H4>
                {retryTimestamps.length > 0 && (
                  <>
                    <P style={{ marginTop: 10 }}>
                      <Trans>Contact attempts:</Trans>
                    </P>
                    <Ul>
                      {retryTimestamps.map((timestamp) => (
                        <li key={timestamp}>
                          &middot;&nbsp;
                          <Text>
                            <Trans
                              id="{date}, {time}"
                              values={dateValues(timestamp)}
                            />
                          </Text>
                        </li>
                      ))}
                    </Ul>
                  </>
                )}
              </>
            )}
          </Popover.Content>
        </Popover>
      )}
      {!isSuccess && (
        <Tooltip position="right">
          <Tooltip.Target>
            <ButtonMicro
              onClick={markAsComplete}
              disabled={isMutating}
              modifiers={['mini', isMutating ? 'disabled' : '']}
              type="button"
            >
              <ButtonMicro.Icon name="user-check" style={{ paddingRight: 0 }} />
            </ButtonMicro>
          </Tooltip.Target>
          <Tooltip.Content>
            <Trans>Mark as manually notified</Trans>
          </Tooltip.Content>
        </Tooltip>
      )}
    </>
  );
}

CallbackState.propTypes = {
  type: PropTypes.oneOf(['ETA', 'ROLL_TIME']).isRequired,
  contactId: PropTypes.string.isRequired,
};

export default CallbackState;
