import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, noop } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { InputField, InputGroup, Checkbox } from 'base-components';

import withContext from 'utils/withContext';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import patchFromChangedProps from 'utils/patchFromChangedProps';
import withPatternValidationOnKeyUp from 'utils/withPatternValidationOnKeyUp';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import { CaseStatusContext } from 'compositions/CaseStatus';
import { invalidCharsForExporting } from 'constants/index';

import ErrorMessage from './ErrorMessage';
import UnitTypeDropdown from './UnitTypeDropdown';
import CaseRequestsPanelContext from '../../CaseRequestsPanelContext';
import { FIELD_NAMES } from '../constants';

// Set the row's minHeight to prevent it pushing content down
// when the Trash icons are visible, as they are taller than
// the checkboxes.
const bottomRowStyles = {
  minHeight: px2rem(35),
  paddingBottom: 0,
};

export class RequestAsset extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    caseNumber: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setUnitNumberInvalidStatus: PropTypes.func,
    setAssetTypeInvalidStatus: PropTypes.func,
    name: PropTypes.string.isRequired,
    // for this component we really don't care the shape of `values`
    // so long is it contains something at a key corresponding to our `name`
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.shape({}).isRequired,
    isFeatureEnabled: PropTypes.func.isRequired,
    // from withPatternValidationOnKeyUp
    hasInvalidPattern: PropTypes.func,
    onValidatingFieldBlur: PropTypes.func,
    onValidatingFieldKeyUp: PropTypes.func,
    onUnitNumberFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    setUnitNumberInvalidStatus: noop,
    setAssetTypeInvalidStatus: noop,
    hasInvalidPattern: noop,
    onValidatingFieldBlur: noop,
    onValidatingFieldKeyUp: noop,
  };

  static getDerivedStateFromProps(props, state) {
    const { hasInvalidPattern } = props;

    // If the unit number input field has an error, we don't
    // want to loose it, so we skip the update.
    if (hasInvalidPattern('unitNumber', state.unitNumber)) {
      return null;
    }

    const patch = patchFromChangedProps(props, state, 'values');
    const value = get(patch, `values.${props.name}.unitNumber`) || '';

    return value !== state.unitNumber ? { unitNumber: value } : null;
  }

  constructor(props) {
    super(props);

    // get the data for this asset:
    const value = get(props.values, props.name, {});

    this.state = {
      // Saving props.name on state so it doesn't change when
      // another related asset is removed (because it's based on index)
      name: props.name,
      unitNumber: value.unitNumber || '',
      errors: {
        [FIELD_NAMES.UNIT_NUMBER]: false,
        [FIELD_NAMES.ASSET_TYPE]: false,
      },
    };
  }

  validateUnitNumber = (isValid) => {
    const { name } = this.state;
    const { setUnitNumberInvalidStatus } = this.props;

    this.setState(
      { errors: { ...this.state.errors, [FIELD_NAMES.UNIT_NUMBER]: !isValid } },
      () => setTimeout(() => setUnitNumberInvalidStatus(name, !isValid), 30),
    );
  };

  validateUnitType = (isValid) => {
    const { name } = this.state;
    const { setAssetTypeInvalidStatus } = this.props;

    this.setState(
      { errors: { ...this.state.errors, [FIELD_NAMES.ASSET_TYPE]: !isValid } },
      () => setTimeout(() => setAssetTypeInvalidStatus(name, !isValid), 30),
    );
  };

  handleNumberChange = (event) => {
    const { target } = event;
    const { hasInvalidPattern } = this.props;

    this.setState({ unitNumber: target.value });

    if (!hasInvalidPattern('unitNumber', target.value)) {
      this.props.handleChange(event);
      this.validateUnitNumber(true);
    }
  };

  handleNumberBlur = (event) => {
    const { unitNumber } = this.state;
    const { onValidatingFieldBlur, hasInvalidPattern } = this.props;

    onValidatingFieldBlur('unitNumber');

    this.validateUnitNumber(!hasInvalidPattern('unitNumber', unitNumber));

    this.props.handleBlur(event);
  };

  handleNumberKeyUp = (event) => {
    const { onValidatingFieldKeyUp, hasInvalidPattern } = this.props;

    const { showValidationError } = onValidatingFieldKeyUp('unitNumber', event);

    const isValid = !(
      showValidationError &&
      hasInvalidPattern('unitNumber', get(event, 'target.value'))
    );
    this.validateUnitNumber(isValid);
  };

  render() {
    const { name, unitNumber, errors } = this.state;
    const { setFieldValue, caseNumber } = this.props;
    const { handleBlur, handleChange, values } = this.props;
    const { index, onUnitNumberFocusRequested, isFeatureEnabled } = this.props;

    const { readOnlyStatus } = get(values, name, {}); // eslint-disable-line no-unused-vars

    const canManageAssets = isFeatureEnabled('manageAssets');

    // TODO: Uncomment when we want to enable readonly after dispatch
    // const canEdit = canManageAssets && readOnlyStatus === 'WRITABLE';
    // const canEditUnit =
    //   canManageAssets && ['WRITABLE', 'MISMATCH'].includes(readOnlyStatus);

    const canEdit = canManageAssets;
    const canEditUnit = canManageAssets;

    // setup names for the sub-fields of the asset:
    const unitNumberFieldName = `${name}.${FIELD_NAMES.UNIT_NUMBER}`;
    const unitTypeFieldName = `${name}.${FIELD_NAMES.ASSET_TYPE}`;
    const unitDroppedFieldName = `${name}.${FIELD_NAMES.DROPPED_UNIT}`;

    return (
      <InputGroup>
        <InputGroup.Row>
          {/* unit number input field */}
          <InputGroup.Column modifiers={['flex_1']}>
            <InputField
              maxLength={50}
              name={unitNumberFieldName}
              onChange={this.handleNumberChange}
              onBlur={this.handleNumberBlur}
              onKeyUp={this.handleNumberKeyUp}
              value={unitNumber}
              placeholder={t`Enter value...`}
              readOnly={!canEditUnit}
              isValid={!errors[FIELD_NAMES.UNIT_NUMBER]}
            >
              <Column modifiers="col">
                <Row>
                  <InputField.Label>
                    <Trans>Unit Number</Trans>
                  </InputField.Label>
                </Row>
                <Row>
                  <CaseShortcut
                    action={{
                      parent: CASE_SHORTCUT_PANELS.request,
                      id: `${unitNumberFieldName}RequestAction`,
                      name: t`Asset ${index + 1}`,
                      shortcut: ['r', `${index + 1}`],
                      keywords: 'unit number',
                      priority: 2,
                      deps: canEditUnit,
                    }}
                  >
                    {({ onFocusRequested: onShortcutFocusRequested }) => (
                      <InputField.TextField
                        readOnly={!canEditUnit}
                        ref={(ref) => {
                          onUnitNumberFocusRequested(ref);
                          onShortcutFocusRequested(ref);
                        }}
                      />
                    )}
                  </CaseShortcut>

                  {unitNumber && (
                    <InputField.ActionButton
                      icon="times"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleNumberChange({
                          target: { name: unitNumberFieldName, value: '' },
                        });
                      }}
                      modifiers={['padScaleX_0', 'hoverDanger']}
                    />
                  )}
                </Row>
              </Column>
            </InputField>
          </InputGroup.Column>

          {/* unit type selection */}
          <InputGroup.Column modifiers={['flex_1']}>
            <UnitTypeDropdown
              name={unitTypeFieldName}
              values={values}
              readOnly={!canEdit}
              caseNumber={caseNumber}
              onValidate={this.validateUnitType}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              focusReceiverId={`request.assets.${index}.assetType`}
            />
          </InputGroup.Column>
        </InputGroup.Row>

        <Row modifiers={['top', 'padScaleY_2']} style={bottomRowStyles}>
          {/* checkbox for "unit dropped */}
          <Column modifiers={['col', 'padScale_0']}>
            <Checkbox
              id={unitDroppedFieldName}
              name={unitDroppedFieldName}
              label={t`Dropped Asset`}
              onBlur={handleBlur}
              checked={!!get(values, unitDroppedFieldName)}
              onChange={handleChange}
              readOnly={!canEdit}
            />
          </Column>
        </Row>

        <ErrorMessage errors={errors} />
      </InputGroup>
    );
  }
}

const patternConfig = { fields: { unitNumber: invalidCharsForExporting } };

export default compose(
  setDisplayName('RequestAsset'),
  withContext(CaseRequestsPanelContext),
  withContext(CaseStatusContext),
  withPatternValidationOnKeyUp(patternConfig),
  withFocusReceiver(
    ({ index }) => `request.assets.${index}.unitNumber`,
    'onUnitNumberFocusRequested',
  ),
)(RequestAsset);
