import gql from 'graphql-tag';

import { RequestLineFieldsFragment } from '../constants';

export default gql`
  mutation updateAsset($id: ID!, $asset: AssetParams!) {
    updateAsset(input: { id: $id, patch: $asset }) {
      asset {
        id
        unitNumber
        droppedUnit
        assetType
        trailerType
        canDelete
      }

      updatedRequestLines {
        ...RequestLineFields
      }
      updatedRequestAgreementLines {
        ...RequestLineFields
      }
      updatedSuppliedLines {
        ...RequestLineFields
      }
    }
  }
  ${RequestLineFieldsFragment}
`;
