import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { t, Trans, Plural } from '@lingui/macro';
import { curry, debounce, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid/reactive';
import { ButtonIconRectangle, InputField, Text } from 'base-components';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

import updateAssetLocationNoteGQL from './updateAssetLocationNoteMutation';

export const MAX_DESCRIPTION_LENGTH = 300;

export class AssetLocationNotesInput extends Component {
  static propTypes = {
    caseDetail: PropTypes.shape({
      id: PropTypes.string,
      assetLocation: PropTypes.shape({
        note: PropTypes.string,
      }),
    }).isRequired,
    savingAssetLocation: PropTypes.bool.isRequired,
    updateCase: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    hasInvalidPattern: PropTypes.func,
    validationError: PropTypes.node,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    onKeyUp: () => {},
    onBlur: () => {},
    hasInvalidPattern: () => {},
    validationError: null,
    readOnly: false,
  };

  state = {
    isCreatingNote: !!get(this.props, 'caseDetail.assetLocation.note'),
    assetLocationNote: get(this.props, 'caseDetail.assetLocation.note', ''),
    containsCreditCardNumber: false,
    ignoreCreditCardNumber: false,
  };

  // eslint-disable-next-line react/sort-comp
  handleDelete = () => {
    const {
      caseDetail: { id },
      updateCase,
      onBlur,
    } = this.props;

    this.setState({
      isCreatingNote: false,
      assetLocationNote: '',
    });
    onBlur('');
    updateCase({ variables: { assetLocationNote: '', id } });
  };

  handleSubmit = (value) => {
    const {
      caseDetail: { id },
      updateCase,
      hasInvalidPattern,
    } = this.props;

    if (this.props.savingAssetLocation || hasInvalidPattern(value)) {
      return false;
    }

    return updateCase({
      variables: { assetLocationNote: value, id },
    });
  };

  submitForm = debounce(this.handleSubmit, 250);

  handleTextChange = ({ target: { value } }) => {
    this.setState({ assetLocationNote: value });
    this.submitForm(value);

    if (!this.state.ignoreCreditCardNumber && containsCreditCardNumber(value)) {
      this.setState({ containsCreditCardNumber: true });
    }
  };

  handleKeyUp = (event) => {
    this.props.onKeyUp(event);
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    onBlur(this.state.assetLocationNote);
  };

  renderInput = () => {
    const { assetLocationNote } = this.state;
    const { validationError, readOnly } = this.props;

    return (
      <Column modifiers={['col']}>
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <InputField
              onChange={this.handleTextChange}
              onBlur={this.handleBlur}
              placeholder={t`Enter note...`}
              value={assetLocationNote || ''}
              modifiers={['height_auto']}
              readOnly={readOnly}
              maxLength={MAX_DESCRIPTION_LENGTH}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.Label>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Note (Optional)</Trans>
                    </Text>
                  </InputField.Label>
                  <InputField.CharsLeftLabel>
                    {(charsLeft) => (
                      <Plural
                        value={charsLeft}
                        one="# Character Left"
                        other="# Characters Left"
                      />
                    )}
                  </InputField.CharsLeftLabel>
                </Row>
                <Row>
                  <CaseShortcut
                    action={{
                      parent: CASE_SHORTCUT_PANELS.assetLocation,
                      id: 'assetLocationNotesAction',
                      name: t`Notes`,
                      shortcut: ['a', 'l', '1'],
                      priority: 3,
                    }}
                  >
                    {({ onFocusRequested }) => (
                      <InputField.TextArea
                        onKeyUp={this.handleKeyUp}
                        autoFocus={!assetLocationNote}
                        ref={onFocusRequested}
                      />
                    )}
                  </CaseShortcut>
                </Row>
              </Column>
            </InputField>
          </Column>
          {!readOnly && (
            <Column modifiers={['padScaleX_3']}>
              <ButtonIconRectangle
                modifiers={['hoverDanger']}
                onClick={this.handleDelete}
              >
                <ButtonIconRectangle.Icon name="trash" />
              </ButtonIconRectangle>
            </Column>
          )}
        </Row>

        {validationError}

        {this.state.containsCreditCardNumber && (
          <CreditCardComplianceModal
            onDeleteCreditCardNumber={() => {
              this.handleTextChange({
                target: { value: excludeCreditCardNumber(assetLocationNote) },
              });
              this.setState({ containsCreditCardNumber: false });
            }}
            onContinue={() =>
              this.setState({
                containsCreditCardNumber: false,
                ignoreCreditCardNumber: true,
              })
            }
          />
        )}
      </Column>
    );
  };

  renderButton = () => {
    if (this.props.readOnly) return null;

    const addNote = () => this.setState({ isCreatingNote: true });

    return (
      <CaseShortcut
        action={{
          parent: CASE_SHORTCUT_PANELS.assetLocation,
          id: 'assetLocationNotesAction',
          name: t`Notes`,
          shortcut: ['a', 'l', '1'],
          priority: 3,
          perform: addNote,
        }}
      >
        {({ onFocusRequested }) => (
          <Row ref={onFocusRequested}>
            <Column>
              <ButtonLinkWithIcon icon="plus" onClick={addNote}>
                <Trans>Add Note</Trans>
              </ButtonLinkWithIcon>
            </Column>
          </Row>
        )}
      </CaseShortcut>
    );
  };

  render() {
    const { isCreatingNote } = this.state;

    return isCreatingNote ? this.renderInput() : this.renderButton();
  }
}

export const buildMutationChild = curry(
  (componentProps, updateCase, mutationProps) => {
    const { loading: savingAssetLocation } = mutationProps;
    return (
      <AssetLocationNotesInput
        {...componentProps}
        updateCase={updateCase}
        savingAssetLocation={savingAssetLocation}
      />
    );
  },
);

export function AssetLocationNoteUpdateMutation(componentProps) {
  const mutationChild = buildMutationChild(componentProps);

  return (
    <Mutation mutation={updateAssetLocationNoteGQL}>{mutationChild}</Mutation>
  );
}

export default compose(setDisplayName('DealerNotesInput'))(
  AssetLocationNoteUpdateMutation,
);
