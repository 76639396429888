import PropTypes from 'prop-types';
import React from 'react';

import { modifiersForComponent } from '../../utils/modifiers';

import Button from './Button';
import Li from './Li';

function SelectableLi({ modifiers, children, ...rest }) {
  return (
    <Li modifiers={modifiersForComponent(modifiers, Li)}>
      <Button modifiers={modifiersForComponent(modifiers, Button)} {...rest}>
        {children}
      </Button>
    </Li>
  );
}

SelectableLi.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

SelectableLi.defaultProps = {
  modifiers: [],
};

export default SelectableLi;
