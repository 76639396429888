import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { groupBy, isEmpty, orderBy, get } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import {
  Dropdown,
  InputField,
  InputGroup,
  MessageMedium,
  MessageSmall,
  Popover,
  Text,
} from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import { withReadOnlyCase } from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withFilteredInboundPrograms from '../withFilteredInboundPrograms';
import withInboundProgramFromCall from '../withInboundProgramFromCall';
import withInboundProgramSelect from '../withInboundProgramSelect';
import withInboundProgramLocationSelect from '../withInboundProgramLocationSelect';
import InboundProgramCustomerDetails from './InboundProgramCustomerDetails';
import { fieldIds } from '../constants';

/**
 * This is the ID of the Michelin ONCall inbound program. Whenever this
 * item is included in the (potentially-filtered) list of inbound programs,
 * it should be the first item in the list. See MICONCALL-233.
 * @type {string}
 */
const MICHELIN_ONCALL_PROGRAM_ID = '206';

const getCustomerNameFirstChar = (program) => get(program, 'customerName.0');

export function sortAndGroupPrograms(inboundPrograms) {
  const sortedPrograms = orderBy(inboundPrograms, ['customerName'], 'asc');
  const mocIndex = sortedPrograms.findIndex(
    ({ id }) => id === MICHELIN_ONCALL_PROGRAM_ID,
  );
  const programs =
    mocIndex >= 0
      ? [
          ...sortedPrograms.slice(0, mocIndex),
          ...sortedPrograms.slice(mocIndex + 1),
        ]
      : sortedPrograms;
  const groupedPrograms = groupBy(programs, getCustomerNameFirstChar);
  const groupArray = Object.entries(groupedPrograms).map(
    ([name, customers]) => ({
      name,
      customers,
    }),
  );

  if (mocIndex >= 0) {
    groupArray.unshift({
      name: '',
      customers: [sortedPrograms[mocIndex]],
    });
  }

  return groupArray;
}

export class InboundProgramSelector extends PureComponent {
  static propTypes = {
    inboundPrograms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        customerName: PropTypes.string.isRequired,
        tollFreeNumber: PropTypes.string,
      }),
    ).isRequired,
    inboundProgramSearchValue: PropTypes.string,
    isReadOnlyCase: PropTypes.bool.isRequired,
    onChangeInboundProgramSearchValue: PropTypes.func.isRequired,
    onSelectInboundProgram: PropTypes.func.isRequired,
    clearSelectedInboundProgram: PropTypes.func.isRequired,
    selectedInboundProgram: PropTypes.shape({
      id: PropTypes.string,
      customerName: PropTypes.string,
    }),
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    inboundProgramSearchValue: '',
    selectedInboundProgram: null,
  };

  state = { isInvalid: false };

  componentWillUnmount() {
    clearTimeout(this.resetDisplayValueTimeout);
  }

  handleDropdownSelect = (_, inboundProgram) => {
    if (!inboundProgram) return;

    clearTimeout(this.resetDisplayValueTimeout);

    this.setState({ isInvalid: false });
    this.props.onSelectInboundProgram(inboundProgram);
  };

  handleSearchValueChange = ({ target: { value } }) =>
    this.props.onChangeInboundProgramSearchValue(value);

  handleResetButtonClick = () => {
    this.setState({ isInvalid: false });
    // this will also clear the selected store location, if there is one:
    this.props.clearSelectedInboundProgram();
  };

  validate = () => {
    const { selectedInboundProgram } = this.props;
    const { inboundProgramSearchValue: searchValue } = this.props;

    const isInvalid = searchValue && !selectedInboundProgram;

    if (isInvalid !== this.state.isInvalid) this.setState({ isInvalid });

    this.resetDisplayValueTimeout = setTimeout(
      this.autoResetDisplayValueTimeout,
      100,
    );
  };

  autoResetDisplayValueTimeout = () => {
    const { selectedInboundProgram } = this.props;
    const { onChangeInboundProgramSearchValue } = this.props;
    const { inboundProgramSearchValue: searchValue } = this.props;

    const { customerName } = selectedInboundProgram || {};

    if (!!selectedInboundProgram && searchValue !== customerName) {
      !searchValue
        ? this.handleResetButtonClick()
        : onChangeInboundProgramSearchValue(customerName);
    }
  };

  renderErrorMessage = () => (
    <Container
      style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
      className="no-results"
    >
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );

  renderPrograms = () => {
    const { inboundProgramSearchValue } = this.props;
    const groupedPrograms = sortAndGroupPrograms(this.props.inboundPrograms);

    if (isEmpty(groupedPrograms)) {
      return this.renderErrorMessage();
    }

    return groupedPrograms.map((group) => (
      <Fragment key={group.name}>
        <Dropdown.SectionHeader>
          <Text modifiers={['small', 'noWrap', 'capitalize', 'textLight']}>
            {group.name}
          </Text>
        </Dropdown.SectionHeader>
        <Dropdown.List>
          {group.customers.map((customer) => (
            <Dropdown.ListItem key={customer.id} id={customer.id} type="submit">
              <InboundProgramCustomerDetails
                customer={customer}
                highlightText={inboundProgramSearchValue}
              />
            </Dropdown.ListItem>
          ))}
        </Dropdown.List>
      </Fragment>
    ));
  };

  renderPopoverContent = (show, toggle, isVisible) => {
    const { inboundProgramSearchValue, isReadOnlyCase } = this.props;

    return (
      <InputField
        isValid={!this.state.isInvalid}
        onBlur={this.validate}
        onFocus={show}
        onChange={this.handleSearchValueChange}
        placeholder={t`Select Inbound Program...`}
        readOnly={isReadOnlyCase}
        value={inboundProgramSearchValue}
      >
        {this.renderAvatar()}
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <InputField.Label>
              <Trans>Program</Trans>
            </InputField.Label>
          </Row>
          <Row>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.inboundProgram,
                id: 'goToInboundProgram',
                name: t`Go to Inbound Program`,
                shortcut: ['i', 'p', '0'],
                icon: 'arrow-right',
              }}
            >
              {({ onFocusRequested: onShortcutFocusRequested }) => (
                <InputField.TextField
                  ref={(ref) => {
                    this.props.onFocusRequested(ref);
                    onShortcutFocusRequested(ref);
                  }}
                />
              )}
            </CaseShortcut>
            {inboundProgramSearchValue && (
              <InputField.ActionButton
                icon="times"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleResetButtonClick();
                }}
                modifiers={['padScaleX_0', 'hoverDanger']}
              />
            )}

            <InputField.ActionButton
              icon={isVisible ? 'chevron-up' : 'chevron-down'}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                toggle();
              }}
              modifiers={['padScaleX_1', 'hoverInfo']}
            />
          </Row>
        </Column>
      </InputField>
    );
  };

  renderAvatar = () => {
    const { selectedInboundProgram } = this.props;

    if (selectedInboundProgram) {
      return (
        <InputField.Avatar
          name={selectedInboundProgram.customerName}
          modifiers={['primary', 'small']}
          isCompany
        />
      );
    }
    return <InputField.Avatar modifiers={['secondary', 'small']} isCompany />;
  };

  render() {
    const { isReadOnlyCase, selectedInboundProgram } = this.props;
    const hasPopover = !!selectedInboundProgram && isReadOnlyCase;

    return (
      <InputGroup.Row>
        <InputGroup.Column modifiers={['col']}>
          <Dropdown
            fullWidth
            hideOnChange
            activeItem={get(selectedInboundProgram, 'id')}
            onChange={this.handleDropdownSelect}
            zIndex={2}
            readOnly={isReadOnlyCase}
          >
            {({ show, toggle, isVisible }) => (
              <>
                <Container modifiers="padScale_0" onClick={show}>
                  {hasPopover ? (
                    <Popover
                      arrow
                      showOnHover
                      position="right"
                      style={{ flex: 1 }}
                    >
                      <Popover.Target style={{ flex: 1 }}>
                        {this.renderPopoverContent(show, toggle, isVisible)}
                      </Popover.Target>
                      <Popover.Content
                        style={{
                          padding: `${px2rem(10)} ${px2rem(14)}`,
                          width: px2rem(200),
                          zIndex: 2,
                        }}
                      >
                        <InboundProgramCustomerDetails
                          customer={selectedInboundProgram}
                        />
                      </Popover.Content>
                    </Popover>
                  ) : (
                    this.renderPopoverContent(show, toggle, isVisible)
                  )}
                </Container>
                <Dropdown.Content>{this.renderPrograms()}</Dropdown.Content>
              </>
            )}
          </Dropdown>
          {this.state.isInvalid && (
            <Row>
              <Column modifiers={['col', 'padScaleY_3']}>
                <MessageSmall type="warning">
                  <Trans>Select an existing program.</Trans>
                </MessageSmall>
              </Column>
            </Row>
          )}
        </InputGroup.Column>
      </InputGroup.Row>
    );
  }
}

export default compose(
  setDisplayName('InboundProgramSelector'),
  withFilteredInboundPrograms,
  withInboundProgramSelect,
  withInboundProgramLocationSelect,
  /**
   * Order matters here. `withInboundProgramFromCall` requires props
   * supplied by the other inbound program HOCs.
   */
  withInboundProgramFromCall,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.program),
)(InboundProgramSelector);
