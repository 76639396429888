import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, Plural } from '@lingui/macro';

import { H2, P, QuickActionButton, InputField, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';

import RadioWrapper from 'elements/RadioWrapper';

export const reasons = [
  { key: 'CREATED_IN_ERROR', label: <Trans>Created in error</Trans> },
  { key: 'CUSTOMER_CANCELED', label: <Trans>Fleet Canceled</Trans> },
  { key: 'OTHER', label: <Trans>Other</Trans> },
];

const cancelReasonNoteRowStyles = { marginTop: px2rem(20) };
const noticeRowStyles = { marginBottom: px2rem(40) };

class CaseCancelModal extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onCancelCase: PropTypes.func.isRequired,
  };

  state = {
    cancelReason: undefined,
    cancelReasonNote: '',
  };

  get canCancel() {
    const { cancelReason, cancelReasonNote } = this.state;

    return cancelReason === 'OTHER' ? !!cancelReasonNote : !!cancelReason;
  }

  handleCancelReasonChange = ({ target: { value } }) =>
    this.setState({ cancelReason: value });

  handleCancelReasonNoteChange = ({ target: { value } }) =>
    this.setState({ cancelReasonNote: value });

  handleCancelCase = () => this.props.onCancelCase(this.state);

  render() {
    const { caseNumber, onCloseModal } = this.props;
    const { cancelReason, cancelReasonNote } = this.state;

    return (
      <Modal onClose={onCloseModal}>
        {() => (
          <Modal.Body>
            <Modal.Header modifiers="danger">
              <Modal.HeaderIcon name="briefcase-times" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Cancel Case?</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center" style={noticeRowStyles}>
                <Column>
                  <P>
                    <Trans>
                      You&apos;re about to cancel case{' '}
                      <Text modifiers="fontWeightMedium">{caseNumber}</Text>.
                      Select a reason, then click CANCEL CASE.
                    </Trans>
                  </P>
                </Column>
              </Row>
              {reasons.map(({ key, label }) => (
                <Row key={key}>
                  <Column modifiers={['col', 'padScale_0']}>
                    <RadioWrapper>
                      <RadioButton
                        id={`case_${caseNumber}_reason_${key}`}
                        name="cancelReason"
                        value={key}
                        label={label}
                        checked={key === cancelReason}
                        onChange={this.handleCancelReasonChange}
                      />
                    </RadioWrapper>
                  </Column>
                </Row>
              ))}
              {cancelReason === 'OTHER' && (
                <Row style={cancelReasonNoteRowStyles}>
                  <Column modifiers={['col', 'padScale_0']}>
                    <InputField
                      name="cancelReasonNote"
                      value={cancelReasonNote}
                      onChange={this.handleCancelReasonNoteChange}
                      modifiers={['height_auto']}
                      maxLength={1000}
                    >
                      <Column modifiers={['col', 'padScaleY_0']}>
                        <Row>
                          <InputField.Label>
                            <Text modifiers={['small', 'textLight']}>
                              <Trans>Reason</Trans>
                            </Text>
                          </InputField.Label>
                          <InputField.CharsLeftLabel>
                            {(charsLeft) => (
                              <Plural
                                value={charsLeft}
                                one="# Character Left"
                                other="# Characters Left"
                              />
                            )}
                          </InputField.CharsLeftLabel>
                        </Row>
                        <Row>
                          <InputField.TextArea />
                        </Row>
                      </Column>
                    </InputField>
                  </Column>
                </Row>
              )}
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                <Column>
                  <QuickActionButton onClick={onCloseModal} type="button">
                    <QuickActionButton.Text>
                      <Trans>Keep Case</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    type="submit"
                    onClick={this.handleCancelCase}
                    disabled={!this.canCancel}
                    modifiers={[
                      'hoverDanger',
                      'secondary',
                      !this.canCancel && 'disabled',
                    ]}
                  >
                    <QuickActionButton.Text>
                      <Trans>Cancel case</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default CaseCancelModal;
