import gql from 'graphql-tag';

export const NAME = 'cancelCaseMutation';

const query = gql`
  mutation cancelCaseMutation(
    $caseId: ID!
    $timestamp: Datetime
    $cancelReason: CaseCancelReason!
    $cancelReasonNote: String
  ) {
    updateCaseStatus(
      input: {
        id: $caseId
        patch: {
          status: CANCELED
          timestamp: $timestamp
          updateCasePatch: {
            cancelReason: $cancelReason
            cancelReasonNote: $cancelReasonNote
          }
        }
      }
    ) {
      case {
        id
        status
        cancelReason
        cancelReasonNote
        statusHistory {
          id
          changedAt
          createdAt
          newStatus
          oldStatus
          user {
            email
            name
          }
        }
      }
    }
  }
`;

export default query;
