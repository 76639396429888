import React from 'react';
import PropTypes from 'prop-types';
import { first } from 'lodash';
import { Helmet } from 'react-helmet';
import { compose, setDisplayName, withProps } from 'recompose';
import { i18n } from '@lingui/core';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Container, Row } from 'styled-components-grid';

import { H1 } from 'base-components';

import Page from 'blocks/Page';
import Panel from 'blocks/Panel';
import RadioButton from 'components/RadioButton';
import RadioWrapper from 'elements/RadioWrapper';
import withUserRole from 'features/rbac/withUserRole';
import CaseBillingReport from 'compositions/Reports/CaseBillingReport';
import DailyAgentMetrics from 'compositions/Reports/DailyAgentMetrics';
import DailyCasesMetrics from 'compositions/Reports/DailyCasesMetrics';
import DailyDealerReport from 'compositions/Reports/DailyDealerReport';
import CasesPerHourReport from 'compositions/Reports/CasesPerHourReport';
import AssetValidationReport from 'compositions/Reports/AssetValidationReport';
import EmailDistributionReport from 'compositions/Reports/EmailDistributionReport';
import DealerSelectionAuditReport from 'compositions/Reports/DealerSelectionAuditReport';
import { USER_ROLES } from 'features/rbac';

import { pageTitles, pageMeta } from '../constants';
import {
  reportTypes,
  reportTitles,
  dealerRoles,
  dealersAllowedReportTypes,
  nonDealersAllowedReportTypes,
  michelinOpsAllowedReportTypes,
} from './constants';

const reportTypesValues = Object.values(reportTypes);

const reportComponentByType = {
  [reportTypes.caseBillingReport]: CaseBillingReport,
  [reportTypes.casesPerHour]: CasesPerHourReport,
  [reportTypes.dailyAgentMetrics]: DailyAgentMetrics,
  [reportTypes.dailyCasesMetrics]: DailyCasesMetrics,
  [reportTypes.dailyDealerReport]: DailyDealerReport,
  [reportTypes.dailyDealerReportByEmail]: DailyDealerReport,
  [reportTypes.emailDistributionReport]: EmailDistributionReport,
  [reportTypes.dealerSelectionAuditReport]: DealerSelectionAuditReport,
  [reportTypes.assetValidationReport]: AssetValidationReport,
};

const ReportsPage = (props) => {
  const { reportType, navigateTo, isDealer, allowedReportTypes } = props;

  const handleReportTypeChange = ({ target: { value: type } }) =>
    navigateTo(`/reports/${type}`);

  const ReportComponent = reportComponentByType[reportType];

  return (
    <>
      <Helmet>
        <title>
          {i18n._(pageTitles.reports, {
            reportType: i18n._(reportTitles[reportType]),
          })}
        </title>
        <meta name="description" content={i18n._(pageMeta.reports)} />
      </Helmet>
      <Page modifiers={['bgChrome100', 'fluid']}>
        <Panel style={{ padding: `${px2rem(40)} ${px2rem(40)} ${px2rem(20)}` }}>
          <H1 modifiers="fontWeightLight">Reports</H1>
        </Panel>
        <Container style={{ padding: px2rem(20) }}>
          <Row>
            {!!allowedReportTypes.length && (
              <Column modifiers={['col_3', 'padScale_0']}>
                {allowedReportTypes.map((type) => (
                  <Row key={type}>
                    <Column modifiers={['col', 'padScale_0']}>
                      <RadioWrapper modifiers="bgWhite">
                        <RadioButton
                          id={type}
                          name="reportType"
                          value={type}
                          label={i18n._(reportTitles[type])}
                          checked={reportType === type}
                          onChange={handleReportTypeChange}
                        />
                      </RadioWrapper>
                    </Column>
                  </Row>
                ))}
              </Column>
            )}
            <Column
              style={{ marginLeft: isDealer ? 0 : px2rem(20) }}
              modifiers={['col', 'padScale_0']}
            >
              <ReportComponent isDealer={isDealer} />
            </Column>
          </Row>
        </Container>
      </Page>
    </>
  );
};

ReportsPage.propTypes = {
  isDealer: PropTypes.bool.isRequired,
  reportType: PropTypes.oneOf(reportTypesValues).isRequired,
  navigateTo: PropTypes.func.isRequired,
  allowedReportTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  setDisplayName('ReportsPage'),
  withUserRole,
  withProps(
    ({
      history: { replace },
      match: {
        params: { type },
      },
      role,
    }) => {
      const userRole = USER_ROLES[role];
      const isDealer = dealerRoles.includes(userRole);
      const isMichelinOps = userRole === USER_ROLES.MICHELIN_OPERATIONS;
      let allowedReportTypes = nonDealersAllowedReportTypes;

      if (isDealer) allowedReportTypes = dealersAllowedReportTypes;
      if (isMichelinOps) allowedReportTypes = michelinOpsAllowedReportTypes;

      const reportType = allowedReportTypes.includes(type)
        ? type
        : first(allowedReportTypes);

      return {
        isDealer,
        reportType,
        navigateTo: replace,
        allowedReportTypes,
      };
    },
  ),
)(ReportsPage);
