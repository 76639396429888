import gql from 'graphql-tag';

export default gql`
  mutation createRelatedAsset($caseId: ID!, $asset: AssetParams!) {
    createRelatedAsset(input: { caseId: $caseId, assetParams: $asset }) {
      asset {
        id
        unitNumber
        droppedUnit
        assetType
        trailerType
        canDelete
      }
    }
  }
`;
