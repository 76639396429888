import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_NOTES_QUERY } from '../withCaseNotes/caseNotesQuery';

import updateCaseNoteGQL from './updateCaseNoteMutation';

const buildUpdateMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseNote) => (
    <WrappedComponent {...componentProps} updateCaseNote={updateCaseNote} />
  ),
);

function withUpdateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={updateCaseNoteGQL} refetchQueries={[CASE_NOTES_QUERY]}>
      {buildUpdateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateCaseNote = (WrappedComponent) => (componentProps) =>
  withUpdateMutation(WrappedComponent, componentProps);

export default withUpdateCaseNote;
