import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { noop } from 'lodash';

import { ButtonIconRectangle, Icon, Tooltip } from 'base-components';

export default function ContactDeleteButton({ deletable, onDelete }) {
  return (
    <Tooltip position="top">
      <Tooltip.Target>
        <ButtonIconRectangle
          disabled={!deletable}
          modifiers={['hoverDanger', !deletable && 'disabled']}
          onClick={onDelete}
          type="button"
        >
          <Icon name="trash" />
        </ButtonIconRectangle>
      </Tooltip.Target>
      {deletable && (
        <Tooltip.Content>
          <Trans>Remove Contact</Trans>
        </Tooltip.Content>
      )}
    </Tooltip>
  );
}

ContactDeleteButton.propTypes = {
  deletable: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
};

ContactDeleteButton.defaultProps = {
  onDelete: noop,
};
