import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t, Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { Text, InputField } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import {
  CASE_PANELS,
  withCasePanelStatusActions,
} from 'compositions/CaseStatus';

import withCaseThirdPartyTransactionDetails from '../../withCaseThirdPartyTransactionDetails';
import { fieldIds } from '../../constants';

export class TransactionReceiptForm extends PureComponent {
  static propTypes = {
    isReadOnlyCase: PropTypes.bool.isRequired,
    setCasePanelComplete: PropTypes.func.isRequired,
    setCasePanelIncomplete: PropTypes.func.isRequired,
    thirdPartyTransactionDetails: PropTypes.string,
    setCaseThirdPartyTransactionDetails: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    thirdPartyTransactionDetails: '',
  };

  state = { value: this.props.thirdPartyTransactionDetails || '' };

  UNSAFE_componentWillReceiveProps({ thirdPartyTransactionDetails }) {
    if (
      thirdPartyTransactionDetails !== this.props.thirdPartyTransactionDetails
    ) {
      this.setState({ value: thirdPartyTransactionDetails || '' });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimeout);
  }

  save = () => {
    const {
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCaseThirdPartyTransactionDetails,
    } = this.props;

    setCaseThirdPartyTransactionDetails(this.state.value);

    const updatePanelStatus = this.state.value
      ? setCasePanelComplete
      : setCasePanelIncomplete;

    updatePanelStatus();
  };

  handleChange = ({ target: { value } }) => {
    clearTimeout(this.saveTimeout);

    this.setState({ value });

    this.saveTimeout = setTimeout(this.save, 1000);
  };

  render() {
    const { isReadOnlyCase } = this.props;
    const { value } = this.state;

    return (
      <>
        <Row>
          <Column modifiers={['col', 'padScaleY_2']}>
            <Text>
              <Trans>Copy and paste the transaction details below.</Trans>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers="col">
            <InputField
              name="transaction-receipt"
              value={value}
              onChange={this.handleChange}
              modifiers={['height_auto']}
              placeholder={t`Enter transaction receipt...`}
              readOnly={isReadOnlyCase}
              maxLength={1000}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.Label>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Transaction Receipt</Trans>
                    </Text>
                  </InputField.Label>
                  <InputField.CharsLeftLabel>
                    {(charsLeft) => (
                      <Plural
                        value={charsLeft}
                        one="# Character Left"
                        other="# Characters Left"
                      />
                    )}
                  </InputField.CharsLeftLabel>
                </Row>
                <Row>
                  <InputField.TextArea ref={this.props.onFocusRequested} />
                </Row>
              </Column>
            </InputField>
          </Column>
        </Row>
      </>
    );
  }
}

export default compose(
  setDisplayName('TransactionReceiptForm'),
  withCaseThirdPartyTransactionDetails,
  withCasePanelStatusActions(CASE_PANELS.payment),
  withFocusReceiver(fieldIds.other.receipt),
)(TransactionReceiptForm);
