import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_NOTES_QUERY } from '../withCaseNotes/caseNotesQuery';

import removeCaseNoteGQL from './removeCaseNoteMutation';

const buildRemoveMutationChild = curry(
  (WrappedComponent, componentProps, removeCaseNote) => (
    <WrappedComponent {...componentProps} removeCaseNote={removeCaseNote} />
  ),
);

function withRemoveMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={removeCaseNoteGQL} refetchQueries={[CASE_NOTES_QUERY]}>
      {buildRemoveMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withRemoveCaseNote = (WrappedComponent) => (componentProps) =>
  withRemoveMutation(WrappedComponent, componentProps);

export default withRemoveCaseNote;
