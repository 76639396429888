import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { parsePhoneNumber } from 'libphonenumber-js';
import { get } from 'lodash';

import { AmazonConnectProvider } from 'features/amazonConnect';

import withContext from 'utils/withContext';
import { CaseStatusContext } from 'compositions/CaseStatus';

import withCallHistoryActions from './withCallHistoryActions';
import { caseCallLinkHelper } from './helpers';

/**
 * Function-as-a-child patterned component which provides two
 * values as arguments to its children:
 *
 * * callAndLinkToCase - a function which places an outbound call
 *    via Amazon Connect, and when that call starts connecting,
 *    adds the call to the case call history.
 * * contactNumber - a phone number for any currently-in-progress
 *    phone call ("contact")
 */
export class CallAndLinkToCase extends Component {
  static propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    dealer: PropTypes.shape({
      name: PropTypes.string,
    }),
    dealerContact: PropTypes.shape({
      name: PropTypes.string,
    }),
    name: PropTypes.string, // for calls to non-dealers
    children: PropTypes.func.isRequired,

    // from CaseStatusContext:
    caseId: PropTypes.string,

    // from withCallHistoryActions:
    linkCaseToCall: PropTypes.func.isRequired,

    // from AmazonConnect:
    contact: PropTypes.shape({
      contactId: PropTypes.string.isRequired,
      getAttributes: PropTypes.func.isRequired,
      isInbound: PropTypes.func.isRequired,
    }),
    contactNumber: PropTypes.string,
    stateStartTime: PropTypes.instanceOf(Date),
    addContactConnectingListener: PropTypes.func.isRequired,
    makeOutboundCall: PropTypes.func,
    outgoingCallOverride: PropTypes.string,
  };

  static defaultProps = {
    dealer: null,
    dealerContact: null,
    name: null,

    caseId: null,

    contact: null,
    contactNumber: null,
    stateStartTime: null,
    makeOutboundCall: null,
    outgoingCallOverride: null,
  };

  /**
   * Event handler called by AmazonConnect when a call connects. If the call
   * is _our_ call, then we link it to the current case.
   */
  onContactConnecting = (data, next, meta) => {
    const {
      phoneNumber,
      dealer,
      dealerContact,
      name,
      caseId,
      linkCaseToCall,
      // these next three could probably also be pulled from `data`:
      contact,
      contactNumber,
      stateStartTime,
    } = this.props;

    if (this.removeListener) {
      this.removeListener();
      this.removeListener = null;
    }

    if (!contact || !contactNumber || !stateStartTime) {
      // eslint-disable-next-line no-console
      console.warn(
        'CallAndLinkToCase#onContactConnecting missing some props:\nprops:',
        this.props,
        '\n                 ===> called with data:',
        data,
      );
    }

    const callContact = contact || get(data, 'contact');

    if (!callContact) {
      // eslint-disable-next-line no-console
      console.trace('CallAndLinkToCase - no `contact` available. Returning.');
      return;
    }

    if (callContact.isInbound()) {
      // We received an incoming call before our outbound call connected?
      // eslint-disable-next-line no-console
      console.warn('CallAndLinkToCase received an inbound contact');
      return;
    }

    if (contactNumber !== phoneNumber) {
      // Another outbound call was placed instead of ours?
      // Since we actually DO expect this in QA mode (using the
      // `outboundphone` query string param), but if the unexpected
      // number is NOT the override number, then something weird is
      // going on and we shouldn't log this call to the case.
      const { outgoingCallOverride } = this.props;
      if (outgoingCallOverride) {
        const normalizedOverride = parsePhoneNumber(outgoingCallOverride, 'US')
          .number;

        if (normalizedOverride !== contactNumber) {
          // eslint-disable-next-line no-console
          console.warn(
            `CallAndLinkToCase expected a call to ${phoneNumber} but instead is connecting to ${contactNumber}`,
          );
        }
      }
    }

    if (!caseId) {
      // we're rendered in a context that has no case ID... e.g. Dealer Locator.
      return;
    }

    caseCallLinkHelper({
      contact: callContact,
      contactNumber,
      stateStartTime,
      currentCaseId: caseId,
      linkCaseToCall,
      destinationName: name || get(dealerContact, 'name', get(dealer, 'name')),
      destinationNumber: contactNumber,
      destinationOrganization: get(dealer, 'name'),
    });

    // Mark the call as already linked to the case, so that
    // AutoLinkDialedCallsToCase does not try to link it again.
    // eslint-disable-next-line no-param-reassign
    meta.callLinkedToCase = true;

    next();
  };

  callAndLinkToCase = (name) => {
    const {
      phoneNumber,
      addContactConnectingListener,
      makeOutboundCall,
    } = this.props;

    this.removeListener = addContactConnectingListener(
      this.onContactConnecting,
    );

    makeOutboundCall(phoneNumber, name);
  };

  render() {
    const { children, contactNumber, makeOutboundCall } = this.props;
    return children({
      callAndLinkToCase: makeOutboundCall && this.callAndLinkToCase,
      contactNumber,
    });
  }
}

export default compose(
  setDisplayName('CallAndLinkToCase'),
  withContext(CaseStatusContext, false),
  withCallHistoryActions,
  withContext(AmazonConnectProvider),
)(CallAndLinkToCase);
