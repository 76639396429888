import gql from 'graphql-tag';

export const NAME = 'caseBillingInfoQuery';

const query = gql`
  query caseBillingInfoQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      billingInfo {
        amount
        comment
        poNumber
        woNumber
        ticketNumber
        invoiceNumber
        referenceNumber
        contractNumber
        releaseNumber
      }
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
          billingPreferences {
            purchaseOrderRule
            releaseNumberRule: releaseNumber
            contractNumberRule: contractNumber
          }
        }
        ... on StoreCustomer {
          id
          type
          name
          billingPreferences {
            purchaseOrderRule
            releaseNumberRule: releaseNumber
            contractNumberRule: contractNumber
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;
