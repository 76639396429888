import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { find, isEmpty, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import {
  Divider,
  Dropdown,
  Icon,
  InputField,
  MessageMedium,
  MessageSmall,
  Popover,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import GroupList from '../GroupList';
import GroupListItem from '../GroupList/Item';
import DropdownButtonLink from './DropdownButtonLink';
import withCaseInboundProgram from '../../withCaseInboundProgram';
import withStoreCustomerSearch from '../../withStoreCustomerSearch';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withClearCustomerSearchValue from '../../withClearCustomerSearchValue';
import { fieldIds } from '../../constants';
import { sortAndGroupCustomers } from '../utils';

export class StoreCustomerSelector extends Component {
  static propTypes = {
    clearCaseCustomer: PropTypes.func.isRequired,
    clearCustomerSearchValue: PropTypes.func.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        accountNumber: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    customerTypes: PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isSearching: PropTypes.bool,
    onChangeStoreCustomerSearchValue: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    setCasePanelIncomplete: PropTypes.func.isRequired,
    setCasePanelInvalid: PropTypes.func.isRequired,
    storeCustomerSearchValue: PropTypes.string,
    updateCustomerType: PropTypes.func.isRequired,
    updateCustomerWidgetContext: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customer: {},
    customers: [],
    isSearching: false,
    storeCustomerSearchValue: '',
  };

  state = { isInvalid: false };

  componentDidMount() {
    const storeCustomerSearchValue = get(this.props.customer, 'name', '');
    this.props.onChangeStoreCustomerSearchValue(storeCustomerSearchValue);
  }

  handleCreateCustomStoreCustomer = () => {
    this.props.updateCustomerWidgetContext({ isNewCustomStoreCustomer: true });
    this.props.updateCustomerType(this.props.customerTypes.CUSTOM_STORE.type);
    this.props.setCasePanelIncomplete();
  };

  handleDropdownSelect = (_, customerId) => {
    if (customerId) {
      const customer =
        find(this.props.customers, (c) => c.id === customerId) || {};

      this.setState({ isInvalid: false });
      this.props.onSelectCustomer(customerId, customer.name, customer);
    }
  };

  resetCustomer = () => {
    this.setState({ isInvalid: false });
    this.props.clearCustomerSearchValue();
    this.props.clearCaseCustomer();
  };

  validate = () => {
    const {
      storeCustomerSearchValue,
      customer,
      setCasePanelInvalid,
    } = this.props;
    if (storeCustomerSearchValue && !customer) {
      setCasePanelInvalid({ [fieldIds.store.customer]: 'invalid' });
      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
    }
  };

  renderErrorMessage = () => {
    const { storeCustomerSearchValue } = this.props;
    return (
      <Container
        style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
        className="no-results"
        modifiers={['padScale_0']}
      >
        <MessageMedium>
          <MessageMedium.Header>
            <MessageMedium.Icon name="search" />
          </MessageMedium.Header>
          <MessageMedium.Section>
            <MessageMedium.Title>
              <Trans>No Matches Found</Trans>
            </MessageMedium.Title>
          </MessageMedium.Section>
          <MessageMedium.Section>
            <MessageMedium.Text>
              <Trans>
                Try Removing or editing some of your criteria or search for
                different words
              </Trans>
            </MessageMedium.Text>
          </MessageMedium.Section>
        </MessageMedium>
        <Divider />
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <DropdownButtonLink onClick={this.handleCreateCustomStoreCustomer}>
              <Row modifiers={['padScaleX_4', 'padScaleY_2']}>
                <Column modifiers={['padScaleX_0']}>
                  <Icon name="plus" />
                </Column>
                <Column modifiers={['col', 'padScaleX_2']}>
                  <Trans>
                    Create &quot;
                    <strong>{storeCustomerSearchValue}</strong>
                    &quot; as new store account
                  </Trans>
                </Column>
              </Row>
            </DropdownButtonLink>
          </Column>
        </Row>
      </Container>
    );
  };

  renderCustomers = (searchValue, customers) => {
    const groupedCustomers = sortAndGroupCustomers(customers);

    if (searchValue && isEmpty(groupedCustomers)) {
      return this.renderErrorMessage();
    }

    return (
      <GroupList
        isStore
        highlightText={searchValue}
        groupedCustomers={groupedCustomers}
      />
    );
  };

  renderPopoverTarget = (show) => {
    const {
      isReadOnlyCase,
      onChangeStoreCustomerSearchValue,
      storeCustomerSearchValue,
    } = this.props;

    return (
      <InputField
        isValid={!this.state.isInvalid}
        onBlur={this.validate}
        onFocus={show}
        onChange={(event) =>
          onChangeStoreCustomerSearchValue(event.target.value)
        }
        placeholder={t`Search by name, city/state, or account number...`}
        value={storeCustomerSearchValue}
        readOnly={isReadOnlyCase}
      >
        {this.renderAvatar()}
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <InputField.Label>
              <Trans>Store Account</Trans>
            </InputField.Label>
          </Row>
          <Row>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.customer,
                id: 'goToCustomerAction',
                name: t`Go to Fleet`,
                shortcut: ['f', '0'],
                priority: 8,
                icon: 'arrow-right',
              }}
            >
              {({ onFocusRequested: onShortcutFocusRequested }) => (
                <InputField.TextField
                  ref={(ref) => {
                    this.props.onFocusRequested(ref);
                    onShortcutFocusRequested(ref);
                  }}
                />
              )}
            </CaseShortcut>

            {storeCustomerSearchValue && (
              <InputField.ActionButton
                icon="times"
                type="button"
                onClick={this.resetCustomer}
                modifiers={['hoverDanger', 'padScaleX_0']}
              />
            )}
          </Row>
        </Column>
      </InputField>
    );
  };

  renderAvatar = () => {
    const { customer } = this.props;

    if (customer) {
      return (
        <InputField.Avatar
          name={customer.name}
          modifiers={['primary', 'small']}
          isCompany
        />
      );
    }
    return <InputField.Avatar modifiers={['secondary', 'small']} isCompany />;
  };

  render() {
    const {
      customer,
      customers,
      isReadOnlyCase,
      isSearching,
      storeCustomerSearchValue,
    } = this.props;
    const selectedCustomerID = get(customer, 'id');

    return (
      <>
        <Dropdown
          fullWidth
          hideOnChange
          onChange={this.handleDropdownSelect}
          activeItem={selectedCustomerID}
          zIndex={2}
          readOnly={isReadOnlyCase}
        >
          {({ show }) => (
            <>
              {selectedCustomerID ? (
                <Popover arrow showOnHover position="right" style={{ flex: 1 }}>
                  <Popover.Target style={{ flex: 1 }}>
                    {this.renderPopoverTarget(show)}
                  </Popover.Target>
                  <Popover.Content
                    style={{
                      padding: `${px2rem(10)} ${px2rem(14)}`,
                      width: px2rem(300),
                    }}
                  >
                    <GroupListItem info={customer} isStore showAvatar={false} />
                  </Popover.Content>
                </Popover>
              ) : (
                this.renderPopoverTarget(show)
              )}
              <Dropdown.Content
                hideContent={isSearching || !storeCustomerSearchValue}
              >
                {this.renderCustomers(storeCustomerSearchValue, customers)}
              </Dropdown.Content>
            </>
          )}
        </Dropdown>
        {this.state.isInvalid && (
          <Row>
            <Column modifiers={['col', 'padScaleY_3']}>
              <MessageSmall type="warning">
                <Trans>Select an existing store account.</Trans>
              </MessageSmall>
            </Column>
          </Row>
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('StoreCustomerSelector'),
  withCaseInboundProgram,
  withClearCustomerSearchValue,
  withStoreCustomerSearch,
  withStoreCustomerSelect,
  withFocusReceiver(fieldIds.store.customer),
)(StoreCustomerSelector);
