import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { noop, get, filter, toLower, isEqual, isEmpty } from 'lodash';

import { Text, Tooltip } from 'base-components';
import { Row, Container, Column } from 'styled-components-grid';

import HighlightText from 'components/HighlightText';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import InputField from './InputField';
import { fieldIds } from '../../constants';

import { Dropdown, getSelectedOption } from './utils';

export class DropdownCell extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    autoSelectSingleOption: PropTypes.bool,
    isNonPreferredSelection: PropTypes.bool,
    optional: PropTypes.bool,
    autocomplete: PropTypes.bool,
    onReset: PropTypes.func,
    disabledOptionIds: PropTypes.arrayOf(PropTypes.string),
    rowData: PropTypes.shape({}),
    dependsOn: PropTypes.arrayOf(PropTypes.string),
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    readOnly: false,
    onChange: noop,
    autoSelectSingleOption: false,
    isNonPreferredSelection: false,
    optional: false,
    onReset: noop,
    disabledOptionIds: [],
    autocomplete: false,
    rowData: {},
    dependsOn: [],
  };

  static getDerivedStateFromProps(props, state) {
    let statePatch = {};

    const { autocomplete, options, value } = props;
    const {
      prevInputValue,
      inputValue,
      visibleOptions,
      selectedValue,
      prevOptions,
    } = state;

    const lowerCasedInputValue = toLower(inputValue);

    if (inputValue !== prevInputValue || options !== prevOptions) {
      // Filter options by typed input value when autocomplete is active
      const newVisibleOptions = autocomplete
        ? filter(options, (o) =>
            toLower(o.title).includes(lowerCasedInputValue),
          )
        : options;

      if (!isEqual(visibleOptions, newVisibleOptions)) {
        statePatch.visibleOptions = newVisibleOptions;
      }
    }

    const inputValuesHaveChanged = [
      value !== selectedValue,
      options !== prevOptions,
    ].some(Boolean);

    if (inputValuesHaveChanged) {
      const selectedOption = getSelectedOption(props);

      statePatch = {
        ...statePatch,
        inputValue: selectedOption?.title || '',
        prevInputValue: inputValue,
        selectedOption,
        selectedValue: value,
        prevOptions: options,
      };
    }

    return !isEmpty(statePatch) ? statePatch : null;
  }

  constructor(props) {
    super(props);

    const selectedOption = getSelectedOption(props);

    this.state = {
      // Set the initial value of input field
      inputValue: selectedOption?.title || '',
      selectedOption,
      visibleOptions: props.options,
      prevInputValue: '',
      prevOptions: props.options,
      selectedValue: props.value,
    };
  }

  componentDidUpdate() {
    const { selectedOption } = this.state;
    const { options, autoSelectSingleOption } = this.props;

    // Auto select the single option if there is no selected value
    if (autoSelectSingleOption && !selectedOption && options.length === 1) {
      const optionId = get(options, '0.id');
      const newOption = getSelectedOption({ value: optionId, options });
      const inputValue = get(newOption, 'title') || '';

      this.setState({ inputValue, selectedOption: newOption }, () => {
        this.handleChange(optionId, true);
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.onFocusShowTimeout);
    clearTimeout(this.blurTimeout);
  }

  onInputChange = (e) => {
    e.preventDefault();

    if (this.props.autocomplete) this.updateInputValue(e.target.value);
  };

  setRef = (name) => (node) => {
    this[name] = node;

    return this[name];
  };

  updateInputValue = (newValue) => {
    const { inputValue } = this.state;

    if (inputValue !== newValue) {
      this.setState({ inputValue: newValue, prevInputValue: inputValue });
    }
  };

  handleChange = (value, isAutoSelectingSingleOption = false) => {
    const { onChange, options } = this.props;

    clearTimeout(this.blurTimeout);

    if (typeof value === 'string') {
      let newOption = getSelectedOption({ value, options });
      const currentOption = this.state.selectedOption;

      // If new value doesn't match an option, reset to currently selected option.
      // Useful for when focus is out of input/dropdown leaving it incomplete.
      // Unless new value is empty, in which case we want to leave it empty.
      if (value && !newOption) {
        newOption = currentOption;
      }

      const newId = get(newOption, 'id', '');
      const newValue = get(newOption, 'title', '');
      const currentId = get(currentOption, 'id');

      this.updateInputValue(newValue);

      const isChangingValue = newId !== currentId && (newId || currentId);

      // Only trigger change upwards if selected option changed
      if (isAutoSelectingSingleOption || isChangingValue) {
        onChange(newId, isAutoSelectingSingleOption);
      }
    }
  };

  handleExpandedChange = (show) => {
    // Trigger change when dropdown is out of focus to
    // prevent erasing selected option when navigating with
    // tab key, clicking outside of the dropdown, and when
    // selecting a new option from the dropdown

    if (!show) {
      const { inputValue, selectedOption } = this.state;

      this.handleChange(
        !inputValue && !this.props.optional
          ? selectedOption?.title
          : inputValue,
      );
    }
  };

  renderInputField = (props) => {
    const {
      show,
      toggle,
      disabled,
      isVisible,
      onFocusRequested,
      ...rest
    } = props;

    const { inputValue } = this.state;
    const { readOnly, optional, onReset, autocomplete } = this.props;

    const onFocus = () => {
      // Delay showing the dropdown when receiving focus, to prevent
      // flashing when the focus event is the result of clicking the
      // chevron.
      this.onFocusShowTimeout = setTimeout(show, 100);
    };

    return (
      <InputField
        value={inputValue}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={t`Select...`}
        onChange={this.onInputChange}
        onFocus={onFocus}
        {...rest}
      >
        <Column modifiers={['col', 'padScaleY_0']}>
          <Row>
            <InputField.TextField
              readOnly={readOnly || !autocomplete}
              ref={(node) => {
                this.setRef('inputRef')(node);

                if (node) onFocusRequested(node);
              }}
            />
            {(optional || autocomplete) && inputValue && (
              <InputField.ActionButton
                icon="times"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();

                  if (autocomplete) {
                    this.handleChange('');
                  } else {
                    onReset();
                  }

                  if (this.inputRef) this.inputRef.focus();
                }}
                modifiers={['padScaleX_0', 'hoverDanger']}
              />
            )}
            <InputField.ActionButton
              icon={isVisible ? 'chevron-up' : 'chevron-down'}
              onClick={(e) => {
                e.stopPropagation();
                toggle();
              }}
              modifiers={['hoverInfo']}
            />
          </Row>
        </Column>
      </InputField>
    );
  };

  renderOptionsList = (options, selectedId, highlightText) => {
    const { disabledOptionIds, autocomplete } = this.props;

    const renderTrans = ({ translation }) => {
      if (!autocomplete) return translation;

      return <HighlightText text={highlightText}>{translation}</HighlightText>;
    };

    return (
      <Dropdown.Content>
        <Dropdown.List>
          {options.map(({ id, title, upstreamId }) => (
            <Dropdown.ListItem
              id={id}
              key={upstreamId ?? `${id}.${title}`}
              modifiers={
                id !== selectedId && disabledOptionIds.includes(id)
                  ? ['disabled']
                  : undefined
              }
            >
              <Text>
                <Trans id={title || ''} render={renderTrans} />
              </Text>
            </Dropdown.ListItem>
          ))}
        </Dropdown.List>
      </Dropdown.Content>
    );
  };

  render() {
    const {
      name,
      value,
      options,
      onChange,
      readOnly,
      autoSelectSingleOption,
      isNonPreferredSelection,
      optional,
      onReset,
      disabledOptionIds,
      autocomplete,
      ...rest
    } = this.props;

    const { inputValue, visibleOptions, selectedOption } = this.state;

    const selectedId = get(selectedOption, 'id');
    const selectedTitle = get(selectedOption, 'title');

    const disabled = !options.length && !selectedId;

    return (
      <Dropdown
        name={name}
        ref={this.setRef('dropdownRef')}
        onChange={(e, newValue) => this.handleChange(newValue)}
        readOnly={readOnly}
        onExpandedChange={this.handleExpandedChange}
        fullWidth
        activeItem={selectedId}
        hideOnChange
        isNonPreferredSelection={isNonPreferredSelection}
      >
        {({ isVisible, show, toggle }) => {
          const inputField = this.renderInputField({
            show,
            toggle,
            disabled,
            isVisible,
            ...rest,
          });

          let tooltipContent =
            disabled && !readOnly ? <Trans>No option available.</Trans> : null;

          if (!disabled && selectedTitle) {
            tooltipContent = <span>{selectedTitle}</span>;
          }

          return (
            <>
              <Container
                onClick={disabled ? undefined : show}
                modifiers="padScale_0"
              >
                <Tooltip>
                  <Tooltip.Target>{inputField}</Tooltip.Target>
                  {tooltipContent && (
                    <Tooltip.Content>{tooltipContent}</Tooltip.Content>
                  )}
                </Tooltip>
              </Container>
              {!!visibleOptions.length &&
                this.renderOptionsList(visibleOptions, selectedId, inputValue)}
            </>
          );
        }}
      </Dropdown>
    );
  }
}

const getFieldId = ({ rowType, name, rowData: { id } }) =>
  get(fieldIds, [rowType, name], '').replace('{id}', id);

export default compose(
  setDisplayName('DropdownCell'),
  withFocusReceiver(getFieldId),
)(DropdownCell);
