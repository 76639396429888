import gql from 'graphql-tag';
import { defineMessage } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const emptyRequestLine = {
  asset: null,
  assetId: null,
  loadRange: null,
  manufacturerFullName: null,
  nonPreferredSelections: [],
  productType: null,
  requestedAction: null,
  rimType: null,
  sculptureTreadName: null,
  tireCondition: null,
  tirePosition: null,
  tireSize: null,
  axleType: null,
  isReadOnly: false,
};

export const requestsTabIndices = {
  requested: 0,
  agreed: 1,
  supplied: 2,
};

export const AssetFieldsFragment = gql`
  fragment AssetFields on Asset {
    id
    assetType
    canDelete
    droppedUnit
    unitNumber
    trailerType
    readOnlyStatus
  }
`;

export const RequestLineFieldsFragment = gql`
  fragment RequestLineFields on CaseRequestLine {
    id
    asset {
      ...AssetFields
    }
    loadRange
    manufacturerFullName
    nonPreferredSelections
    productType
    requestedAction
    rimType
    sculptureTreadName
    tireCondition
    tirePosition
    tireSize
    axleType
    isReadOnly
  }
  ${AssetFieldsFragment}
`;

// Please keep this array sorted as per the fields in the UI.
// We are currently using it to display field validations in
// the same order.
export const allRequestLineKeys = [
  'asset',
  'requestedAction',
  'tireCondition',
  'axleType',
  'tirePosition',
  'productType',
  'manufacturerFullName',
  'tireSize',
  'sculptureTreadName',
  'loadRange',
  'rimType',
];

export const optionalRequestLineKeys = ['rimType'];

export const rimReplacementProductType = 'Rim Replacement';
export const rimDamagedProductCondition = 'RIM_DAMAGED';

export const productTypeIds = {
  0: 'New',
  1: 'Retread',
  2: rimReplacementProductType,
};

export const delayedServiceModalModes = buildEnum([
  // Used when the user intentionally marks the case as "Delayed Service" or
  // asks to edit the delayed service information.
  'default',

  // Used to ask the user to confirm the case is not ERS anymore and to collect
  // the delayed service information.
  'confirmDelayedService',

  // Used to ask the user to confirm the case is not in delayed service anymore.
  'confirmERS',
]);

export const lineKeysToClearOnDuplicateToRim = [
  'rimType',
  'tireSize',
  'loadRange',
  'tireCondition',
  'sculptureTreadName',
  'manufacturerFullName',
];

const lineFields = [
  'unit',
  'requestedAction',
  'condition',
  'position',
  'type',
  'brand',
  'size',
  'design',
  'loadRange',
  'rimType',
];

const fieldKeyForType = (type) => (key) => `request.${type}.{id}.${key}`;

export const fieldIds = {
  assets: buildEnum(['number', 'type'], fieldKeyForType('assets')),
  requestLines: buildEnum(lineFields, fieldKeyForType('requestLines')),
  agreementLines: buildEnum(lineFields, fieldKeyForType('agreementLines')),
  suppliedLines: buildEnum(lineFields, fieldKeyForType('suppliedLines')),
};

export const linesFieldValidationPrefixes = {
  requested: defineMessage({ message: 'Requested Row {index}:' }),
  agreed: defineMessage({ message: 'Agreed Row {index}:' }),
  supplied: defineMessage({ message: 'Supplied Row {index}:' }),
};
