import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { omit, identity } from 'lodash';
import { compose, mapProps } from 'recompose';

import createPrimaryAsset from './createPrimaryAssetMutation';
import createRelatedAsset from './createRelatedAssetMutation';
import deleteAssets from './deleteAssetsMutation';
import updateAsset from './updateAssetMutation';

import { buildQueryNameForField } from '../../CaseRequestLines/BaseRequestLinesTable/TireOptionsDropdownCell/tireOptionsQuery';
import { CASE_REQUEST_ASSETS_QUERY_NAME } from '../../RequestAssetsForm/withRequestAssets/caseRequestAssetsQuery';

// We reload the Axle Type and Tire Position options
// when the unit type changes, because the axle types depend on it.
const axleTypesQueryName = buildQueryNameForField('axleType');
const tirePositionsQueryName = buildQueryNameForField('tirePosition');

const refetchQueries = () => [
  CASE_REQUEST_ASSETS_QUERY_NAME,
  axleTypesQueryName,
  tirePositionsQueryName,
];

// Remove the "readOnlyStatus" prop that we append to assets,
// otherwise GraphQL will complain it does not recognise it.
const omitReadOnly = (vars) => omit(vars, 'asset.readOnlyStatus');

const withMutation = (config) => {
  const { varsTransformer = omitReadOnly } = config;
  const { mutation, mutateFnPropName, loadingPropName } = config;

  return (Component) => (props) => {
    const [run, { loading }] = useMutation(mutation, { refetchQueries });

    const mutate = useCallback(
      (vars) => run({ variables: varsTransformer(vars) }),
      [run],
    );

    const allProps = {
      ...props,
      [mutateFnPropName]: mutate,
      [loadingPropName]: !!loading,
    };

    return <Component {...allProps} />;
  };
};

export default compose(
  withMutation({
    mutation: createPrimaryAsset,
    loadingPropName: 'creatingPrimary',
    mutateFnPropName: 'createPrimaryAsset',
  }),

  withMutation({
    mutation: createRelatedAsset,
    loadingPropName: 'creatingRelated',
    mutateFnPropName: 'createRelatedAsset',
  }),

  withMutation({
    mutation: updateAsset,
    loadingPropName: 'updating',
    mutateFnPropName: 'updateAsset',
  }),

  withMutation({
    mutation: deleteAssets,
    varsTransformer: identity,
    loadingPropName: 'deleting',
    mutateFnPropName: 'deleteAssets',
  }),

  mapProps(
    ({ creatingPrimary, creatingRelated, updating, deleting, ...rest }) => {
      const status = [creatingPrimary, creatingRelated, updating, deleting];

      return { isSavingAssets: status.some(Boolean), ...rest };
    },
  ),
);
