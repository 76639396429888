import React, { useCallback } from 'react';
import { get, omit } from 'lodash';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation updateCaseAgreementLine($id: ID!, $patch: CaseAgreementLinePatch!) {
    updateCaseAgreementLine(input: { id: $id, patch: $patch }) {
      requestAgreementLine {
        agreed
        agreementLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCaseUpdateRequestAgreementLine = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const updateLine = useCallback(
    ({ patch = {}, ...rest }) => {
      const line = omit(patch.agreementLine, 'asset');
      const assetId = get(patch, 'agreementLine.asset.id');
      const agreementLine = { ...line, assetId };
      const variables = { ...rest, patch: { ...patch, agreementLine } };

      return mutate({ variables });
    },
    [mutate],
  );

  return <Component {...props} updateRequestAgreementLine={updateLine} />;
};

export default withCaseUpdateRequestAgreementLine;
