import React from 'react';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';

import caseBillingInfoQuery from './caseBillingInfoQuery';
import updateCaseBillingInfoMutation from './updateCaseBillingInfoMutation';
import { fieldsConfig } from './constants';

const billingInfoKeys = Object.keys(fieldsConfig);

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseBillingInfo) => (
    <WrappedComponent
      {...componentProps}
      updateCaseBillingInfo={(billingInfo) => {
        updateCaseBillingInfo({
          variables: {
            caseId: componentProps.caseId,
            billingInfo: billingInfoKeys.reduce(
              (acc, key) => ({
                ...acc,
                [key]: billingInfo[key] || '',
              }),
              {},
            ),
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={updateCaseBillingInfoMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseData = get(queryProps, 'data.case') || {};
    const billingPrefs = get(caseData, 'customer.billingPreferences') || {};

    const props = {
      ...componentProps,
      caseId: caseData.id,
      billingInfo: caseData.billingInfo || {},
      purchaseOrderRule: (billingPrefs.purchaseOrderRule || '').toUpperCase(),
      releaseNumberRule: (billingPrefs.releaseNumberRule || '').toUpperCase(),
      contractNumberRule: (billingPrefs.contractNumberRule || '').toUpperCase(),
    };

    return buildWrappedComponentWithMutation(WrappedComponent, props);
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      fetchPolicy="cache-and-network"
      query={caseBillingInfoQuery}
      skip={!caseNumber}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseBillingInfo = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withCaseBillingInfo;
