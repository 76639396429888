import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { t, Trans } from '@lingui/macro';
import {
  branch,
  compose,
  mapProps,
  withState,
  setDisplayName,
} from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row } from 'styled-components-grid';
import { Accordion, DataTable } from 'base-components';

import Tabs from 'components/Tabs';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withRequestAgreementLinesActions from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withRequestAgreementLinesActions';

import withCustomerSelect from '../../withCustomerSelect';
import withTirePreferences from './withTirePreferences';
import SingleCallRequested from './SingleCallRequested';
import PreferencesEmptyState from '../PreferencesEmptyState';
import PreferenceDetailsModal from '../PreferenceDetailsModal';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import { specialTireTypes, tabTitleByTireType } from './constants';

import {
  groupTires,
  renderGroupHeader,
  tireChoicePropType,
  buildColumnsForTireGroup,
} from './utils';

const specialTireKeys = Object.keys(specialTireTypes);
const specialTireValues = Object.values(specialTireTypes);

const containerStyles = {
  width: '100%',
  paddingTop: px2rem(12),
  paddingBottom: px2rem(12),
};

const rowsConfig = [
  { label: t`Tire Type`, valueProp: 'type' },
  { label: t`Brand`, valueProp: 'brand' },
  { label: t`Size`, valueProp: 'size' },
  { label: t`Tread`, valueProp: 'tread' },
  { label: t`Load Range`, valueProp: 'loadRange' },
  { label: t`Application`, valueProp: 'application' },
  { label: t`Required Activity`, valueProp: 'requiredActivity' },
  { label: t`Comments`, valueProp: 'comments' },
];

export function TiresList(props) {
  const { tireGroups, selectedTire, setSelectedTire } = props;

  if (!tireGroups.length) {
    return <PreferencesEmptyState title={<Trans>No Tire Preferences</Trans>} />;
  }

  return tireGroups.map((tireGroup) => {
    const [metadata, tires] = tireGroup;
    const columns = buildColumnsForTireGroup(tireGroup, props);

    return (
      <Container key={metadata.name} style={containerStyles}>
        <Row>{renderGroupHeader(metadata)}</Row>
        <DataTable
          style={{ height: 'auto' }}
          scrollX
          columns={columns}
          modifiers=""
          tableData={tires}
          rowKeyGetter={(row) => row.priority}
          rowModifiersGetter={() => ['noHighlightOnHover', 'notFlex']}
        />
        {selectedTire && (
          <PreferenceDetailsModal
            title={<Trans>Tire Preference Details</Trans>}
            onClose={() => setSelectedTire(null)}
            preference={selectedTire}
            rowsConfig={rowsConfig}
          />
        )}
      </Container>
    );
  });
}

TiresList.propTypes = {
  tireGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedTire: tireChoicePropType,
  setSelectedTire: PropTypes.func.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  caseId: PropTypes.string,
  axleType: PropTypes.oneOf(specialTireValues).isRequired,
  caseStatus: PropTypes.string.isRequired,
  isReadOnlyCase: PropTypes.bool,
  createRequestAgreementLine: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

TiresList.defaultProps = {
  caseId: undefined,
  selectedTire: null,
  isReadOnlyCase: false,
};

const TiresListWithModal = compose(
  setDisplayName('TiresList'),
  withRequestAgreementLinesActions,
  mapProps(groupTires),
  withState('selectedTire', 'setSelectedTire'),
)(TiresList);

const buildTab = (props, type) => {
  const data = {
    ...props,
    tires: props.tirePreferences[type],
    axleType: specialTireTypes[type],
  };

  return {
    key: type,
    title: <Trans id={tabTitleByTireType[specialTireTypes[type]]} />,
    content: <TiresListWithModal {...data} />,
  };
};

export function TirePreferences(props) {
  const { tirePreferences, customer } = props;

  const hasData = !Object.values(tirePreferences).every(isEmpty);
  const tabs = specialTireKeys.map((key) => buildTab(props, key));

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'tirePreferencesAction',
    name: t`Tire Preferences`,
    shortcut: ['f', '5'],
    keywords: 'fleet tire preferences',
    priority: 3,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Tire Preferences</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {hasData && (
        <Accordion.Body>
          {get(customer, 'singleCallRequested') && <SingleCallRequested />}

          <div style={{ overflowX: 'auto' }}>
            <Tabs compact tabs={tabs} tabGroupName="tire-preferences" />
            <br />
          </div>
        </Accordion.Body>
      )}
    </Accordion>
  );
}

TirePreferences.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  caseId: PropTypes.string,
  caseStatus: PropTypes.string.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */
  tirePreferences: PropTypes.shape({
    Steer: PropTypes.arrayOf(tireChoicePropType),
    Drive: PropTypes.arrayOf(tireChoicePropType),
    Trailer: PropTypes.arrayOf(tireChoicePropType),
    Other: PropTypes.arrayOf(tireChoicePropType),
  }),
  customer: PropTypes.shape({
    id: PropTypes.string,
    singleCallRequested: PropTypes.bool,
  }),
};

TirePreferences.defaultProps = {
  caseId: undefined,
  customer: undefined,
  tirePreferences: {},
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('TirePreferences'),
  /**
   * Order is important here. `withTirePreferences` needs the current customer
   * as a prop, so we run `withCustomerSelect/withStoreCustomerSelect` first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withTirePreferences,
)(TirePreferences);
