import { Trans } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';

import { withReadOnlyCase } from 'compositions/CaseStatus';

import StoreCustomerCreditCard from '../StoreCustomerCreditCard';
import withSetPaymentDetails from '../withSetPaymentDetails';
import { PAYMENT_METHODS } from '../constants';

const OPTIONS = {
  THIRD_PARTY_CREDIT_CARD_TXN: {
    label: 'other-credit-card',
    value: PAYMENT_METHODS.THIRD_PARTY_CREDIT_CARD_TXN,
  },
  CASH: {
    label: 'other-cash',
    value: PAYMENT_METHODS.CASH,
  },
  COM_CHECK: {
    label: 'other-com-check',
    value: PAYMENT_METHODS.COM_CHECK,
  },
  OTHER_NATIONAL_ACCOUNT: {
    label: 'other-national-account',
    value: PAYMENT_METHODS.OTHER_NATIONAL_ACCOUNT,
  },
  NONE: {
    label: 'other-none',
    value: PAYMENT_METHODS.NONE,
  },
  CREDIT_CARD_NO_PREAUTH: {
    label: 'other-pre-auth',
    value: PAYMENT_METHODS.CREDIT_CARD_NO_PREAUTH,
  },
};

class Other extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
  };

  state = {
    selectedOption: get(OPTIONS[this.props.paymentMethod], 'label', ''),
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.label });
  };

  render() {
    const { selectedOption } = this.state;
    const { caseNumber, isReadOnlyCase } = this.props;
    return (
      <Row>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.THIRD_PARTY_CREDIT_CARD_TXN.label}
                checked={
                  selectedOption === OPTIONS.THIRD_PARTY_CREDIT_CARD_TXN.label
                }
                label={<Trans>Credit Card</Trans>}
                onChange={() =>
                  this.handleChange(OPTIONS.THIRD_PARTY_CREDIT_CARD_TXN)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          {selectedOption === OPTIONS.THIRD_PARTY_CREDIT_CARD_TXN.label && (
            <Row>
              <StoreCustomerCreditCard
                caseNumber={caseNumber}
                isReadOnlyCase={isReadOnlyCase}
              />
            </Row>
          )}
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CREDIT_CARD_NO_PREAUTH.label}
                checked={
                  selectedOption === OPTIONS.CREDIT_CARD_NO_PREAUTH.label
                }
                label={<Trans id="Credit Card - No Preauth" />}
                onChange={() =>
                  this.handleChange(OPTIONS.CREDIT_CARD_NO_PREAUTH)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CASH.label}
                checked={selectedOption === OPTIONS.CASH.label}
                label={<Trans>Cash</Trans>}
                onChange={() => this.handleChange(OPTIONS.CASH)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.COM_CHECK.label}
                checked={selectedOption === OPTIONS.COM_CHECK.label}
                label={<Trans>COMcheck</Trans>}
                onChange={() => this.handleChange(OPTIONS.COM_CHECK)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.OTHER_NATIONAL_ACCOUNT.label}
                checked={
                  selectedOption === OPTIONS.OTHER_NATIONAL_ACCOUNT.label
                }
                label={<Trans id="Other National Account" />}
                onChange={() =>
                  this.handleChange(OPTIONS.OTHER_NATIONAL_ACCOUNT)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.NONE.label}
                checked={selectedOption === OPTIONS.NONE.label}
                label={<Trans>Continue without payment method</Trans>}
                onChange={() => this.handleChange(OPTIONS.NONE)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default compose(
  setDisplayName('Other'),
  withSetPaymentDetails,
  withReadOnlyCase,
)(Other);
