import React, { Component } from 'react';
import styled from 'styled-components';
import Bugsnag from '@bugsnag/browser';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { get, memoize } from 'lodash';
import { compose, fromRenderProps, setDisplayName } from 'recompose';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

import { AmazonConnect } from 'features/amazonConnect';

import SecureIvrModal from '../SecureIvrModal';

import CreditCardInfo from './CreditCardInfo';
import ExternalServices from './ExternalServices';
import TransferToIVRButton from './TransferToIVRButton';
import TransactionReceiptForm from './TransactionReceiptForm';

const {
  REACT_APP_CC_DECRYPTION_SERVICE_URL: ccDecryptionServiceURL,
  REACT_APP_CC_DECRYPTION_SERVICE_API_KEY: ccDecryptionServiceAPIKey,
} = process.env;

const hasValidCCDecryptionServiceConfig =
  !!ccDecryptionServiceURL && !!ccDecryptionServiceAPIKey;

const ccDecryptionRequestHeaders = {
  'Content-Type': 'application/json',
  'X-API-KEY': ccDecryptionServiceAPIKey,
};

const decryptCreditCardNumber = memoize(
  async (encryptedCreditCard, { onError }) => {
    try {
      const response = await fetch(ccDecryptionServiceURL, {
        body: JSON.stringify({ encryptedCreditCard }),
        headers: ccDecryptionRequestHeaders,
        method: 'POST',
      });

      return (await response.json()).cardNumber;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to decrypt credit card', error);

      if (onError) onError(error);
    }

    return undefined;
  },
  (encryptedCreditCard) => encryptedCreditCard,
);

const PaymentTypeItemOptionsColumn = buildStyledComponent(
  'PaymentTypeItemOptionsColumn',
  styled(Column),
  `
    flex: 1 1 auto;
    padding: ${px2rem(4)} 0 ${px2rem(4)} ${px2rem(24)};
  `,
);

const IVR_QUICK_CONNECT = SecureIvrModal.QUICK_CONNECTS.STORE_CREDIT_CARD;

export class StoreCustomerCreditCard extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    ccp: PropTypes.shape({
      contact: PropTypes.shape({
        getAgentConnection: PropTypes.func.isRequired,
      }),
    }).isRequired,
  };

  state = {
    showIvrModal: false,
    ivrResult: null,
  };

  transferToIVR = () =>
    this.setState({
      // showing the IVR modal kicks off the IVR quick connect
      showIvrModal: true,
      ivrResult: null,
    });

  clearIVRResult = () => this.handleResults(null);

  handleResults = async (contactAttributes) => {
    const encryptedCreditCard = get(contactAttributes, 'cardNumber.value');
    const expirationDate = get(contactAttributes, 'expDate.value');
    let creditCardNumber;

    if (encryptedCreditCard) {
      creditCardNumber = await decryptCreditCardNumber(encryptedCreditCard, {
        onError: () => {
          // We clear the cache if an error occurred to
          // prevent returning it as the memoization result.
          decryptCreditCardNumber.cache.clear();

          this.handleFailedQuickConnect();
        },
      });
    }

    // get the card info from the IVR...
    const ivrResult = contactAttributes && {
      creditCardNumber,
      expirationDate,
    };

    // ...then auto-close the modal
    this.setState({
      showIvrModal: false,
      ivrResult,
    });

    // If the third party connection drops, the agent will still be on hold,
    // so we end the agent's connection in order for him to be transitioned to
    // "After Call Work".
    if (!encryptedCreditCard || !expirationDate) {
      const { contact } = this.props.ccp;

      if (contact) contact.getAgentConnection().destroy();
    }
  };

  handleFailedQuickConnect = () => {
    this.setState(
      {
        showIvrModal: false,
        ivrResult: null,
      },
      () => {
        // eslint-disable-next-line no-alert
        alert('Secure IVR connection failed, see console for details');

        Bugsnag.notify(
          `StoreCustomerCreditCard: Secure IVR connection failed to ${IVR_QUICK_CONNECT}`,
        );
      },
    );
  };

  render() {
    const { showIvrModal, ivrResult } = this.state;
    const { isReadOnlyCase } = this.props;

    const hasFullCreditCardInfo =
      !!get(ivrResult, 'creditCardNumber') &&
      !!get(ivrResult, 'expirationDate');

    return (
      <PaymentTypeItemOptionsColumn>
        {hasFullCreditCardInfo ? (
          <CreditCardInfo {...ivrResult} onRemove={this.clearIVRResult} />
        ) : (
          <AmazonConnect>
            {({ transferToQuickConnect, contact, uiState }) => (
              <>
                <TransferToIVRButton
                  onClick={this.transferToIVR}
                  showWarning={!!ivrResult}
                  isDisabled={
                    !transferToQuickConnect ||
                    isReadOnlyCase ||
                    !hasValidCCDecryptionServiceConfig
                  }
                />
                {showIvrModal && (
                  <SecureIvrModal
                    transferToQuickConnect={transferToQuickConnect || null}
                    quickConnect={IVR_QUICK_CONNECT}
                    headline={<Trans>Entering Credit Card Details...</Trans>}
                    body={
                      <Trans>
                        Please wait while the caller enters their credit card
                        details.
                      </Trans>
                    }
                    contact={contact}
                    handleResults={this.handleResults}
                    handleFailedQuickConnect={this.handleFailedQuickConnect}
                    uiState={uiState}
                  />
                )}
              </>
            )}
          </AmazonConnect>
        )}
        <ExternalServices />
        <TransactionReceiptForm
          caseNumber={this.props.caseNumber}
          isReadOnlyCase={isReadOnlyCase}
        />
      </PaymentTypeItemOptionsColumn>
    );
  }
}

export default compose(
  setDisplayName('StoreCustomerCreditCard'),
  fromRenderProps(AmazonConnect, (ccp) => ({ ccp })),
)(StoreCustomerCreditCard);
