import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Column, Row } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';
import { H2, P } from 'base-components';

import Modal from 'components/Modal';
import { isDisconnectedState } from 'features/amazonConnect';

/**
 * Names of the Amazon Connect "Quick Connects" for the different Secure IVR
 * flows for various scenarios.
 */
const IVR_QUICK_CONNECTS = Object.freeze({
  STORE_CREDIT_CARD: 'lbb secure ivr',
  NAFA_EXISTING_CARD: 'nafa validation',
  NAFA_NEW_CARD: 'orphan',
});

export default class SecureIvrModal extends Component {
  static propTypes = {
    /**
     * Function that will be called once the IVR quick connect has
     * completed. It will be called with the current contact attributes.
     */
    handleResults: PropTypes.func.isRequired,
    /**
     * Function that will be called if the transfer-to-IVR process fails.
     */
    handleFailedQuickConnect: PropTypes.func.isRequired,
    /**
     * The Amazon Connect function for initiating the transfer to a
     * quick connect. Note that this function will be undefined if
     * this component is, hence it is not _required_. But it should
     * be available at the initial render of this component.
     */
    transferToQuickConnect: PropTypes.func,
    /**
     * The name of the quick connect to transfer to for the IVR.
     */
    quickConnect: PropTypes.string.isRequired,
    /**
     * The current Amazon Connect "contact" for the call in progress.
     */
    contact: PropTypes.shape({
      getAttributes: PropTypes.func.isRequired,
      getSingleActiveThirdPartyConnection: PropTypes.func.isRequired,
    }).isRequired,
    /**
     * The text to render into the larger headline area of the modal.
     */
    headline: PropTypes.node.isRequired,
    /**
     * The text to render as the smaller body text of the modal.
     */
    body: PropTypes.node.isRequired,
    /**
     * The current Amazon Connect CCP call state. Used to watch for
     * a mid-IVR disconnection.
     */
    // eslint-disable-next-line react/no-unused-prop-types
    uiState: PropTypes.string.isRequired,
  };

  static defaultProps = {
    transferToQuickConnect: null,
  };

  static get QUICK_CONNECTS() {
    return IVR_QUICK_CONNECTS;
  }

  /**
   * Based on the third-party connection state (from the `contact` in
   * the `props`) determine if a secure IVR quick connect has been
   * initiated, or has completed.
   */
  static getDerivedStateFromProps(props, state) {
    const { contact, uiState } = props;
    const { ivrInProgress, activeThirdPartyConnection } = state;
    const currentThirdParty = contact.getSingleActiveThirdPartyConnection();

    if (
      ivrInProgress &&
      activeThirdPartyConnection &&
      (!currentThirdParty || isDisconnectedState(uiState))
    ) {
      // We've gone from IVR in progress, with an active 3rd-party connection
      // (for the quick connect), to no longer having an active 3rd-party
      // connection (or maybe we just got disconnected), so we can safely (???)
      // assume that the IVR is complete.
      return {
        activeThirdPartyConnection: null,
        ivrInProgress: false,
        ivrComplete: true,
      };
    }
    if (!activeThirdPartyConnection && currentThirdParty) {
      // We've gone from not having an active 3rd-party connection to
      // now having one, so let's save it for comparison next time we
      // come through here...
      return { activeThirdPartyConnection: currentThirdParty };
    }

    return null;
  }

  state = {
    /**
     * Is the secure IVR currently in progress via a 3rd-party quick connect?
     */
    // eslint-disable-next-line react/no-unused-state
    ivrInProgress: false,
    // eslint-disable-next-line react/no-unused-state
    activeThirdPartyConnection: null,
    /**
     * Have we already gone through the secure IVR, and now we're done with it?
     */
    ivrComplete: false,
  };

  /**
   * Initiate the quick connect upon component mount.
   */
  componentDidMount() {
    const { transferToQuickConnect, quickConnect } = this.props;
    // call the quick-connect helper with the provided quick-connect name
    // to initiate the secure IVR...
    // eslint-disable-next-line no-console
    console.log(`initiating transfer to IVR quick connect "${quickConnect}"..`);
    transferToQuickConnect(quickConnect, {
      success: this.onIvrConnected,
      failure: this.quickConnectFailure,
    });
  }

  /**
   * On each update check for changes in the contact that would indicate
   * that the IVR quick connect has completed (and the caller has been
   * reconnected to the agent).
   */
  componentDidUpdate() {
    const { handleResults, contact } = this.props;
    const contactAttributes = contact.getAttributes();

    const { ivrComplete } = this.state;

    if (ivrComplete) {
      // eslint-disable-next-line no-console
      console.log(`IVR complete.`);
      handleResults(contactAttributes);
    }
  }

  /**
   * Callback for a successful `transferToQuickConnect`.
   */
  onIvrConnected = () => {
    // eslint-disable-next-line no-console
    console.log(`IVR connected`);
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      ivrInProgress: true,
      ivrComplete: false,
    });
  };

  quickConnectFailure = () => {
    // eslint-disable-next-line no-console
    console.error(
      `Secure IVR Modal failed to initiate quick connect to "${this.props.quickConnect}" - is this endpoint properly configured in Amazon Connect?`,
    );
    const { handleFailedQuickConnect } = this.props;
    if (handleFailedQuickConnect) handleFailedQuickConnect();
  };

  render() {
    const { headline, body } = this.props;

    // The modal should be closed by the parent component upon a
    // call to `handleResults`, so we don't need an `onClose` here.
    return (
      <Modal onClose={noop}>
        {() => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name="credit-card" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">{headline}</H2>
                </Column>
              </Row>
              <Row modifiers="center">
                <Column>
                  <P style={{ marginBottom: px2rem(20) }}>{body}</P>
                </Column>
              </Row>
            </Modal.Content>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}
