// TODO: export configureGlobalStyles to decisiv-ui-utils and pull it from there instead
// And, after doing so, remove the coverage exclusion of "!src/setup/AppThemeProvider/**"
// from package.json's jest > collectCoverageFrom configuration.

import React from 'react';
import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { px2rem } from 'decisiv-ui-utils';

const NormalizeStyles = createGlobalStyle(normalize());

const GlobalStyles = createGlobalStyle`
  html, body {
    min-height: 100%;
    #root {
      > * {
        height: 100vh;
      }
    }
  }

  #offscreen-measurements-root {
    visibility: hidden;
    position: fixed;
    top: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  /*
    * Override google maps map type controls font size.
    * Hopefully the class names don't change.
    */
  .gm-style .gm-style-mtc > [type="button"] {
    font-size: ${px2rem(12)} !important;
  }

  .gm-style .gm-style-mtc label {
    font-size: ${px2rem(12)} !important;
  }

  @keyframes highlight {
    0% { outline-width: 0; outline-offset: 0; }
    20% { outline-width: 4px; outline-offset: -4px; }
    40% { outline-width: 1px; outline-offset: 0; }
    60% { outline-width: 4px; outline-offset: -4px; }
    100% { outline-width: 0; outline-offset: 0; }
  }

  .highlight {
    outline-color: #00bbff;
    outline-style: solid;
    outline-offset: 0;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: highlight;
    animation-timing-function: ease-in-out;
  }
`;

export default () => (
  <>
    <NormalizeStyles />
    <GlobalStyles />
  </>
);
