import styled, { css } from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

// TODO: Open PR in base-components exporting MessageSmall in `blocks`.
import BaseMessageSmall from 'base-components/blocks/MessageSmall';

// TODO: Shouldn't these styled be the default?
export default buildStyledComponent(
  'MessageSmall',
  styled(BaseMessageSmall),
  css`
    /* Align the icon to the first line of text */
    align-items: flex-start;

    > span {
      /* Allow to text to flow/break as needed */
      white-space: normal;
    }
  `,
);
