import { find, findIndex, get, last } from 'lodash';
import moment from 'moment';

import buildEnum from './buildEnum';

/**
 * @typedef {object} StatusChange
 * @property {string} changedAt the timestamp this change took place at
 * @property {string} id
 * @property {string} newStatus
 * @property {string} oldStatus
 */

export const CASE_STATUS = buildEnum([
  'new',
  'dispatch',
  'dispatched',
  'enRoute',
  'arrived',
  'rolling',
  'closed',
  'closed_canceled',
  'canceled',
]);

const CASE_EVENTS = [
  CASE_STATUS.new,
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
  CASE_STATUS.closed,
];

// This assumes that if the case is in closed_canceled, the previous status
// was canceled (that's the normal/expected path). So we ignore closed_canceled
// and only care for the status before canceled because it does not have a
// visual representation in the timeline.
function getStatusBeforeCanceled(statusHistory) {
  return get(
    find([...statusHistory].reverse(), { newStatus: CASE_STATUS.canceled }),
    'oldStatus',
  );
}

/**
 * Evaluates a status change to determine if it is a backward transition.
 * @param {StatusChange} statusChange
 * @returns {boolean}
 */
function isBackwardTransition({ oldStatus, newStatus } = {}) {
  if (!oldStatus || !newStatus) {
    return false;
  }

  const newStatusIndex = CASE_EVENTS.indexOf(newStatus);
  const oldStatusIndex = CASE_EVENTS.indexOf(oldStatus);

  // A transition might be to or from canceled or closed_canceled.
  // This transition will result in an index of -1, but is not a backwards transition
  if (newStatusIndex < 0 || oldStatusIndex < 0) {
    return false;
  }

  return newStatusIndex < oldStatusIndex;
}

/**
 * Evaluates a status change to determine if a transition is from a closed state back to rolling.
 * @param {StatusChange} statusChange
 * @returns {boolean}
 */
function isReopenedToRolling({ oldStatus, newStatus } = {}) {
  return (
    [
      CASE_STATUS.canceled,
      CASE_STATUS.closed,
      CASE_STATUS.closed_canceled,
    ].includes(oldStatus) && newStatus === CASE_STATUS.rolling
  );
}

function sortStatusHistory(statusHistory) {
  return statusHistory
    .slice()
    .sort((a, b) =>
      moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1,
    );
}

/**
 * Builds case status change events from a case's status history.
 * @param {object} options
 * @param {string} options.caseCreatedAt
 * @param {string} options.status
 * @param {StatusChange[]} options.statusHistory
 */
function caseEventsFromStatusHistory({ caseCreatedAt, status, statusHistory }) {
  let history = sortStatusHistory(statusHistory);

  // We want the timeline to treat "closed_canceled" cases
  // as if their current status was "closed", so that the
  // "closed" indicator becomes active and has an arrow.
  if (
    status === CASE_STATUS.closed_canceled &&
    get(last(history), 'newStatus') === CASE_STATUS.closed_canceled
  ) {
    history = [...history, { ...last(history), newStatus: CASE_STATUS.closed }];
  }

  const caseIsCanceled = [
    CASE_STATUS.canceled,
    CASE_STATUS.closed_canceled,
  ].includes(status);

  const caseIsClosed = [
    CASE_STATUS.closed,
    CASE_STATUS.closed_canceled,
  ].includes(status);

  function isStatusChangeEventActive(type) {
    return type === status;
  }

  function isStatusChangeEventComplete(completedAt, type) {
    return [
      !!completedAt && type !== CASE_STATUS.closed,
      caseIsClosed && type === CASE_STATUS.closed,
    ].includes(true);
  }

  const typeBeforeCanceled = caseIsCanceled
    ? getStatusBeforeCanceled(history)
    : undefined;

  function isStatusChangeEventInterrupted(type) {
    return caseIsCanceled && type === typeBeforeCanceled;
  }

  const statusChangeEvents = history.reduce(
    (acc, statusChange, indexOfCurrent) => {
      const {
        id: caseStatusChangeId,
        newStatus: type,
        changedAt: createdAt,
      } = statusChange;
      const completedAt = get(history, `${indexOfCurrent + 1}.changedAt`);

      const statusChangeEvent = {
        newStatus: type,
        oldStatus: statusChange.oldStatus,
        caseStatusChangeId,
        completedAt,
        createdAt,
        isActive: isStatusChangeEventActive(type),
        isComplete: isStatusChangeEventComplete(completedAt, type),
        isInterrupted: isStatusChangeEventInterrupted(type),
        type,
      };

      // Reopening a case that was in rolling status is a special case. The most recent _forward_
      // transition into the rolling status should be displayed as the last status change event,
      // _NOT_ the most recent transition into rolling (as is the case with other transitions).
      if (isReopenedToRolling(statusChange)) {
        const trimTo = acc.findIndex(
          ({ oldStatus, newStatus }) =>
            oldStatus === CASE_STATUS.rolling &&
            [
              CASE_STATUS.canceled,
              CASE_STATUS.closed,
              CASE_STATUS.closed_canceled,
            ].includes(newStatus),
        );
        return acc.slice(0, trimTo);
      }

      // The event timeline should display the most recent transition into a status
      // in the current timeline if one exists. A new timeline is created when there
      // is a backwards transition.
      if (isBackwardTransition(statusChange)) {
        // Trim so the end of the acc is the highest status index before the current type's index.
        // This complex selection logic allows for navigating backwards, even to a status that
        // is not currently in the accumulator.
        const accTypeIndexes = acc.map(({ type: statusChangeType }) =>
          CASE_EVENTS.indexOf(statusChangeType),
        );
        const currentStatusChangeIndex = CASE_EVENTS.indexOf(type);

        const trimTo = findIndex(
          accTypeIndexes,
          (idx) => idx > currentStatusChangeIndex,
        );

        // If the end of the trimmed timeline isn't the same status as is currently being processed,
        // it means the current status was previously skipped. In this case, leave the last event
        // in place.
        const lastOfTrimmed = get(acc, `${trimTo - 1}`);
        if (type !== get(lastOfTrimmed, 'newStatus')) {
          return acc.slice(0, trimTo).concat([statusChangeEvent]);
        }

        return acc.slice(0, trimTo - 1).concat([statusChangeEvent]);
      }

      return acc.concat([statusChangeEvent]);
    },
    [
      // Initialize the statusChangeEvents accumulator with a case creation
      // statusChangeEvent. This event is not reflected in the history.
      {
        completedAt: get(history, '0.changedAt'),
        createdAt: caseCreatedAt,
        isActive: isStatusChangeEventActive(CASE_STATUS.new),
        isComplete: isStatusChangeEventComplete(
          get(history, '0.changedAt'),
          CASE_STATUS.new,
        ),
        isInterrupted: isStatusChangeEventInterrupted(CASE_STATUS.new),
        newStatus: CASE_STATUS.new,
        type: CASE_STATUS.new,
      },
    ],
  );

  const events = CASE_EVENTS.map((type) => {
    const statusChangeEvent = find(statusChangeEvents, { type });

    if (statusChangeEvent) {
      return statusChangeEvent;
    }

    return {
      completedAt: undefined,
      createdAt: undefined,
      isActive: false,
      isComplete: false,
      isInterrupted: false,
      type,
    };
  });

  return events;
}

export default caseEventsFromStatusHistory;
