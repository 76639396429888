import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, withState, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import {
  P,
  H2,
  ButtonLink,
  MessageSmall,
  QuickActionButton,
} from 'base-components';

import Modal from 'components/Modal';
import { defaultRangeInMiles } from 'compositions/DealerLocator/constants';

function SingleCallModal({ onClose }) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers="info">
            <Modal.HeaderIcon name="phone" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center" style={{ marginBottom: px2rem(20) }}>
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Customer requested Single Call</Trans>
                </H2>
              </Column>
            </Row>

            <Row modifiers="center" style={{ marginBottom: px2rem(20) }}>
              <Column modifiers={['col', 'padScaleX_5']}>
                <P
                  modifiers="fontWeightRegular"
                  style={{ marginBottom: px2rem(20) }}
                >
                  <Trans>
                    For new Michelin products requested within customer tire
                    preferences, apply Single Call within {defaultRangeInMiles}{' '}
                    miles of the asset breakdown location, exhausting all
                    Michelin related products included within the preferences
                    before moving on to alternate brands.
                  </Trans>
                </P>
                <P modifiers="fontWeightRegular">
                  <Trans>
                    For Michelin retreads requested within customer tire
                    preferences, extend Single Call to 50 miles of the asset
                    breakdown location, exhausting all Michelin related products
                    included within the preferences before moving on to
                    alternate brands.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

SingleCallModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const SingleCallRequested = ({ showInstructions, showModal }) => (
  <div style={{ marginBottom: 10 }}>
    <MessageSmall type="info">
      <Trans>Single Call Requested</Trans>
      <ButtonLink
        style={{ display: 'inline-block', marginLeft: 10 }}
        onClick={() => showModal(true)}
      >
        <Trans>Show Instructions</Trans>
      </ButtonLink>
    </MessageSmall>

    {showInstructions && <SingleCallModal onClose={() => showModal(false)} />}
  </div>
);

SingleCallRequested.propTypes = {
  showModal: PropTypes.func.isRequired,
  showInstructions: PropTypes.bool.isRequired,
};

const SingleCallRequestedWithModal = compose(
  setDisplayName('SingleCall'),
  withState('showInstructions', 'showModal', false),
)(SingleCallRequested);

export default SingleCallRequestedWithModal;
