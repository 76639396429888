import React, { Children } from 'react';
import PropTypes from 'prop-types';

// import ContainerSelectable from '../../blocks/ContainerSelectable';
import DropdownBlock from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

function List({ children, name, ...rest }, context) {
  const { activeItem, handleItemClick, selectable } = context[DROPDOWN_CONTEXT];

  function handleClick(e, itemId) {
    if (selectable) {
      handleItemClick(e, itemId);
    }
  }

  const listItems = Children.map(children, (child, i) => {
    // this ternary allows us to not have to add a name prop
    // for a dropdown that only contains a single list
    const identifier = child.props.id || i.toString();
    const itemId = name.length ? `${name}:${identifier}` : identifier;
    const isActive = activeItem === itemId;
    const defaultModifiers = ['fluid'];

    const modifierProps =
      child?.props?.modifiers?.concat(isActive ? 'active' : []) || [];

    const listItem = React.cloneElement(child, {
      onClick: (e) => handleClick(e, itemId),
      modifiers: defaultModifiers.concat(modifierProps),
    });

    return listItem;
  });

  return (
    <DropdownBlock.List name={name} {...rest}>
      {listItems}
    </DropdownBlock.List>
  );
}

List.contextTypes = {
  [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
};

List.defaultProps = {
  name: '',
};

List.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default List;
