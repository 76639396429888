import React, { Component } from 'react';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { H3, Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import Panel from 'blocks/Panel';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import WithCaseCustomerType from 'compositions/WithCaseCustomerType';

import PropTypes from './prop-types';
import AccountType from './AccountType';
import PaymentEmpty from './PaymentEmpty';
import withCaseCustomer from './withCaseCustomer';
import withCaseTokenizedCard from './withCaseTokenizedCard';
import withPaymentPanelStatusReporting from './withPaymentPanelStatusReporting';
import withCaseThirdPartyTransactionDetails from './withCaseThirdPartyTransactionDetails';
import { CREDIT_CARD_RULE_TYPES } from './constants';

class CasePaymentPanel extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    creditCardRuleAbbreviation: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    paymentMethod: PropTypes.string,
    tokenizedCard: PropTypes.tokenizedCard,
  };

  static defaultProps = {
    caseId: '',
    customer: {},
    paymentMethod: '',
    tokenizedCard: null,
  };

  renderCreditCardRequired = () => {
    const { creditCardRuleAbbreviation } = this.props;

    if (
      CREDIT_CARD_RULE_TYPES[creditCardRuleAbbreviation] ===
      CREDIT_CARD_RULE_TYPES.M
    ) {
      return (
        <>
          <Column>
            <Row modifiers="middle">
              <Column>
                <Icon name="info-circle" modifiers="info" />
              </Column>
              <Column>
                <Text modifiers="textLight">Credit Card Required</Text>
              </Column>
            </Row>
          </Column>
        </>
      );
    }
    return null;
  };

  render() {
    const {
      caseId,
      caseNumber,
      creditCardRuleAbbreviation,
      customer,
      paymentMethod,
      tokenizedCard,
    } = this.props;

    const key = `${caseNumber}-${get(customer, 'id')}`;

    return (
      <WithCaseCustomerType caseNumber={caseNumber} key={key}>
        {({ caseCustomerType, CUSTOMER_TYPES }) => (
          <Panel
            modifiers={['padScaleX_3', 'padScaleY_2']}
            data-testid="CasePaymentPanel"
          >
            <Row modifiers="middle">
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.payment,
                  id: 'goToPaymentAction',
                  name: t`Go to Payment`,
                  shortcut: ['p', '0'],
                  icon: 'arrow-right',
                }}
              >
                {({ onFocusRequested }) => (
                  <Column ref={onFocusRequested}>
                    <H3 modifiers="fontWeightRegular">
                      <Trans>Payment</Trans>
                    </H3>
                  </Column>
                )}
              </CaseShortcut>
              {this.renderCreditCardRequired(caseCustomerType, CUSTOMER_TYPES)}
            </Row>
            {isEmpty(customer) ? (
              <PaymentEmpty />
            ) : (
              <AccountType
                caseCustomerType={caseCustomerType}
                caseId={caseId}
                caseNumber={caseNumber}
                creditCardRuleAbbreviation={creditCardRuleAbbreviation}
                customer={customer}
                customerTypes={CUSTOMER_TYPES}
                paymentMethod={paymentMethod === null ? '' : paymentMethod}
                tokenizedCard={tokenizedCard}
              />
            )}
          </Panel>
        )}
      </WithCaseCustomerType>
    );
  }
}

export default compose(
  setDisplayName('CasePaymentPanel'),
  withCaseCustomer,
  withCaseTokenizedCard,
  withCaseThirdPartyTransactionDetails,
  withPaymentPanelStatusReporting,
)(CasePaymentPanel);
