import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SmoothCollapse from 'react-smooth-collapse';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Text, ButtonLink } from 'base-components';

import Ul from 'elements/Ul';
import LinkWithIcon from 'elements/LinkWithIcon';
import { CallButtonLink } from 'compositions/ClickToCall';

import { services } from './constants';

export const ExternalService = buildStyledComponent(
  'ExternalService',
  styled.li,
  ({ theme }) => `
    font-weight: ${theme.fontWeights.light};
    padding: ${px2rem(2)} 0;
  `,
  {
    themePropTypes: {
      fontWeights: PropTypes.shape({
        light: PropTypes.number.isRequired,
      }).isRequired,
    },
  },
);

export default class ExternalServices extends PureComponent {
  state = { showList: false };

  toggleServicesList = () => this.setState({ showList: !this.state.showList });

  render() {
    const { showList } = this.state;

    return (
      <>
        <Row>
          <Column
            modifiers={['col', 'padScaleY_4']}
            style={{ paddingBottom: px2rem(2) }}
          >
            <Text>
              <Trans>
                Then use one of the available services to pre-authorize the
                credit card.
              </Trans>
              &nbsp;
              <ButtonLink modifiers="small" onClick={this.toggleServicesList}>
                {showList ? (
                  <Trans>Hide services</Trans>
                ) : (
                  <Trans>Show services</Trans>
                )}
              </ButtonLink>
            </Text>
          </Column>
        </Row>
        <SmoothCollapse expanded={showList}>
          <Row>
            <Column modifiers="col">
              <Ul>
                {services.map(({ type, label, link, ...other }) => (
                  <ExternalService key={label}>
                    {type === 'tel' ? (
                      <CallButtonLink
                        icon="phone"
                        label={label}
                        phone={link}
                        iconModifiers={['mini']}
                        {...other}
                      />
                    ) : (
                      <LinkWithIcon
                        to={link}
                        icon="external-link"
                        target="_blank"
                        external
                        aria-label={label}
                        iconModifiers="mini"
                        {...other}
                      >
                        {label}
                      </LinkWithIcon>
                    )}
                  </ExternalService>
                ))}
              </Ul>
            </Column>
          </Row>
        </SmoothCollapse>
      </>
    );
  }
}
