import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `
    button {
      background-color: ${theme.colors.base.selectableActive};
      span {
        color: ${theme.colors.base.linkHover};
      }
    }
  `,

  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,

  selected: ({ theme }) => `
    button {
      background-color: ${theme.colors.base.selectableActive};
      span {
        color: ${theme.colors.base.linkHover};
      }
    }
  `,
};

const styles = ({ theme }) => `
  transition: background 200ms ease,
              color 200ms ease,
              text-decoration 200ms ease;

  button:hover, button:focus {
    background-color: ${theme.colors.base.selectableHover};

    span {
      color: ${theme.colors.base.linkHover};
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
      selectableActive: PropTypes.string.isRequired,
      selectableHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Li', styled.li, styles, {
  modifierConfig,
  themePropTypes,
});
