import React, { PureComponent } from 'react';
import Bugsnag from '@bugsnag/browser';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, fromRenderProps, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { InputField, QuickActionButton, Text } from 'base-components';

import { AmazonConnect } from 'features/amazonConnect';

import PropTypes from '../prop-types';
import SecureIvrModal from '../SecureIvrModal';
import TokenizedCard from '../TokenizedCard';
import withSetPaymentDetails from '../withSetPaymentDetails';
import { PAYMENT_METHODS } from '../constants';

import withSetCardHolderName from './withSetCardHolderName';

const IVR_QUICK_CONNECT = SecureIvrModal.QUICK_CONNECTS.NAFA_NEW_CARD;

class NationalOneTimeCard extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    tokenizedCard: PropTypes.tokenizedCard,
    setCardHolderName: PropTypes.func.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
    ccp: PropTypes.shape({
      uiState: PropTypes.string,
      contact: PropTypes.shape({
        getContactId: PropTypes.func.isRequired,
        getAgentConnection: PropTypes.func.isRequired,
      }),
      transferToQuickConnect: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    tokenizedCard: null,
  };

  state = {
    nameOnCreditCard: '',
    showIvrModal: false,
  };

  handleTextChange = (e) => {
    const {
      target: { value },
    } = e;
    this.setState({ nameOnCreditCard: value });
  };

  initiateVerification = (contactId) => async () => {
    if (!contactId) {
      return; // this is very unlikely...
    }

    // step 1... save `this.state.nameOnCreditCard` to the backend
    await this.props.setCardHolderName({
      variables: {
        contactId,
        cardHolderName: this.state.nameOnCreditCard,
      },
    });

    // step 2... transfer to the "orphan" IVR
    this.setState({ showIvrModal: true });
  };

  handleResults = (data) => {
    const response = get(data, 'response.value');
    const responseNumber = parseInt(response, 10);
    const responseMessage = get(data, 'responseMessage.value');

    // expiration date comes in as a 4 chars string. e.g. 0123 for January 2023
    const expDateAttr = get(data, 'expDate.value', '').replace(/\s+/g, '');
    const firstDigit = get(data, 'first.value');
    const last4Digits = get(data, 'lastFour.value');
    const contactName = get(data, 'contactName.value');
    const isSuccessResponse = responseNumber === 1;

    let hasInvalidData = false;
    let tokenizedCreditCard;

    const hasRequiredData = [
      expDateAttr,
      firstDigit,
      last4Digits,
      contactName,
    ].every((value) => !!value);

    if (hasRequiredData) {
      const expirationMonth = parseInt(expDateAttr.slice(0, 2), 10);
      const expirationYear = parseInt(expDateAttr.slice(2), 10);

      tokenizedCreditCard = {
        name: contactName,
        firstDigit,
        last4Digits,
        expirationMonth,
        expirationYear,
      };

      const invalidRules = [
        firstDigit.length !== 1,
        last4Digits.length !== 4,
        expirationMonth === 0,
        expirationMonth > 12,
        !/\d{4}/.test(expDateAttr),
      ];

      if (invalidRules.some(Boolean)) {
        hasInvalidData = true;
      }
    }

    if (isSuccessResponse && hasRequiredData && !hasInvalidData) {
      this.props.setPaymentDetails({
        paymentMethod: PAYMENT_METHODS.ONE_TIME_CARD,
        tokenizedCreditCard,
        skipCreditCardValidation: true,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Error '${response}' from credit card validation: ${responseMessage}`,
      );

      if (hasInvalidData) {
        // eslint-disable-next-line no-console
        console.error(
          'Invalid data from credit card validation:',
          tokenizedCreditCard,
        );
      }

      const { contact } = this.props.ccp;

      // If the third party connection drops
      // (responseNumber = 1 and no responseMessage), the agent will still be
      // on hold, so we end the agent's connection in order for him to be
      // transitioned to "After Call Work". Ideally, we would get a different
      // responseNumber when the call drops.
      if (contact && responseNumber === 1 && !responseMessage) {
        contact.getAgentConnection().destroy();
      }
    }

    // ...then auto-close the modal:
    this.setState({ showIvrModal: false });
  };

  handleFailedQuickConnect = () => {
    this.setState({ showIvrModal: false }, () => {
      // eslint-disable-next-line no-alert
      alert('Secure IVR connection failed, see console for details');

      Bugsnag.notify(
        `NationalOneTimeCard: Secure IVR connection failed to ${IVR_QUICK_CONNECT}`,
      );
    });
  };

  render() {
    const { nameOnCreditCard, showIvrModal } = this.state;
    const {
      ccp,
      caseId,
      caseNumber,
      isReadOnlyCase,
      tokenizedCard,
    } = this.props;

    if (tokenizedCard) {
      return (
        <TokenizedCard
          caseId={caseId}
          caseNumber={caseNumber}
          isReadOnlyCase={isReadOnlyCase}
          tokenizedCard={tokenizedCard}
        />
      );
    }

    const { transferToQuickConnect, contact, uiState } = ccp;

    const enableIvrButton = !!(
      transferToQuickConnect &&
      contact &&
      nameOnCreditCard.length > 3 &&
      !isReadOnlyCase
    );

    return (
      <>
        <Row>
          <Column modifiers={['col']}>
            <InputField
              name="national-name-on-credit-card-input"
              onChange={this.handleTextChange}
              placeholder={t`Enter name on card...`}
              value={nameOnCreditCard}
              readOnly={isReadOnlyCase}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.Label>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Name on Card</Trans>
                    </Text>
                  </InputField.Label>
                </Row>
                <Row>
                  <InputField.TextField />
                </Row>
              </Column>
            </InputField>
          </Column>
        </Row>
        <Row>
          <Column>
            <QuickActionButton
              modifiers={enableIvrButton || 'disabled'}
              disabled={!enableIvrButton}
              onClick={this.initiateVerification(
                contact && contact.getContactId(),
              )}
            >
              <QuickActionButton.Text>
                <Trans>Transfer to IVR</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </Row>
        {showIvrModal && (
          <SecureIvrModal
            transferToQuickConnect={transferToQuickConnect || null}
            quickConnect={IVR_QUICK_CONNECT}
            headline={<Trans>Credit Card Validation in Progress...</Trans>}
            body={
              <Trans>Please wait while the credit card is validated.</Trans>
            }
            contact={contact}
            handleResults={this.handleResults}
            handleFailedQuickConnect={this.handleFailedQuickConnect}
            uiState={uiState}
          />
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('NationalOneTimeCard'),
  withSetCardHolderName,
  withSetPaymentDetails,
  fromRenderProps(AmazonConnect, (ccp) => ({ ccp })),
)(NationalOneTimeCard);
