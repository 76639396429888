import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_NOTES_QUERY } from '../withCaseNotes/caseNotesQuery';

import createCaseNoteGQL from './createCaseNoteMutation';

const buildCreateMutationChild = curry(
  (WrappedComponent, componentProps, createCaseNote) => (
    <WrappedComponent {...componentProps} createCaseNote={createCaseNote} />
  ),
);

function withCreateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={createCaseNoteGQL} refetchQueries={[CASE_NOTES_QUERY]}>
      {buildCreateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withCreateCaseNote = (WrappedComponent) => (componentProps) =>
  withCreateMutation(WrappedComponent, componentProps);

export default withCreateCaseNote;
