import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { H3 } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import Panel from 'blocks/Panel';

import WithCaseCustomerType from 'compositions/WithCaseCustomerType';

import CustomerTypeSelector from './CustomerTypeSelector';
import CustomerInput from './CustomerInput';
import CustomerDetails from './CustomerDetails';
import CustomerWidgetContext from './CustomerWidgetContext';

function CustomerWidget({ caseNumber }) {
  return (
    <WithCaseCustomerType caseNumber={caseNumber}>
      {({
        caseCustomerType,
        clearCaseCustomer,
        customerType,
        updateCustomerType,
        CUSTOMER_TYPES,
        CUSTOMER_TYPE_OPTIONS,
      }) => (
        <CustomerWidgetContext
          caseNumber={caseNumber}
          customerType={customerType}
          customerTypes={CUSTOMER_TYPES}
        >
          <Panel modifiers={['padScaleX_3']} data-testid="CustomerPanel">
            <Row>
              <Column modifiers={['padScaleY_2']}>
                <H3 modifiers={['fontWeightRegular']}>
                  <Trans>Fleet</Trans>
                </H3>
              </Column>
            </Row>
            <CustomerTypeSelector
              customerType={customerType}
              customerTypeOptions={CUSTOMER_TYPE_OPTIONS}
              updateCustomerType={updateCustomerType}
            />
            <CustomerInput
              caseNumber={caseNumber}
              clearCaseCustomer={clearCaseCustomer}
              customerType={customerType}
              customerTypes={CUSTOMER_TYPES}
              updateCustomerType={updateCustomerType}
            />
            <CustomerDetails
              caseNumber={caseNumber}
              caseCustomerType={caseCustomerType}
              customerType={customerType}
              customerTypes={CUSTOMER_TYPES}
            />
          </Panel>
        </CustomerWidgetContext>
      )}
    </WithCaseCustomerType>
  );
}

CustomerWidget.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

export default CustomerWidget;
