import React, { Component } from 'react';
import { get, isEmpty } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import { withReadOnlyCase } from 'compositions/CaseStatus';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import PropTypes from '../prop-types';
import withRemovePaymentMutation from '../withRemovePaymentMutation';
import withSetPaymentDetails from '../withSetPaymentDetails';
import NationalOneTimeCard from './NationalOneTimeCard';
import NationalExistingCard from './NationalExistingCard';
import NationalNoCreditCard from './NationalNoCreditCard';

import {
  fieldIds,
  CREDIT_CARD_RULE_TYPES,
  PAYMENT_METHODS,
} from '../constants';

const OPTIONS = {
  MICHELIN_LINE_OF_CREDIT: {
    label: 'national',
    value: PAYMENT_METHODS.MICHELIN_LINE_OF_CREDIT,
  },
  CARD_ON_FILE: {
    label: 'national-existing-credit-card',
    value: PAYMENT_METHODS.CARD_ON_FILE,
  },
  CREDIT_CARD_NO_PREAUTH: {
    label: 'other-pre-auth',
    value: PAYMENT_METHODS.CREDIT_CARD_NO_PREAUTH,
  },
  OTHER_NATIONAL_ACCOUNT: {
    label: 'national-other-national-account',
    value: PAYMENT_METHODS.OTHER_NATIONAL_ACCOUNT,
  },
  ONE_TIME_CARD: {
    label: 'national-new-credit-card',
    value: PAYMENT_METHODS.ONE_TIME_CARD,
  },
  NONE: {
    label: 'national-none',
    value: PAYMENT_METHODS.NONE,
  },
};

const creditCardTypes = [
  OPTIONS.CARD_ON_FILE.label,
  OPTIONS.ONE_TIME_CARD.label,
];

function hasCreditCardType(selectedOption) {
  return creditCardTypes.includes(selectedOption);
}

function getPaymentOption(paymentMethod) {
  return get(OPTIONS[paymentMethod], 'label', '');
}

class National extends Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    creditCardRuleAbbreviation: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    removePaymentDetails: PropTypes.func.isRequired,
    removePaymentDetailsLoading: PropTypes.bool.isRequired,
    tokenizedCard: PropTypes.tokenizedCard,
    setPaymentDetails: PropTypes.func.isRequired,
    onExistingCCFocusRequested: PropTypes.func.isRequired,
    onNewCCFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tokenizedCard: null,
  };

  state = {
    selectedOption: getPaymentOption(this.props.paymentMethod),
  };

  componentDidUpdate(prevProps, prevState) {
    /*
      This logic tracks when the selectedOption is between existing or new credit card.
    */
    const optionHasChanged =
      this.state.selectedOption !== prevState.selectedOption;

    const paymentMethodHasChanged =
      prevProps.paymentMethod !== this.props.paymentMethod;

    // Checks to see if the cache has changed
    if (paymentMethodHasChanged && !isEmpty(this.props.paymentMethod)) {
      // eslint-disable-next-line
      this.setState({
        selectedOption: getPaymentOption(this.props.paymentMethod),
      });
    }

    const changingFromCreditCardToCreditCard =
      hasCreditCardType(this.state.selectedOption) &&
      hasCreditCardType(prevState.selectedOption);

    /*
        Clears the paymentMethod so we can clear the tokenizedCard when switching between
        existing and new credit card.
      */
    if (
      optionHasChanged &&
      changingFromCreditCardToCreditCard &&
      this.props.tokenizedCard
    ) {
      this.clearPaymentDetails();
    }
  }

  clearPaymentDetails = () => {
    const { caseId } = this.props;
    this.props.removePaymentDetails({ variables: { caseId } });
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.label });
  };

  renderForm = () => {
    const { selectedOption } = this.state;
    const {
      creditCardRuleAbbreviation,
      caseId,
      caseNumber,
      removePaymentDetailsLoading,
      tokenizedCard,
      isReadOnlyCase,
    } = this.props;

    /*
      When switching from existing to new credit card or vice versa, we are waiting for the
      paymentMethod(along with the tokenizedCard) to clear. So if we are removing the paymentMethod
      we just don't display the tokenizedCard;
    */
    const displayedTokenizedCard = !removePaymentDetailsLoading
      ? tokenizedCard
      : null;

    const creditCardOptional =
      CREDIT_CARD_RULE_TYPES[creditCardRuleAbbreviation] ===
      CREDIT_CARD_RULE_TYPES.O;

    return (
      <Row>
        <Column modifiers="col">
          {creditCardOptional && (
            <Row>
              <Column modifiers={['col', 'padScaleY_2']}>
                <RadioButton
                  id={OPTIONS.MICHELIN_LINE_OF_CREDIT.label}
                  checked={
                    selectedOption === OPTIONS.MICHELIN_LINE_OF_CREDIT.label
                  }
                  label={<Trans>National Account</Trans>}
                  onChange={() =>
                    this.handleChange(OPTIONS.MICHELIN_LINE_OF_CREDIT)
                  }
                  readOnly={isReadOnlyCase}
                />
              </Column>
            </Row>
          )}
          <Row ref={this.props.onExistingCCFocusRequested}>
            <Column modifiers="col">
              <Row>
                <Column modifiers="padScaleX_0">
                  <RadioButton
                    id={OPTIONS.CARD_ON_FILE.label}
                    checked={selectedOption === OPTIONS.CARD_ON_FILE.label}
                    label={<Trans>Existing Credit Card</Trans>}
                    onChange={() => this.handleChange(OPTIONS.CARD_ON_FILE)}
                    readOnly={isReadOnlyCase}
                  />
                </Column>
              </Row>
              {selectedOption === OPTIONS.CARD_ON_FILE.label && (
                <NationalExistingCard
                  caseId={caseId}
                  caseNumber={caseNumber}
                  isReadOnlyCase={isReadOnlyCase}
                  tokenizedCard={displayedTokenizedCard}
                />
              )}
            </Column>
          </Row>
          <Row ref={this.props.onNewCCFocusRequested}>
            <Column modifiers="col">
              <Row>
                <Column modifiers="padScaleX_0">
                  <RadioButton
                    id={OPTIONS.ONE_TIME_CARD.label}
                    checked={selectedOption === OPTIONS.ONE_TIME_CARD.label}
                    label={<Trans>New Credit Card</Trans>}
                    onChange={() => this.handleChange(OPTIONS.ONE_TIME_CARD)}
                    readOnly={isReadOnlyCase}
                  />
                </Column>
              </Row>
              {selectedOption === OPTIONS.ONE_TIME_CARD.label && (
                <NationalOneTimeCard
                  caseId={caseId}
                  caseNumber={caseNumber}
                  isReadOnlyCase={isReadOnlyCase}
                  tokenizedCard={displayedTokenizedCard}
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CREDIT_CARD_NO_PREAUTH.label}
                checked={
                  selectedOption === OPTIONS.CREDIT_CARD_NO_PREAUTH.label
                }
                label={<Trans id="Credit Card - No Preauth" />}
                onChange={() =>
                  this.handleChange(OPTIONS.CREDIT_CARD_NO_PREAUTH)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <RadioButton
                id={OPTIONS.OTHER_NATIONAL_ACCOUNT.label}
                checked={
                  selectedOption === OPTIONS.OTHER_NATIONAL_ACCOUNT.label
                }
                label={<Trans id="Other National Account" />}
                onChange={() =>
                  this.handleChange(OPTIONS.OTHER_NATIONAL_ACCOUNT)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          {!creditCardOptional && (
            <Row>
              <Column modifiers={['col', 'padScaleY_2']}>
                <RadioButton
                  id={OPTIONS.NONE.label}
                  checked={selectedOption === OPTIONS.NONE.label}
                  label={<Trans id="Continue without payment method" />}
                  onChange={() => this.handleChange(OPTIONS.NONE)}
                  readOnly={isReadOnlyCase}
                />
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  render() {
    const { creditCardRuleAbbreviation } = this.props;

    const isNationalAccount = [
      CREDIT_CARD_RULE_TYPES.N,
      CREDIT_CARD_RULE_TYPES.Z,
    ].includes(CREDIT_CARD_RULE_TYPES[creditCardRuleAbbreviation]);

    return (
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          {isNationalAccount ? <NationalNoCreditCard /> : this.renderForm()}
        </Column>
      </Row>
    );
  }
}

export default compose(
  setDisplayName('National'),
  withRemovePaymentMutation,
  withSetPaymentDetails,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.national.existingCC, 'onExistingCCFocusRequested'),
  withFocusReceiver(fieldIds.national.newCC, 'onNewCCFocusRequested'),
)(National);
