import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { get } from 'lodash';
import { compose, fromRenderProps, mapProps, withState } from 'recompose';

import { reportTypes } from 'pages/Reports/constants';

import createPerHourReportMutation from './createPerHourReportMutation';
import createDealerInvoiceReportMutation from './createDealerInvoiceReportMutation';
import createDailyAgentMetricsReportMutation from './createDailyAgentMetricsReportMutation';
import createDailyCasesMetricsReportMutation from './createDailyCasesMetricsReportMutation';
import createDailyDealerReportMutation from './createDailyDealerReportMutation';
import createDailyDealerReportByEmailMutation from './createDailyDealerReportByEmailMutation';
import createEmailDistributionReportMutation from './createEmailDistributionReportMutation';
import createDealerSelectionAuditReportMutation from './createDealerSelectionAuditReportMutation';
import createAssetValidationReport from './createAssetValidationReport';

import {
  configValuesTransformers,
  createReportJobStatusTypes,
} from '../constants';

import {
  getCommonConfigVars,
  getConfigVarsForType,
  mapConfigKeysToVariables,
} from '../utils';

const mutationInfoByReportType = {
  [reportTypes.caseBillingReport]: {
    mutationName: 'createDealerInvoiceReport',
    mutationFnPropName: 'createCaseBillingReport',
  },
  [reportTypes.casesPerHour]: {
    mutationName: 'createPerHourReport',
    mutationFnPropName: 'createCasesPerHourReport',
  },
  [reportTypes.dailyAgentMetrics]: {
    mutationName: 'createDailyAgentMetricsReport',
    mutationFnPropName: 'createDailyAgentMetricsReport',
  },
  [reportTypes.dailyCasesMetrics]: {
    mutationName: 'createDailyCasesMetricsReport',
    mutationFnPropName: 'createDailyCasesMetricsReport',
  },
  [reportTypes.dailyDealerReport]: {
    mutationName: 'createDailyDealerReport',
    mutationFnPropName: 'createDailyDealerReport',
  },
  [reportTypes.dailyDealerReportByEmail]: {
    mutationName: 'createDailyDealerReportByEmail',
    mutationFnPropName: 'createDailyDealerReportByEmail',
  },
  [reportTypes.emailDistributionReport]: {
    mutationName: 'createEmailDistributionReport',
    mutationFnPropName: 'createEmailDistributionReport',
  },
  [reportTypes.dealerSelectionAuditReport]: {
    mutationName: 'createDealerSelectionAuditReport',
    mutationFnPropName: 'createDealerSelectionAuditReport',
  },
  [reportTypes.assetValidationReport]: {
    mutationName: 'createAssetValidationReport',
    mutationFnPropName: 'createAssetValidationReport',
  },
};

const createMutationFor = (mutation) => (props) => (
  <Mutation {...{ ...props, mutation }} />
);

const mapMutationPropsForReportType = (reportType) => (mutate, { data }) => ({
  [get(mutationInfoByReportType, `${reportType}.mutationFnPropName`)]: (
    variables,
  ) => mutate({ variables }),
  [`${reportType}Results`]: data,
});

const createReportFactory = (props) => {
  const { setCurrentRunningReport } = props;

  return async (type, config) => {
    const { mutationName, mutationFnPropName } =
      mutationInfoByReportType[type] || {};

    if (!mutationName || !props[mutationFnPropName]) {
      // eslint-disable-next-line no-console
      console.error(`[createReport] cannot handle reports of type "${type}"`);

      return;
    }

    const createReport = props[mutationFnPropName];
    const reportJobIdPath = `data.${mutationName}.jobId`;

    const runningReport = {
      type,
      config,
      jobId: undefined,
      status: createReportJobStatusTypes.loading,
    };

    setCurrentRunningReport(runningReport);

    let jobId;
    let status;

    try {
      const result = await createReport(
        mapConfigKeysToVariables(
          {
            ...getCommonConfigVars(config),
            ...getConfigVarsForType(type, config),
          },
          configValuesTransformers[type],
        ),
      );
      jobId = get(result, reportJobIdPath);
      status = createReportJobStatusTypes[jobId ? 'success' : 'failure'];
    } catch (e) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);

      status = createReportJobStatusTypes.failure;
    }

    setCurrentRunningReport({ ...runningReport, jobId, status });
  };
};

export default compose(
  fromRenderProps(
    createMutationFor(createDealerInvoiceReportMutation),
    mapMutationPropsForReportType(reportTypes.caseBillingReport),
  ),
  fromRenderProps(
    createMutationFor(createPerHourReportMutation),
    mapMutationPropsForReportType(reportTypes.casesPerHour),
  ),
  fromRenderProps(
    createMutationFor(createDailyAgentMetricsReportMutation),
    mapMutationPropsForReportType(reportTypes.dailyAgentMetrics),
  ),
  fromRenderProps(
    createMutationFor(createDailyCasesMetricsReportMutation),
    mapMutationPropsForReportType(reportTypes.dailyCasesMetrics),
  ),
  fromRenderProps(
    createMutationFor(createDailyDealerReportMutation),
    mapMutationPropsForReportType(reportTypes.dailyDealerReport),
  ),
  fromRenderProps(
    createMutationFor(createDailyDealerReportByEmailMutation),
    mapMutationPropsForReportType(reportTypes.dailyDealerReportByEmail),
  ),
  fromRenderProps(
    createMutationFor(createEmailDistributionReportMutation),
    mapMutationPropsForReportType(reportTypes.emailDistributionReport),
  ),
  fromRenderProps(
    createMutationFor(createDealerSelectionAuditReportMutation),
    mapMutationPropsForReportType(reportTypes.dealerSelectionAuditReport),
  ),
  fromRenderProps(
    createMutationFor(createAssetValidationReport),
    mapMutationPropsForReportType(reportTypes.assetValidationReport),
  ),
  withState('currentRunningReport', 'setCurrentRunningReport', undefined),
  mapProps((props) => ({
    ...props,
    cancelReport: () => props.setCurrentRunningReport(undefined),
    createReport: createReportFactory(props),
  })),
);
