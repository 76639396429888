import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';

import { ButtonIconRectangle } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';

function BaseRenderer({ deleteDealerResponse, disabled, id }) {
  return (
    <Row modifiers={['height_100', 'middle']}>
      <Column modifiers={['col', 'end', 'padScaleX_2']}>
        <ButtonIconRectangle
          disabled={disabled}
          modifiers={[disabled && 'disabled', 'hoverDanger']}
          onClick={() => deleteDealerResponse({ ids: [id] })}
        >
          <ButtonIconRectangle.Icon name="trash" />
        </ButtonIconRectangle>
      </Column>
    </Row>
  );
}

BaseRenderer.propTypes = {
  deleteDealerResponse: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

const CellRenderer = compose(withCaseDealerResponseActions)(BaseRenderer);

function canDelete({ rowIndex, tableData, tableMetaData }) {
  const hasServicingDealer = get(tableMetaData, 'hasServicingDealer', false);
  const isServicingDealer = get(tableMetaData, 'isServicingDealer', false);
  const isLast = rowIndex === tableData.length - 1;
  const nextToLastAccepted = get(tableData[tableData.length - 2], 'accepted');

  if (
    hasServicingDealer &&
    !isServicingDealer &&
    isLast &&
    nextToLastAccepted
  ) {
    return false;
  }
  return true;
}

export default {
  name: 'deleteAction',
  cellKeyGetter: ({ id }) => `${id}:deleteAction`,
  cellDataGetter: (args) => args,
  cellStyles: { flex: 'none', minWidth: px2rem(52) },
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']} />
    </Row>
  ),
  dataCellRenderer: (
    { id }, // eslint-disable-line react/prop-types
    { rowIndex, tableData, tableMetaData },
  ) =>
    get(tableMetaData, 'readOnly') ||
    get(tableMetaData, 'isDispatchedStatus') ? (
      <Row>
        <Column modifiers={['padScaleX_2', 'padScaleY_3']} />
      </Row>
    ) : (
      <CellRenderer
        id={id}
        disabled={!canDelete({ rowIndex, tableData, tableMetaData })}
      />
    ),
};
