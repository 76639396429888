import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { chunk, curry } from 'lodash';
import { Trans } from '@lingui/macro';

import {
  Row,
  Column,
  Container as BaseContainer,
} from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Text, Icon, ButtonIconRectangle } from 'base-components';

import Dot from 'elements/Dot';

import MessageSmall from './MessageSmall';

const Container = buildStyledComponent(
  'Container',
  styled(BaseContainer),
  ({ theme }) => `
    background: ${theme.colors.base.selectableActive};
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          selectableActive: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

const buttonStyles = { background: 'none' };

/**
 * Build a formatting function for the credit card data. Breaks the
 * input `number` into chunks of a given `chunkSize`, with each chunk
 * separated by the `separator`.
 */
const formatter = curry((chunkSize, separator, number) =>
  chunk(number.replace(/\s+/g, ''), chunkSize)
    .map((i) => i.join(''))
    .join(separator),
);

const formatCreditCardNumber = formatter(4, ' ');

const formatExpirationDate = formatter(2, '/');

export default class CreditCardInfo extends PureComponent {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    creditCardNumber: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
  };

  state = { revealCreditCardNumber: false };

  toggleRevealCreditCardNumber = () =>
    this.setState({
      revealCreditCardNumber: !this.state.revealCreditCardNumber,
    });

  render() {
    const { revealCreditCardNumber } = this.state;
    const { creditCardNumber, expirationDate, onRemove } = this.props;

    return (
      <Container modifiers={['padScaleX_4', 'padScaleY_2']}>
        <Row>
          <Column modifiers={['col', 'padScaleY_4']}>
            <MessageSmall>
              <MessageSmall.Icon type="info" />
              <MessageSmall.Text>
                <Trans>
                  Credit Card details will be discarded when you navigate away
                  from this page.
                </Trans>
              </MessageSmall.Text>
            </MessageSmall>
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleY_0']}>
            <Text modifiers="small">
              <Trans>Credit Card Number</Trans>
            </Text>
          </Column>
        </Row>
        <Row modifiers="middle">
          <Column modifiers={['col', 'padScaleY_0']}>
            <Text>
              {revealCreditCardNumber ? (
                formatCreditCardNumber(creditCardNumber)
              ) : (
                <Dot repeat={[4, 4, 4, 4]} />
              )}
            </Text>
          </Column>
          <Column modifiers={['end', 'padScaleY_0']}>
            <ButtonIconRectangle
              type="button"
              style={buttonStyles}
              onClick={this.toggleRevealCreditCardNumber}
            >
              <Icon name={`eye${revealCreditCardNumber ? '-slash' : ''}`} />
            </ButtonIconRectangle>
          </Column>
          <Column modifiers={['end', 'padScaleY_0']}>
            <ButtonIconRectangle
              type="button"
              style={buttonStyles}
              onClick={onRemove}
              modifiers="hoverDanger"
            >
              <Icon name="trash" />
            </ButtonIconRectangle>
          </Column>
        </Row>
        <Row>
          <Column
            modifiers={['col', 'padScaleY_3']}
            style={{ paddingBottom: 0 }}
          >
            <Text modifiers="small">
              <Trans>Expiration Date</Trans>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleY_2']} style={{ paddingTop: 0 }}>
            <Text>{formatExpirationDate(expirationDate)}</Text>
          </Column>
        </Row>
      </Container>
    );
  }
}
