import { Trans } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import { withReadOnlyCase } from 'compositions/CaseStatus';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import withSetPaymentDetails from '../withSetPaymentDetails';
import { PAYMENT_METHODS, fieldIds } from '../constants';

const OPTIONS = {
  STORE_ACCOUNT: {
    label: 'store-account',
    value: PAYMENT_METHODS.STORE_ACCOUNT,
  },
  NONE: {
    label: 'store-none',
    value: PAYMENT_METHODS.NONE,
  },
};

class Store extends Component {
  static propTypes = {
    customer: PropTypes.shape({
      accountNumber: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  state = {
    selectedOption: get(OPTIONS[this.props.paymentMethod], 'label', ''),
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.label });
  };

  render() {
    const {
      customer: { accountNumber },
      isReadOnlyCase,
    } = this.props;
    const { selectedOption } = this.state;

    return (
      <Row ref={this.props.onFocusRequested}>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.STORE_ACCOUNT.label}
                checked={selectedOption === OPTIONS.STORE_ACCOUNT.label}
                label={
                  <Trans>
                    Store Account {accountNumber && `#${accountNumber}`}
                  </Trans>
                }
                onChange={() => this.handleChange(OPTIONS.STORE_ACCOUNT)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.NONE.label}
                checked={selectedOption === OPTIONS.NONE.label}
                label={<Trans>Continue without payment method</Trans>}
                onChange={() => this.handleChange(OPTIONS.NONE)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default compose(
  setDisplayName('Store'),
  withSetPaymentDetails,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.store.type),
)(Store);
