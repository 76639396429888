import { curry, get } from 'lodash';
import React from 'react';

import DebouncedQuery from 'components/DebouncedQuery';

import CustomerWidgetContext from './CustomerWidgetContext';
import searchStoreCustomersQuery from './searchCustomerStoresQuery';
import { INBOUND_PROGRAM_ACCOUNT_TYPE } from './withCaseInboundProgram/constants';

export function skipQuery({ searchString }) {
  return !searchString || searchString.length < 3;
}

export function buildQueryVariables({
  searchValue = '',
  inboundProgramAccountType,
  inboundProgramBillTo,
}) {
  const variables = { searchString: searchValue };

  if (
    inboundProgramAccountType === INBOUND_PROGRAM_ACCOUNT_TYPE.fleet &&
    !!inboundProgramBillTo
  ) {
    variables.billTo = inboundProgramBillTo;
  }

  return variables;
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { pending = false } = queryProps;
    const customers = get(queryProps, 'data.searchStoreCustomers.customers');
    return (
      <WrappedComponent
        {...componentProps}
        customers={customers}
        isSearching={pending}
      />
    );
  },
);

function buildWrappedComponentWithDebouncedQuery(
  WrappedComponent,
  componentProps,
) {
  return (
    <DebouncedQuery
      buildQueryVariables={buildQueryVariables}
      debounceMS={1000}
      query={searchStoreCustomersQuery}
      searchValue={componentProps.storeCustomerSearchValue}
      skipQuery={skipQuery}
      fetchPolicy="no-cache"
      {...componentProps}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </DebouncedQuery>
  );
}

export const updateStoreCustomerSearchValue = curry(
  (setContext, storeCustomerSearchValue) =>
    setContext({ storeCustomerSearchValue }),
);

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { inboundProgramBillTo, inboundProgramAccountType } = componentProps;
    const {
      storeCustomerSearchValue,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithDebouncedQuery(WrappedComponent, {
      ...componentProps,
      storeCustomerSearchValue,
      inboundProgramBillTo,
      inboundProgramAccountType,
      onChangeStoreCustomerSearchValue: updateStoreCustomerSearchValue(
        updateCustomerWidgetContext,
      ),
    });
  },
);

const withStoreCustomerSearch = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withStoreCustomerSearch;
