import gql from 'graphql-tag';

export default gql`
  query searchStoreCustomersQuery($searchString: String) {
    searchStoreCustomers(filters: { searchString: $searchString }) {
      customers {
        accountNumber
        city
        id
        name
        state
        zip
      }
    }
  }
`;
