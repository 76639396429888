import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { InputField } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

/**
 * This is the read-only input field that shows the current selected
 * value for the `UnitTypeDropdown`.
 */
export function UnitTypeInputField(props) {
  const { isDropdownVisible, onChange, onToggle, onReset } = props;
  const { onBlur, value, readOnly, isValid, onFocusRequested } = props;

  return (
    <InputField
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      readOnly={readOnly}
      isValid={isValid}
    >
      <Column modifiers={['col', 'padScaleY_0']}>
        <Row>
          <InputField.Label>
            <Trans>Unit Type</Trans>
          </InputField.Label>
        </Row>
        <Row>
          <InputField.TextField ref={onFocusRequested} />
          {value && (
            <InputField.ActionButton
              icon="times"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              modifiers={['padScaleX_0', 'hoverDanger']}
            />
          )}
          <InputField.ActionButton
            icon={isDropdownVisible ? 'chevron-up' : 'chevron-down'}
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            modifiers={['hoverInfo']}
          />
        </Row>
      </Column>
    </InputField>
  );
}

UnitTypeInputField.propTypes = {
  isDropdownVisible: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  value: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  focusReceiverId: PropTypes.string.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
};

UnitTypeInputField.defaultProps = {
  value: '',
};

export default compose(
  setDisplayName('UnitTypeInputField'),
  withFocusReceiver((p) => p.focusReceiverId),
)(UnitTypeInputField);
